import { Fields } from "@/components/collection"
import { ListItem, ListLeftRight } from "@/components/collection/list"
import { Button } from "@/components/ui/button"
import { Popover } from "@/components/ui/popover"
import { getLinesStats } from "@/fns/invoice"
import { toRoudedString } from "@/fns/math"
import { InvoiceLine } from "@/store/invoices/localizers"
import { InfoIcon } from "lucide-react"

/**
 * PaymentDetails
 */
type Props = {
  lines: InvoiceLine[]
  stretch?: boolean
}
export const PaymentDetails: React.FC<Props> = ({ lines, stretch = false }) => {
  const stats = React.useMemo(() => getLinesStats(lines), [lines])
  return (
    <>
      <Fields.Item
        name="Discount"
        value={`${toRoudedString(stats.discountAmount)} €`}
        className="grid"
        stretch={stretch}
      />
      <Fields.Item
        name="Subtotal"
        value={`${toRoudedString(stats.totalNoTax)} €`}
        className="grid"
        stretch={stretch}
      />
      <Fields.Item name="VAT" className="relative grid" stretch={stretch}>
        {`${toRoudedString(stats.sumTax)} €`}
        <Popover>
          <Popover.Trigger asChild>
            <Button variant="ghost" icon size="xs" className="absolute top-2.5 right-0">
              <InfoIcon size={16} />
            </Button>
          </Popover.Trigger>
          <Popover.Content className="w-72 pt-1" side="left" align="start">
            <ListLeftRight>
              <ListItem label="Normal">{toRoudedString(stats.sumTaxNormal)} €</ListItem>
              <ListItem label="Intermediate">{toRoudedString(stats.sumTaxIntermediate)} €</ListItem>
              <ListItem label="Reduced">{toRoudedString(stats.sumTaxReduced)} €</ListItem>
              <ListItem label="Super reduced">
                {toRoudedString(stats.sumTaxSuperReduced)} €
              </ListItem>
            </ListLeftRight>
          </Popover.Content>
        </Popover>
      </Fields.Item>
      <Fields.Item
        name="Total"
        value={`${toRoudedString(stats.totalWithTax)} €`}
        className="grid"
        stretch={stretch}
      />
    </>
  )
}
