import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import globalConfig from "@/config/global"
import { FallbackProps } from "react-error-boundary"
import Linkify from "react-linkify"

/**
 * PageBoundary
 */
export const PageBoundary: React.FC<FallbackProps> = ({ error }) => {
  const refresh = () => location.reload()
  const { inDev } = globalConfig
  return (
    <div className='flex justify-center items-center w-full min-h-screen p-8 bg-secondary' role='status'>
      <Card className='w-full max-w-4xl'>
        <Card.Header>
          <Card.Title>Application error</Card.Title>
          <Card.Description>An error occured</Card.Description>
        </Card.Header>
        <Card.Content className='flex flex-col gap-6'>
          <p className='text-sm font-light'>
            The application is not responding, please try reloading the page or go back to the home page
          </p>
          {inDev && G.isString(error.stack) && (
            <div className='p-8 bg-destructive/10 border border-destructive/25 rounded-md text-sm [&_a]:text-destructive font-medium italic whitespace-pre-wrap'>
              <h3 className='text-lg font-medium text-destructive'>Stack Trace</h3>
              <Linkify textDecorator={cleanUrl}>{error.stack}</Linkify>
            </div>
          )}
          <div className='flex gap-2'>
            <Button onClick={refresh}>Reload</Button>
            <Button onClick={() => navigate("/")} variant={"secondary"}>
              Home
            </Button>
          </div>
        </Card.Content>
      </Card>
    </div>
  )
}

/**
 * helpers
 */
const shortenUrl = (url: string, n: number): string => {
  if (url.length <= n) return url
  const startChars = Math.floor((n - 3) / 2)
  const endChars = n - startChars - 3
  const shortenedUrl = `${url.slice(0, startChars)}...${url.slice(-endChars)}`
  return shortenedUrl
}
const cleanUrl = (url: string): string => {
  let cleanedUrl = url.replace(/^(https?:\/\/[^/]+)(\/)?/, "")
  const queryParamsIndex = cleanedUrl.indexOf("?")

  if (queryParamsIndex !== -1) {
    const beforeQueryParams = A.head(S.split(cleanedUrl, "?"))
    if (G.isNotNullable(beforeQueryParams)) cleanedUrl = beforeQueryParams
    const followingParams = S.split(url, ":")
    const rowNumber = +(followingParams[followingParams.length - 2] ?? "")
    const charNumber = +(followingParams[followingParams.length - 1] ?? "")
    if (N.gte(rowNumber, 0)) cleanedUrl += `:${rowNumber}`
    if (N.gte(charNumber, 0)) cleanedUrl += `:${charNumber}`
  }

  return shortenUrl(cleanedUrl, 60)
}
