import { Globe } from "lucide-react"
import { FormGroup, FormGroupError, FormGroupInfo, FormLabel } from "."
import { Button } from "../ui/button"

/**
 * FormMediaWrapper
 */
export type FormFieldWrapperProps = {
  groupCx?: ClassName
  label?: string
  labelCx?: ClassName
  labelAside?: React.ReactNode
  name?: string
  info?: string | false
  translatable?: boolean
}
export const FormFieldWrapper: React.FC<React.PropsWithChildren<FormFieldWrapperProps>> = ({
  groupCx,
  label,
  labelCx,
  labelAside,
  name,
  info,
  translatable = false,
  children,
}) => {
  return (
    <FormGroup name={name} className={groupCx}>
      {G.isNotNullable(label) && (
        <div className={cxm("flex justify-between items-center min-h-6", labelCx)}>
          <FormLabel>{label}</FormLabel>
          {labelAside}
          {translatable && (
            <Button icon variant='ghost' size='xxs' className='text-muted-foreground'>
              <Globe aria-hidden />
            </Button>
          )}
        </div>
      )}
      {children}
      <FormGroupError />
      {info !== false && <FormGroupInfo>{info}</FormGroupInfo>}
    </FormGroup>
  )
}

type Props = FormFieldWrapperProps & { [key: string]: any }
export const extractWrapperProps = (props: Props): FormFieldWrapperProps => {
  const { groupCx, label, labelCx, labelAside, name, info, translatable } = props

  return { groupCx, label, labelCx, labelAside, name, info, translatable }
}
export const extractInputProps = <T extends Record<string, unknown>>(
  props: FormFieldWrapperProps & T
): { [key: string]: any } => {
  const { groupCx, label, labelCx, labelAside, name, info, translatable, ...inputProps } = props
  return inputProps as T
}
