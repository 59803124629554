import { useCopyToClipboard } from "@/hooks/useCopyToClipboard"
import { CopyCheck, CopyIcon } from "lucide-react"

/**
 * Clipboard
 */
export const Clipboard: React.FC<React.PropsWithChildren<{ text: string }>> = ({ text, children }) => {
  const [, copy, alert] = useCopyToClipboard()
  return (
    <span className='inline-flex items-center' onClick={() => copy(text)}>
      {children}
      {alert ? (
        <CopyCheck className='cursor-pointer size-[0.7em] mb-[0.3em] ml-[0.3em]' strokeWidth={0.8} />
      ) : (
        <CopyIcon className='cursor-pointer size-[0.7em] mb-[0.3em] ml-[0.3em]' strokeWidth={0.8} />
      )}
    </span>
  )
}
