import { Menu, Row } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { oneIsNotEmpty } from "@/fns/string"
import { customerIsValid } from "@/store/customers/helpers"
import { Customer } from "@/store/customers/localizers"
import { BadgeEuro, Check, Hash, SquareUser, TriangleAlert, UserIcon } from "lucide-react"
import { usePageContext } from "./Context"
import { ContextMenu } from "./ContextMenu"

type Props = { customer: Customer }

/**
 * Item
 */
export const Item: React.FC<Props> = (props) => {
  const { customer } = props
  const isValid = customerIsValid(customer)
  const { edit } = usePageContext()
  return (
    <Menu type='context-menu' menu={<ContextMenu {...props} />} asChild>
      <div className='grid' onDoubleClick={() => edit(customer)}>
        <Row>
          <Row.Image>
            <UserIcon size={16} />
          </Row.Image>
          <Row.Header>
            <Row.Title>{customer.name}</Row.Title>
            {oneIsNotEmpty(
              customer.address1,
              customer.address2,
              customer.postalCode,
              customer.city,
              customer.country
            ) && (
              <Row.Description className='[&>span]:block'>
                {oneIsNotEmpty(customer.address1) && <span>{customer.address1}</span>}
                {oneIsNotEmpty(customer.address2) && <span>{customer.address2}</span>}
                {oneIsNotEmpty(customer.postalCode, customer.city) && (
                  <span>
                    {customer.postalCode} {customer.city} {customer.country}
                  </span>
                )}
              </Row.Description>
            )}
            <Row.Description className='flex pt-2 gap-4'>
              {oneIsNotEmpty(customer.reference) && (
                <Badge variant='secondary'>
                  <Hash aria-hidden />
                  {customer.reference}
                </Badge>
              )}
              {oneIsNotEmpty(customer.vat) && (
                <Badge variant='secondary'>
                  <SquareUser aria-hidden />
                  {customer.peppolId}
                </Badge>
              )}
              {oneIsNotEmpty(customer.vat) && (
                <Badge variant='secondary'>
                  <BadgeEuro aria-hidden />
                  {customer.vat}
                </Badge>
              )}
            </Row.Description>
          </Row.Header>
          <Row.Content className='flex flex-wrap gap-2'>
            <Row.Icon
              tooltip={isValid ? "Valid customer" : "Invalid customer"}
              className={isValid ? "text-green-600" : "text-amber-600"}
            >
              {isValid ? <Check /> : <TriangleAlert />}
            </Row.Icon>
          </Row.Content>
          <Row.Menu menu={<ContextMenu {...props} />} />
        </Row>
      </div>
    </Menu>
  )
}
