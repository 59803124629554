import { ApiErrorLog } from "@/services/error-logs/schemas"
import { localizeInvoice } from "../invoices/localizers"

/**
 * localizeErrorLog
 */
export const localizeErrorLog = ({ invoice, createdAt, ...parsed }: ApiErrorLog) => ({
  ...D.selectKeys(parsed, ["id", "parsedJson", "beinvoice"]),
  invoice: localizeInvoice(JSON.parse(invoice)),
  invoiceUnparsed: invoice,
  createdAt: T.parseISO(createdAt),
})
export type ErrorLog = ReturnType<typeof localizeErrorLog>
