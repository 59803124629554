import { customerIsValid } from "@/store/customers/helpers"
import { Customer } from "@/store/customers/localizers"
import { Invoice, InvoiceLine } from "@/store/invoices/localizers"
import { match } from "ts-pattern"
import { round } from "./math"

/**
 * const calculation functions
 */
const getTotalBeforeDiscount = (line: InvoiceLine) => line.unitPriceNoTax * line.quantity
const getDiscountAmount = (line: InvoiceLine) =>
  (getTotalBeforeDiscount(line) * line.discountPercentage) / 100
const getTotalNoTax = (line: InvoiceLine) =>
  round(getTotalBeforeDiscount(line) - getDiscountAmount(line))
const getTax = (line: InvoiceLine) => round((getTotalNoTax(line) * line.vat) / 100) // ! round readded

/**
 * getTotalNoTax
 */
export const getComputedTotalWithTax = (lines: InvoiceLine[]): number =>
  A.reduce(lines, 0, (total, line) => {
    return total + getTotalNoTax(line) + getTax(line)
  })

/**
 * taxByVat
 */
export const taxByVat = (
  type: "normal" | "intermediate" | "reduced" | "superReduced",
  vat: number,
  tax: number
) =>
  match(type)
    .with("normal", () => (A.includes([16, 17], vat) ? tax : 0))
    .with("intermediate", () => (A.includes([14, 13], vat) ? tax : 0))
    .with("reduced", () => (A.includes([8, 7], vat) ? tax : 0))
    .with("superReduced", () => (A.includes([3], vat) ? tax : 0))
    .exhaustive()

/**
 * getLinesStats
 */
export const getLinesStats = (lines: InvoiceLine[]) =>
  A.reduce(lines, initialStats, (acc, line) => {
    const discountAmount = getDiscountAmount(line)
    const totalNoTax = getTotalNoTax(line)
    const tax = getTax(line)
    return {
      sumTaxNormal: acc.sumTaxNormal + taxByVat("normal", line.vat, tax),
      sumTaxIntermediate: acc.sumTaxIntermediate + taxByVat("intermediate", line.vat, tax),
      sumTaxReduced: acc.sumTaxReduced + taxByVat("reduced", line.vat, tax),
      sumTaxSuperReduced: acc.sumTaxSuperReduced + taxByVat("superReduced", line.vat, tax),
      sumTax: acc.sumTax + tax,
      discountAmount: acc.discountAmount + discountAmount,
      totalNoTax: acc.totalNoTax + totalNoTax,
      totalWithTax: acc.totalWithTax + totalNoTax + tax,
    }
  })

const initialStats = {
  sumTaxNormal: 0,
  sumTaxIntermediate: 0,
  sumTaxReduced: 0,
  sumTaxSuperReduced: 0,
  sumTax: 0,
  discountAmount: 0,
  totalNoTax: 0,
  totalWithTax: 0,
}

/**
 * inlineTaxeByVat
 * base of calulation must correspond to the one in getLinesStats
 */
export const inlineTaxeByVat = (invoice: Invoice) =>
  A.reduce(
    invoice.invoiceLines,
    {
      normal: [] as number[],
      intermediate: [] as number[],
      reduced: [] as number[],
      superReduced: [] as number[],
    },
    (acc, line) => {
      const tax = getTax(line)
      const taxNames = ["normal", "intermediate", "reduced", "superReduced"] as const
      const taxName = A.find([...taxNames], name => taxByVat(name, line.vat, tax) > 0)
      if (G.isNullable(taxName)) return acc
      return D.set(acc, taxName, A.append(acc[taxName], taxByVat(taxName, line.vat, tax)))
    }
  )

/**
 * lineIsValid
 */
export const lineIsValid = (line: InvoiceLine) => {
  //"unitPriceNoTax", "quantity"
  const isValid = pipe(
    line,
    D.selectKeys(["name", "reference", "vat", "vatCode", "unitCode"]),
    D.values,
    A.every(field =>
      G.isString(field) ? S.isNotEmpty(field) : G.isNumber(field) ? N.gte(field, 0) : false
    )
  )
  return isValid
}

/**
 * linesAreValid
 */
export const linesAreValid = (invoice: Invoice) => {
  return A.isNotEmpty(invoice.invoiceLines) && A.every(invoice.invoiceLines, lineIsValid)
}

/**
 * detailsAreValid
 */
export const detailsAreValid = (invoice: Invoice) => {
  return pipe(
    invoice,
    D.selectKeys([
      "invoiceReference",
      "senderProjectReference",
      "senderProjectReference",
      "customerProjectReference",
    ]),
    D.values,
    A.every(field => G.isString(field) && S.isNotEmpty(field))
  )
}

/**
 * datesAreValid
 */
export const datesAreValid = (invoice: Invoice) => {
  return pipe(
    invoice,
    D.selectKeys(["date", "dueDate"]),
    D.values,
    A.every(field => G.isDate(field) && T.isValid(field))
  )
}

/**
 * attachmentIsValid
 */
export const attachmentIsValid = (invoice: Invoice) => {
  return G.isNotNullable(invoice.attachment)
}

/**
 * invoiceIsValid
 */
export const invoiceIsValid = (invoice: Invoice, customers: Customer[]) => {
  // check if all lines are valid
  if (!linesAreValid(invoice)) return false

  // check if customer is valid
  if (
    A.some(customers, customer => customer.id === invoice.customerId && !customerIsValid(customer))
  )
    return false

  // check if each field is valid
  if (!detailsAreValid(invoice)) return false

  // check if dates are valid
  if (!datesAreValid(invoice)) return false

  // check if attachment is valid
  if (!attachmentIsValid(invoice)) return false

  return true
}
