import { ApiCustomer } from "@/services/customers/schemas"

/**
 * localizeMe
 */
export const localizeCustomer = ({ ...parsed }: ApiCustomer) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "reference",
    "address1",
    "address2",
    "postalCode",
    "city",
    "country",
    "vat",
    "peppolId",
  ]),
})
export type Customer = ReturnType<typeof localizeCustomer>
