import "./ninja.css"

interface Props {
  message?: string
  messageTimeMs?: number
  color?: string
  logoSrc?: string
  logoRender?: React.ReactNode
  pClassName?: string
}

export const Ninja: React.FC<Props> = ({
  messageTimeMs = 10 * 1000,
  logoSrc,
  logoRender = <Logo />,
  pClassName = undefined,
  color = undefined,
}) => {
  const [hide, setHide] = React.useState(false)
  const [cleanUp, setCleanUp] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setHide(true), messageTimeMs)
    setTimeout(() => setCleanUp(true), messageTimeMs + 2000)
  }, [])
  const logoToUse = logoSrc ? <img src={logoSrc} width="50px" height="50px" /> : logoRender
  const baseClassName = pClassName ? pClassName : "react-ninja-message"
  const styleMyNinja = { "--my-ninja-color": color ? color : "red" } as React.CSSProperties
  const message = React.useMemo(() => randomMessage(messages), [])
  return (
    <>
      {!cleanUp && (
        <div
          className={`${baseClassName}-ninja-drop ${
            hide ? "backOutLeftNinja" : "bounceInDownNinjaAnimation"
          }`}
        >
          {!hide && (
            <div
              className={`${baseClassName}-bubble ${baseClassName}-bubble-bottom-left bounceInBubble`}
            >
              {message}
            </div>
          )}
          <div className={`${baseClassName}-ninja color`} style={styleMyNinja}>
            <div className="n--head">
              <div className="n--ribbon"></div>
              <div className="n--eyes"></div>
            </div>
            <div className={`${baseClassName}-ninja-logo`}>{logoToUse}</div>
            <div className="n--body">
              <div className="n--weapon"></div>
              <div className="n--arm"></div>
              <div className="n--arm"></div>
              <div className="n--leg"></div>
              <div className="n--leg"></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Logo: React.FC = () => (
  <div className="pt-4 text-slate-200">
    <svg viewBox="0 0 64 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.61178 0.308715L-0.000961547 5.88333L-0.000961886 13.6192L6.61178 8.06172L6.61178 20L14.04 20L14.04 0.308716L6.61178 0.308715Z" />
      <path d="M64 20L64 0.308716L56.5717 0.308715L49.9413 5.88333L49.9413 13.6192L56.5717 8.06172L56.5717 20L64 20Z" />
      <path d="M44.7471 20L44.7471 11.7153C44.7471 4.2024 39.0385 -2.4953e-07 32.408 -5.39357e-07C25.7775 -8.29184e-07 20.0158 4.2024 20.0158 11.7153L20.0158 20L27.444 20L27.444 11.8182C27.444 8.50772 29.5005 6.4494 32.408 6.4494C35.3155 6.4494 37.3188 8.49057 37.3188 11.8182L37.3188 20L44.7471 20Z" />
    </svg>
  </div>
)
const randomMessage = (messages: string[]): string => {
  const randomIndex = Math.floor(Math.random() * messages.length)
  return messages[randomIndex] as string
}
const messages = [
  "You found me! Welcome to the secret world of 101 Ninjas!",
  "Shhh... It's a secret! You've unlocked the ninja's hidden message!",
  "Ninja mode activated! Keep up the great work at 101!",
  "Surprise! The 101 Ninja is watching over your projects!",
  "Ninja detected! 101 thanks you for your hard work!",
]
