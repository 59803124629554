import { Limitable } from "@/hooks/useLimitable"
import { useInView } from "react-intersection-observer"
import { Button } from "../ui/button"

/**
 * LimitMore
 */
export const LimitMore: React.FC<Limitable> = ({ reached, takeMore }) => {
  const { ref: showMoreRef, inView } = useInView({})
  React.useEffect(() => {
    if (inView) takeMore()
  }, [inView, takeMore])
  return reached ? (
    <div className='flex justify-center'>
      <Button variant='outline' onClick={takeMore} ref={showMoreRef}>
        More
      </Button>
    </div>
  ) : null
}
