import { Empty, Grid, Toolbar } from "@/components/collection"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout"
import { Select } from "@/components/ui/select"
import { useMatchable } from "@/hooks/useMatchable"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { countries } from "@/services/countries"
import { SearchResult } from "@/services/search-peppol/schemas"
import { service } from "@/services/search-peppol/service"
import { Item } from "./Item"

/**
 * SearchPeppol
 */
const SearchPeppol: React.FC = () => {
  //
  const [{ search, setSearch }, matchIn] = useMatchable<SearchResult>([D.getUnsafe("name")])

  const [country, setCountry] = React.useState<string>(() => S.toUpperCase(A.head(countries)?.alpha2 ?? ""))
  const countryOptions = useMemoOnce(() =>
    countries.map(({ fr, alpha2 }) => ({ value: S.toUpperCase(alpha2), label: fr }))
  )

  const [result, setResult] = React.useState<SearchResult[]>([])
  React.useEffect(() => {
    searchPeppol(country, search, setResult)
  }, [search, country])

  const filtered = React.useMemo(() => pipe(result, matchIn), [result, matchIn, search])

  return (
    <PageWrapper>
      <PageHeader breadcrumbs={[["Search on Peppol", "/dashboard/search-peppol"]]} />
      <PageContent>
        <Toolbar>
          <Toolbar.Search {...{ search, setSearch }} />
          <Select onValueChange={setCountry} defaultValue={country}>
            <Select.Trigger className={cx("max-w-64")}>
              <Select.Value />
            </Select.Trigger>
            <Select.Content>
              {A.mapWithIndex(countryOptions, (index, { label, ...option }) => (
                <Select.Item key={index} {...option}>
                  {label}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
          {/* <Toolbar.Button onClick={() => create()}>Create a new customer</Toolbar.Button> */}
        </Toolbar>
        <Grid view='list'>
          {A.map(filtered, (item) => (
            <Item key={item.peppolId} item={item} />
          ))}
        </Grid>
        {S.isNotEmpty(search) && <Empty items={[1]} results={filtered} />}
      </PageContent>
    </PageWrapper>
  )
}
export default SearchPeppol

let raceId = 0
const searchPeppol = async (
  country: string,
  q: string,
  setResult: React.Dispatch<React.SetStateAction<SearchResult[]>>
) => {
  const id = ++raceId
  const { data, error } = await service.search(country, q)
  if (id < raceId) return
  if (error) return
  setResult(data)
}
