import { emptyStringToNull } from "@/fns/string"
import { z } from "zod"
import { AuthErrorCode, NoContent, api } from "../api"
import { ApiInvoiceParsedFile, apiInvoiceParsedFile } from "./parse"
import { ApiInvoice, ApiInvoiceLine, apiInvoice, apiInvoiceType, vatCode } from "./schemas"

/**
 * payloads
 */

// lines

const createLinePayload = z.object({
  quantity: z.number().optional(),
  unitCode: z.string().optional(),
  reference: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  unitPriceNoTax: z.number().optional(),
  vatCode: vatCode.optional(),
  vat: z.number().optional(),
  discountPercentage: z.number().optional(),
})
const updateLinePayload = createLinePayload
const reorderLinePayload = z.object({
  lines: z.string().uuid().array(),
})

// invoices

const createPayload = z.object({
  date: z.string().optional(),
  dueDate: z.string().optional(),
  dueDateText: z.string().optional(), // max 255
  invoiceReference: z.string().optional(), // max 255
  type: apiInvoiceType.optional(),
  senderProjectReference: z.string().optional(), // max 255
  customerProjectReference: z.string().optional(), // max 255
  note: z.string().optional(), // max 255
  customerId: z.string().optional(),
  attachment: z.instanceof(File).nullable().optional(),
  invoiceLines: createLinePayload.array().optional(),
})
const updatePayload = z.object({
  date: z.string().optional(),
  dueDate: z.string().optional(),
  dueDateText: z.string().optional(), // max 255
  invoiceReference: z.string().optional(), // max 255
  type: apiInvoiceType.optional(),
  senderProjectReference: z.string().optional(), // max 255
  customerProjectReference: z.string().optional(), // max 255
  note: z.string().optional(), // max 255
  customerId: z.string().optional(),
  attachment: z.instanceof(File).nullable().optional(),
})

const parsePayload = z.object({
  files: z.instanceof(File).array(),
})

export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
  parse: z.infer<typeof parsePayload>
  lines: {
    reorder: z.infer<typeof reorderLinePayload>
    create: z.infer<typeof createLinePayload>
    update: z.infer<typeof updateLinePayload>
  }
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { invoices: ApiInvoice[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("invoices")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { invoices: A.map(data.invoices, apiInvoice.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { invoice: ApiInvoice }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const {
      dueDateText,
      invoiceReference,
      senderProjectReference,
      customerProjectReference,
      note,
      attachment,
      ...parsed
    } = createPayload.parse(payload)
    const prepared = {
      ...parsed,
      dueDateText: emptyStringToNull(dueDateText),
      invoiceReference: emptyStringToNull(invoiceReference),
      senderProjectReference: emptyStringToNull(senderProjectReference),
      customerProjectReference: emptyStringToNull(customerProjectReference),
      note: emptyStringToNull(note),
    }
    const { success, data } = await api.post<RSuccess, RError>("invoices", { data: prepared })
    if (!success) return { data: null, error: true, code: data.code } as const
    const id = data.invoice.id
    if (attachment) {
      const { success, data } = await api.put<RSuccess, RError>(`invoices/${id}`, {
        form: { attachment },
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
    }
    return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { invoice: ApiInvoice }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`invoices/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { invoice: ApiInvoice }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const {
      dueDateText,
      invoiceReference,
      senderProjectReference,
      customerProjectReference,
      note,
      attachment,
      ...parsed
    } = updatePayload.parse(payload)
    const prepared = {
      ...parsed,
      dueDateText: emptyStringToNull(dueDateText),
      invoiceReference: emptyStringToNull(invoiceReference),
      senderProjectReference: emptyStringToNull(senderProjectReference),
      customerProjectReference: emptyStringToNull(customerProjectReference),
      note: emptyStringToNull(note),
    }
    const { success, data } = await api.put<RSuccess, RError>(`invoices/${id}`, { data: prepared })
    if (!success) return { data: null, error: true, code: data.code } as const
    console.log(attachment)

    if (attachment) {
      const { success, data } = await api.put<RSuccess, RError>(`invoices/${id}`, {
        form: { attachment },
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
    }
    return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`invoices/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
  submit: async (id: string) => {
    type RSuccess = { invoice: ApiInvoice }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND" | "PARSE_ERROR" | "SUBMISSION_ERROR">
    const { success, data } = await api.post<RSuccess, RError>(`invoices/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
  },
  document: async (id: string) => {
    type RSuccess = { parsed: Record<string, string | number> }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND" | "PARSE_ERROR">
    const { success, data } = await api.get<RSuccess, RError>(`invoices/${id}/document`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { document: data.parsed }, error: false } as const
  },
  parse: async (payload: Payload["parse"]) => {
    type RSuccess = { files: (ApiInvoiceParsedFile | false)[] }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("invoices/parse", {
      form: parsePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    const files = A.map(data.files, file => (file ? apiInvoiceParsedFile.parse(file) : null))
    return { data: { files }, error: false } as const
  },
  lines: {
    create: async (invoiceId: string, payload: Payload["lines"]["create"]) => {
      type RSuccess = { line: ApiInvoiceLine; invoice: ApiInvoice }
      type RError = AuthErrorCode<"VALIDATION_FAILURE">
      const { success, data } = await api.post<RSuccess, RError>(`invoices/${invoiceId}/lines`, {
        data: createLinePayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: { invoice: apiInvoice.parse(data.invoice), id: data.line.id },
        error: false,
      } as const
    },
    update: async (invoiceId: string, lineId: string, payload: Payload["lines"]["update"]) => {
      type RSuccess = { line: ApiInvoiceLine; invoice: ApiInvoice }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const parsed = updateLinePayload.parse(payload)
      const prepared = {
        ...parsed,
        unitCode: emptyStringToNull(parsed.unitCode),
        reference: emptyStringToNull(parsed.reference),
        name: emptyStringToNull(parsed.name),
        description: emptyStringToNull(parsed.description),
      }
      const { success, data } = await api.put<RSuccess, RError>(
        `invoices/${invoiceId}/lines/${lineId}`,
        {
          data: prepared,
        }
      )
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
    },
    delete: async (invoiceId: string, lineId: string) => {
      type RSuccess = { invoice: ApiInvoice }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(
        `invoices/${invoiceId}/lines/${lineId}`
      )
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
    },
    reorder: async (invoiceId: string, payload: Payload["lines"]["reorder"]) => {
      type RSuccess = { invoice: ApiInvoice }
      type RError = AuthErrorCode<"VALIDATION_FAILURE">
      const { success, data } = await api.put<RSuccess, RError>(`invoices/${invoiceId}/lines`, {
        data: reorderLinePayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { invoice: apiInvoice.parse(data.invoice) }, error: false } as const
    },
  },
}
