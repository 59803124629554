/**
 * HN
 */
type HNProps = React.HTMLAttributes<HTMLHeadingElement> & {
  level?: number
}
const HN = React.forwardRef<HTMLHeadingElement, HNProps>(({ level = 1, ...props }, ref) => {
  const As = `h${A.includes([1, 2, 3, 4, 5, 6], level) ? level : 1}` as React.ElementType
  return <As {...props} ref={ref} />
})

/**
 * exports
 */
export { HN, type HNProps }
