import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { getLinesStats, inlineTaxeByVat } from "@/fns/invoice"
import { round } from "@/fns/math"
import { Invoice, InvoiceLine } from "@/store/invoices/localizers"

/**
 * DetailsDialog
 */
export const DetailsDialog: React.FC<UseDialogProps<Invoice>> = ({ item, onOpenChange, open }) => {
  return (
    <Dialog {...{ open, onOpenChange }} title="Detail of calulation" className="max-w-3xl">
      {item !== false && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}

const DialogForm: React.FC<UseDialogFormProps<Invoice>> = ({ item: invoice }) => {
  const [selected, setSelected] = React.useState<string | null>(null)
  const stats = React.useMemo(() => getLinesStats(invoice.invoiceLines), [invoice.invoiceLines])
  const taxeByVat = inlineTaxeByVat(invoice)

  return (
    <div className="flex flex-col gap-2">
      {A.map(invoice.invoiceLines, line => (
        <Line line={line} selected={selected} setSelected={setSelected} />
      ))}
      <div className="flex flex-col gap-1 p-3 bg-foreground text-background">
        <Row>
          <Col1>Subtotal</Col1>
          <Col2>{stats.totalNoTax}</Col2>
        </Row>
        {stats.sumTaxNormal > 0 && (
          <Row>
            <Col1>Tax Normal</Col1>
            <Col2>
              {stats.sumTaxNormal}
              <TaxeCalucation taxes={taxeByVat.normal} />
            </Col2>
          </Row>
        )}
        {stats.sumTaxIntermediate > 0 && (
          <Row>
            <Col1>Tax Intermediate</Col1>
            <Col2>
              {stats.sumTaxIntermediate}
              <TaxeCalucation taxes={taxeByVat.intermediate} />
            </Col2>
          </Row>
        )}
        {stats.sumTaxReduced > 0 && (
          <Row>
            <Col1>Tax Reduced</Col1>
            <Col2>
              {stats.sumTaxReduced}
              <TaxeCalucation taxes={taxeByVat.reduced} />
            </Col2>
          </Row>
        )}
        {stats.sumTaxSuperReduced > 0 && (
          <Row>
            <Col1>Tax Super Reduced</Col1>
            <Col2>
              {stats.sumTaxSuperReduced}
              <TaxeCalucation taxes={taxeByVat.superReduced} />
            </Col2>
          </Row>
        )}
        {stats.discountAmount > 0 && (
          <Row>
            <Col1>Discount</Col1>
            <Col2>{stats.discountAmount}</Col2>
          </Row>
        )}

        <Row>
          <Col1>VAT</Col1>
          <Col2>{stats.totalWithTax - stats.totalNoTax}</Col2>
        </Row>
        <Row>
          <Col1>Total</Col1>
          <Col2>{stats.totalWithTax}</Col2>
        </Row>
      </div>
    </div>
  )
}
const Line: React.FC<{
  line: InvoiceLine
  selected: string | null
  setSelected: (id: string | null) => void
}> = ({ line, selected, setSelected }) => {
  const { id, vat, quantity, unitPriceNoTax, discountPercentage } = line
  const totalBeforeDiscount = unitPriceNoTax * quantity
  const discountAmount = (totalBeforeDiscount * discountPercentage) / 100
  const totalNoTax = round(totalBeforeDiscount - discountAmount)
  const tax = round((totalNoTax * vat) / 100)
  const totalWithTax = totalNoTax + tax
  return (
    <div
      className={cxm(
        "flex flex-col gap-1 p-3 transition-all border-2 border-transparent",
        "odd:bg-muted/20",
        "even:bg-card",
        "hover:bg-primary-foreground",
        selected === id && "border-primary"
      )}
      onClick={() => setSelected(selected === id ? null : id)}
    >
      <div className="text-sm font-medium text-blue-500">{line.name}</div>
      <Row>
        <Col1>Total Before Discount</Col1>
        <Col2>
          {unitPriceNoTax} <S>x</S> {quantity} <E /> {totalBeforeDiscount}
        </Col2>
      </Row>
      {discountPercentage > 0 && (
        <Row>
          <Col1>Discount Amount</Col1>
          <Col2>
            <F>(</F> {totalBeforeDiscount} <X /> {discountPercentage} <F>)</F> <Di /> 100 <E />{" "}
            {discountAmount}
          </Col2>
        </Row>
      )}
      <Row>
        <Col1>Total No Tax</Col1>
        <Col2>
          <F>round(</F> {totalBeforeDiscount} <M /> {discountAmount} <F>)</F> <E /> {totalNoTax}{" "}
          <UnRounded>{totalBeforeDiscount - discountAmount}</UnRounded>
        </Col2>
      </Row>
      <Row>
        <Col1>Tax {vat} %</Col1>
        <Col2>
          <F>round(</F> {totalNoTax} <X /> {vat} <Di /> 100 <F>)</F> <E /> {tax}{" "}
          <UnRounded>{(totalNoTax * vat) / 100}</UnRounded>
        </Col2>
      </Row>
      <Row>
        <Col1>Total With Tax</Col1>
        <Col2>
          {totalNoTax} <P /> {tax} <E /> {totalWithTax}
        </Col2>
      </Row>
    </div>
  )
}

const LineT: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="flex flex-col gap-1 py-4">{children}</div>
)
const Row: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="grid grid-cols-12 text-xs">{children}</div>
)
const Col1: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="col-span-4 font-light">{children}</div>
)
const Col2: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="col-span-8 font-mono text-muted-foreground">{children}</div>
)
const F: React.FC<React.PropsWithChildren> = ({ children }) => (
  <span className="text-amber-500">{children}</span>
)
const S: React.FC<React.PropsWithChildren> = ({ children }) => (
  <span className="text-foreground">{children}</span>
)
const UnRounded: React.FC<React.PropsWithChildren> = ({ children }) => (
  <span className="text-green-500/50">({children})</span>
)
const TaxeCalucation: React.FC<{ taxes: number[] }> = ({ taxes }) => (
  <span className="pl-2 text-green-500">
    ({A.join(taxes, " + ")} = {A.reduce(taxes, 0, (acc, t) => acc + t)})
  </span>
)
const E: React.FC = () => <span className="text-blue-500">=</span>
const X: React.FC = () => <span className="text-foreground">x</span>
const P: React.FC = () => <span className="text-foreground">+</span>
const M: React.FC = () => <span className="text-foreground">-</span>
const Di: React.FC = () => <span className="text-foreground">/</span>
