import { NoError, api } from "../api"
import { ApiErrorLog, apiErrorLog } from "./schemas"

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { errorMessages: ApiErrorLog[] }
    type RError = NoError
    const { success, data } = await api.get<RSuccess, RError>("error-messages")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { errorLogs: A.map(data.errorMessages, apiErrorLog.parse) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { errorMessage: ApiErrorLog }
    type RError = NoError
    const { success, data } = await api.get<RSuccess, RError>(`error-messages/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { errorLog: apiErrorLog.parse(data.errorMessage) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoError
    type RError = NoError
    const { success, data } = await api.delete<RSuccess, RError>(`error-messages/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: null, error: false } as const
  },
}
