import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import { SrOnly } from "@/components/ui/sr-only"
import { capitalize } from "@/fns/string"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { Moon, Palette, Sun, SunMoon } from "lucide-react"
import { themeColors } from "./context"
import { useTheme } from "./useTheme"

/**
 * ThemeDropdown
 */
type ThemeDropdownProps = {
  beforeClick?: () => true
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
}
export const ThemeDropdown: React.FC<ThemeDropdownProps> = ({
  beforeClick = () => true,
  align = "end",
  side = "right",
}) => {
  const { setTheme, color: currentColor, setColor } = useTheme()
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button variant='ghost' icon className='rounded-full'>
          <Sun size={20} className='rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' aria-hidden />
          <Moon
            size={20}
            className='absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100'
            aria-hidden
          />
          <SrOnly>Select your theme</SrOnly>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align={align} side={side}>
        <DropdownMenu.Item onClick={() => beforeClick() && setTheme("light")}>
          <Sun aria-hidden />
          Light mode
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={() => beforeClick() && setTheme("dark")}>
          <Moon aria-hidden />
          Dark mode
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={() => beforeClick() && setTheme("system")}>
          <SunMoon aria-hidden />
          Default system
        </DropdownMenu.Item>
        <DropdownMenu.Sub>
          <DropdownMenu.Sub.Trigger>
            <Palette aria-hidden />
            Colors
          </DropdownMenu.Sub.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Sub.Content>
              {A.map([...themeColors], (color) => (
                <DropdownMenu.CheckboxItem
                  key={color}
                  checked={color === currentColor}
                  onCheckedChange={() => beforeClick() && setColor(color)}
                >
                  <span className='inline-flex w-4 h-4 mr-2' style={{ background: `hsl(var(--primary-${color}))` }} />
                  {capitalize(color)}
                </DropdownMenu.CheckboxItem>
              ))}
            </DropdownMenu.Sub.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Sub>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
