import { decorateStore } from "@/fns/zustand"
import { create } from "zustand"
import { Me } from "./localizers"

/**
 * types
 */
export type AuthStoreState = {
  initDone: boolean
  initError: boolean
  authenticated: boolean
  sid: string
  user: null | Me
}

/**
 * initialState
 */
const initialState: AuthStoreState = {
  initDone: false,
  initError: false,
  authenticated: false,
  sid: "",
  user: null,
}

/**
 * Store
 */
export const authStore = decorateStore(initialState, create, {})
export const useAuthStore = authStore.use
