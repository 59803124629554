import { Form } from "."

/**
 * FormGroup
 */
type Props = React.ComponentProps<"div"> & React.ComponentProps<typeof Form.Field>
export const FormGroup: React.FC<Props> = ({ name, className, children, ...props }) => {
  return (
    <Form.Field name={name}>
      <div className={cxm("flex flex-col items-stretch gap-2", className)} {...props}>
        {children}
      </div>
    </Form.Field>
  )
}
