import { decorateStore } from "@/fns/zustand"
import { create } from "zustand"
import { Invoice } from "./localizers"

/**
 * types
 */
export type InvoicesStoreState = {
  invoices: ById<Invoice>
}

/**
 * initialState
 */
const initialState: InvoicesStoreState = {
  invoices: {},
}

/**
 * store
 */
export const invoicesStore = decorateStore(initialState, create, {})
export const useInvoicesStore = invoicesStore.use
