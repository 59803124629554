import { useEventListener } from "./useEventListener"
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect"

/** @const {Breakpoints} breakpoints List of breakpoints available in an object */
const breakpoints: Breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
}

/**
 * Return an object to manage media queries state, Media queries are base on TaillWind default media queries
 * Do no hesitate to change values on name (dont forget to also update type) to be closer to your needs
 * min(breakpoint) : min-width breakpoint
 * max(breakpoint) : max-width breakpoint
 * between(breakpointSmallest, breakpointBiggest) : min-width breakpointSmallest max-width breakpointBiggest
 * breakpoint : current breakpoint
 * breakpoints : List of breakpoints available in an object
 */
export function useResponsive(): {
  min: (breakpoint: keyof Breakpoints) => boolean
  max: (breakpoint: keyof Breakpoints) => boolean
  between: (breakpointSmallest: keyof Breakpoints, breakpointBiggest: keyof Breakpoints) => boolean
  breakpoint: keyof Breakpoints
  breakpoints: Breakpoints
} {
  const [breakpoint, setBreackpoint] = React.useState<keyof Breakpoints>("2xl")
  const [windowWidth, setWindowSize] = React.useState<number>(() => window.innerWidth)
  const handleSize = () => setWindowSize(window.innerWidth) // Update windowWidth
  const handleBreakpoint = () => {
    // Update current breakpoint
    const cB = pipe(
      breakpoints,
      D.toPairs,
      A.reverse,
      A.find((b) => b[1] <= windowWidth)
    )
    const key = G.isNotNullable(cB) ? cB[0] : "2xl"
    setBreackpoint(key as keyof Breakpoints) // Typescript hack to be sure to have every time the right type
  }
  // Update state on window resize
  useEventListener("resize", () => {
    handleSize()
    handleBreakpoint()
  })
  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize()
    handleBreakpoint()
  }, [])

  return {
    //the biggest, the smallest
    min: (breakpoint: keyof Breakpoints) => windowWidth > breakpoints[breakpoint],
    max: (breakpoint: keyof Breakpoints) => windowWidth < breakpoints[breakpoint],
    between: (breakpointSmallest: keyof Breakpoints, breakpointBiggest: keyof Breakpoints) =>
      windowWidth > breakpoints[breakpointSmallest] && windowWidth < breakpoints[breakpointBiggest],
    breakpoint,
    breakpoints,
  }
}

/**
 * types
 */
type Breakpoints = {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
  "2xl": number
}
