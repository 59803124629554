import { Menu, Row, useMenu } from "@/components/collection"
import { en } from "@/fns/date-fns"
import { useCustomer } from "@/store/customers/hooks"
import { ErrorLog } from "@/store/error-logs/localizers"
import { BugIcon, Trash } from "lucide-react"
import { usePageContext } from "./Context"

type Props = { log: ErrorLog }

export const Item: React.FC<Props> = (props) => {
  const { log } = props
  const { customerId, createdAt } = log.invoice
  const customer = useCustomer(customerId)

  return (
    <Menu type='context-menu' menu={<ContextMenu {...props} />} asChild>
      <div className='grid' onDoubleClick={() => navigate(`/dashboard/error-logs/${log.id}`)}>
        <Row>
          <Row.Image>
            <BugIcon size={16} />
          </Row.Image>
          <Row.Header>
            <Row.Title>{T.format(createdAt, "PPPPp", { locale: en })}</Row.Title>
            <Row.Description>{customer?.name}</Row.Description>
          </Row.Header>
          <Row.Content className='flex flex-wrap gap-2'></Row.Content>
          <Row.Menu menu={<ContextMenu {...props} />} />
        </Row>
      </div>
    </Menu>
  )
}

/**
 * ContextMenu
 */
const ContextMenu: React.FC<Props> = ({ log }) => {
  const ctx = usePageContext()
  const { type } = useMenu()

  return (
    <>
      <Menu.Item onClick={() => navigate(`/dashboard/error-logs/${log.id}`)}>
        <BugIcon />
        Display log
      </Menu.Item>
      <Menu.Item onClick={() => ctx.confirmDelete(log.id)}>
        <Trash />
        Delete log
      </Menu.Item>
    </>
  )
}
