import { byId } from "@/fns/collection"
import * as Invoices from "@/services/invoices/service"
import { match } from "ts-pattern"
import { invoicesStore } from "."
import { localizeInvoice } from "./localizers"

/**
 * getInvoices
 */
export const getInvoices = async () =>
  match(await Invoices.service.index())
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: byId(data.invoices, localizeInvoice),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createInvoice
 */
export const createInvoice = async (payload: Invoices.Payload["create"]) =>
  match(await Invoices.service.create(payload))
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: D.set(data.invoice.id, localizeInvoice(data.invoice)),
      })
      return { error: false, id: data.invoice.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getInvoice
 */
export const getInvoice = async (id: string) =>
  match(await Invoices.service.read(id))
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: D.set(data.invoice.id, localizeInvoice(data.invoice)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateInvoice
 */
export const updateInvoice = async (id: string, payload: Invoices.Payload["update"]) =>
  match(await Invoices.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: D.set(data.invoice.id, localizeInvoice(data.invoice)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteInvoice
 */
export const deleteInvoice = async (id: string) =>
  match(await Invoices.service.delete(id))
    .with({ error: false }, () => {
      invoicesStore.evolve({
        invoices: D.deleteKey(id),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * submitInvoice
 */
export const submitInvoice = async (id: string) =>
  match(await Invoices.service.submit(id))
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: D.set(data.invoice.id, localizeInvoice(data.invoice)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * reorderInvoiceLines
 */
export const reorderInvoiceLines = async (
  id: string,
  payload: Invoices.Payload["lines"]["reorder"]
) => {
  if (G.isNullable(D.get(invoicesStore.current.invoices, id))) {
    const response = await getInvoice(id)
    if (response.error) return { error: true, code: response.code } as const
  }

  // optimist update with new order before sending request
  invoicesStore.evolve({
    invoices: {
      [id]: {
        invoiceLines: flow(
          A.map(line =>
            D.set(
              line,
              "position",
              A.getIndexBy(payload.lines, current => current === line.id) ?? 0
            )
          )
        ),
      },
    },
  })
  return match(await Invoices.service.lines.reorder(id, payload))
    .with({ error: false }, () => ({ error: false } as const))
    .otherwise(({ error, code }) => ({ error, code } as const))
}

/**
 * createInvoiceLine
 */
export const createInvoiceLine = async (
  invoiceId: string,
  payload: Invoices.Payload["lines"]["create"]
) =>
  match(await Invoices.service.lines.create(invoiceId, payload))
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: D.set(data.invoice.id, localizeInvoice(data.invoice)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateInvoiceLine
 */
export const updateInvoiceLine = async (
  invoiceId: string,
  id: string,
  payload: Invoices.Payload["lines"]["update"]
) =>
  match(await Invoices.service.lines.update(invoiceId, id, payload))
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: D.set(data.invoice.id, localizeInvoice(data.invoice)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteInvoiceLine
 */
export const deleteInvoiceLine = async (invoiceId: string, id: string) =>
  match(await Invoices.service.lines.delete(invoiceId, id))
    .with({ error: false }, ({ data }) => {
      invoicesStore.evolve({
        invoices: D.set(data.invoice.id, localizeInvoice(data.invoice)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
