import { z } from "zod"

/**
 * PeppolContact
 */
// const peppolContact = z.object({
//   type: z.string(),
//   name: z.string(),
//   phone: z.string(),
//   email: z.string(),
// })
// type PeppolContact = z.infer<typeof peppolContact>

/**
 * PeppolIdentifier
 */
const peppolIdentifier = z.object({
  // scheme: z.string(),
  value: z.string(),
})
// type PeppolIdentifier = z.infer<typeof peppolIdentifier>

/**
 * PeppolName
 */
const peppolName = z.object({
  name: z.string(),
})
// type PeppolName = z.infer<typeof peppolName>

/**
 * PeppolEntity
 */
const peppolEntity = z.object({
  name: peppolName.array(),
  // countryCode: z.string(),
  identifiers: peppolIdentifier.array().optional(),
  // geoInfo: z.string().optional(),
  // websites: z.array(z.string()).optional(),
  // contacts: peppolContact.array().optional(),
  // regDate: z.string().optional(),
})
// type PeppolEntity = z.infer<typeof peppolEntity>

/**
 * PeppolDocType
 */
const peppolDocType = z.object({
  scheme: z.string(),
  value: z.string(),
})
// type PeppolDocType = z.infer<typeof peppolDocType>

/**
 * PeppolParticipantId
 */
const peppolParticipantId = z.object({
  // scheme: z.string(),
  value: z.string(),
})
// type PeppolParticipantId = z.infer<typeof peppolParticipantId>

/**
 * PeppolMatch
 */
export const peppolMatch = z.object({
  participantID: peppolParticipantId,
  // docTypes: peppolDocType.array(),
  entities: peppolEntity.array(),
})
// type PeppolMatch = z.infer<typeof peppolMatch>

/**
 * PeppolSearch
 */
export const peppolSearch = z.object({
  // version: z.string(),
  // "total-result-count": z.number(),
  // "used-result-count": z.number(),
  // "result-page-index": z.number(),
  // "result-page-count": z.number(),
  // "first-result-index": z.number(),
  // "last-result-index": z.number(),
  // "query-terms": z.string(),
  // "creation-dt": z.string(),
  matches: peppolMatch.array(),
})
export type PeppolSearch = z.infer<typeof peppolSearch>

export type SearchResult = {
  name: string
  peppolId: string
  vat?: string
}
