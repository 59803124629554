import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"

/**
 * PageError
 */
type PageErrorProps = {
  title?: string
  secondary?: string
  message?: string
  displayRedirection?: boolean
}
export const PageError: React.FC<PageErrorProps> = ({ title, secondary, message, displayRedirection = true }) => {
  const refresh = () => location.reload()
  return (
    <div className='flex justify-center items-center w-full min-h-screen p-8 bg-secondary' role='status'>
      <Card className={cxm("w-full max-w-sm", G.isNullable(title) && G.isNullable(secondary) && "pt-6")}>
        {(G.isNotNullable(title) || G.isNotNullable(secondary)) && (
          <Card.Header>
            {G.isNotNullable(title) && <Card.Title>{title}</Card.Title>}
            {G.isNotNullable(secondary) && <Card.Description>{secondary}</Card.Description>}
          </Card.Header>
        )}
        {G.isNotNullable(message) && (
          <Card.Content className='flex flex-col gap-6'>
            <p className='text-sm font-light'>{message}</p>
            {displayRedirection && (
              <div className='flex gap-2'>
                <Button onClick={refresh}>Reload</Button>
                <Button onClick={() => navigate("/")} variant={"secondary"}>
                  Home
                </Button>
              </div>
            )}
          </Card.Content>
        )}
      </Card>
    </div>
  )
}
