import { useAuthStore } from "@/store/auth"
import { DefaultParams, Redirect, Route, RouteProps } from "wouter"

/**
 * RouteUnauthenticated
 */
interface Props extends RouteProps {
  redirect?: string
}
export const RouteUnauthenticated: React.FC<Props> = ({ redirect = "/", children, ...props }) => {
  const { authenticated } = useAuthStore()
  return (
    <>
      {authenticated ? (
        <Route {...props}>
          <Redirect to={redirect} />
        </Route>
      ) : (
        <Route {...props}>{G.isFunction(children) ? (params: DefaultParams) => children(params) : children}</Route>
      )}
    </>
  )
}
