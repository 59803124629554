import { FormFileType } from "@/components/form/files"
import { useConfetti } from "@/components/layout/sidebar/context"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { formatError } from "@/fns/format-error"
import { service } from "@/services/invoices/service"
import { resetAllStoresAndReload } from "@/store"
import { deleteInvoice, deleteInvoiceLine, submitInvoice } from "@/store/invoices/actions"
import { Invoice, InvoiceLine } from "@/store/invoices/localizers"
import { saveAs } from "file-saver"
import { match } from "ts-pattern"
import { CreateDialog } from "./CreateDialog"
import { CreateLineDialog } from "./CreateLineDialog"
import { DetailsDialog } from "./DetailsDialog"
import { EditDialog } from "./EditDialog"
import { EditLineDialog } from "./EditLineDialog"
import { UploadDialog } from "./UploadDialog"

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteInvoice,
    dictionary: {
      title: "Delete invoice",
      description: "Are you sure you want to delete this invoice?",
      success: "Invoice deleted",
      error: "Failed to delete invoice",
      progress: "Deleting invoice",
    },
  })
  const { setItem: upload, ...uploadProps } = useDialog<FormFileType[]>()
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: edit, ...editProps } = useDialog<Invoice>()
  const { setItem: details, ...detailsProps } = useDialog<Invoice>()

  const { confirm: confirmDeleteLine, ...deleteLineProps } = useConfirm<InvoiceLine>({
    onAsyncConfirm: line => deleteInvoiceLine(line.invoiceId, line.id),
    dictionary: {
      title: "Delete product",
      description: "Are you sure you want to delete this product?",
      success: "Product deleted",
      error: "Failed to delete product",
      progress: "Deleting product",
    },
  })
  const { setItem: createLine, ...createLineProps } = useDialog<string>()
  const { setItem: editLine, ...editLineProps } = useDialog<InvoiceLine>()

  const download = async (invoice: Invoice) =>
    match(await service.document(invoice.id))
      .with({ error: false }, ({ data }) => {
        const file = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(data.document, null, "\t")
        )}`
        const name = `peppol-invoice-${invoice.invoiceReference}-${new Date().getTime()}.json`
        saveAs(file, name)
      })
      .otherwise(({ code }) =>
        match(code)
          .with("PARSE_ERROR", () => toast.error(`Failed to parse document`))
          .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
          .otherwise(code => toast.error(formatError(code)))
      )

  const { confetti } = useConfetti()
  const submit = async (invoice: Invoice) =>
    match(await submitInvoice(invoice.id))
      .with({ error: false }, () => {
        toast.success(`Invoice submitted successfully`)
        navigate("/dashboard/invoices")
        confetti()
      })
      .otherwise(({ code }) =>
        match(code)
          .with("PARSE_ERROR", () => toast.error(`Failed to parse document`))
          .with("SUBMISSION_ERROR", () => toast.error(`Failed to submit document`))
          .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
          .otherwise(code => toast.error(formatError(code)))
      )

  return (
    <Context.Provider
      value={{
        upload,
        create,
        edit,
        confirmDelete,
        download,
        submit,
        details,
        createLine,
        editLine,
        confirmDeleteLine,
      }}
    >
      {children}
      <UploadDialog {...uploadProps} />
      <CreateDialog {...createProps} />
      <EditDialog {...editProps} />

      <Confirm {...deleteProps} />
      <DetailsDialog {...detailsProps} />

      <Confirm {...deleteLineProps} />
      <CreateLineDialog {...createLineProps} />
      <EditLineDialog {...editLineProps} />
    </Context.Provider>
  )
}

/**
 * types
 */
type ContextType = {
  upload: (files: FormFileType[]) => void
  create: () => void
  edit: (invoice: Invoice) => void
  confirmDelete: (id: string) => void
  download: (invoice: Invoice) => void
  submit: (invoice: Invoice) => void
  details: (invoice: Invoice) => void
  createLine: (invoiceId: string) => void
  editLine: (line: InvoiceLine) => void
  confirmDeleteLine: (line: InvoiceLine) => void
}
