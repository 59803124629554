import Layout from "@/components/layout"
import Home from "@/pages"
import Customers from "@/pages/dashboard/customers"
import ErrorLogs from "@/pages/dashboard/error-logs"
import ErrorLog from "@/pages/dashboard/error-logs/error-log"
import Invoices from "@/pages/dashboard/invoices"
import InvoicePage from "@/pages/dashboard/invoices/invoice"
import SearchPeppol from "@/pages/dashboard/search-peppol"
import { Redirect, Route, Switch } from "wouter"
import { RouteAuthenticated } from "./authenticated"
import { RouteUnauthenticated } from "./unauthenticated"

/**
 * Routes
 */
const Routes: React.FC = () => (
  <Switch>
    <RouteAuthenticated path='/dashboard/:rest*' redirect='/'>
      <DashboardRoutes />
    </RouteAuthenticated>
    <RouteUnauthenticated path='/' redirect='/dashboard'>
      <Home />
    </RouteUnauthenticated>
    <Redirect to='/' />
  </Switch>
)
export default Routes

/**
 * DashboardRoutes
 */
const DashboardRoutes: React.FC = () => (
  <Layout>
    <Switch>
      {/* invoice */}
      <Route path='/dashboard/invoices/:id'>{(params) => <InvoicePage {...params} />}</Route>
      {/* invoices */}
      <Route path='/dashboard/invoices'>
        <Invoices />
      </Route>
      {/* error-logs */}
      <Route path='/dashboard/error-logs'>
        <ErrorLogs />
      </Route>
      {/* error-log */}
      <Route path='/dashboard/error-logs/:id'>{(params) => <ErrorLog {...params} />}</Route>
      {/* search-peppol */}
      <Route path='/dashboard/search-peppol'>
        <SearchPeppol />
      </Route>
      {/* customers */}
      <Route path='/dashboard/customers'>
        <Customers />
      </Route>
      {/* dashboard */}
      <Route path='/dashboard'>
        <Invoices />
      </Route>
      <Redirect to='/dashboard' />
    </Switch>
  </Layout>
)
