/**
 * useSpotlight
 */
export const useSpotlight = () => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [isFocused] = React.useState(false)
  const [position, setPosition] = React.useState({ x: 0, y: 0 })
  const [opacity, setOpacity] = React.useState(0)

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!ref.current || isFocused) return
    const rect = ref.current.getBoundingClientRect()
    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top })
  }

  const onFocus = () => {
    //
  }

  const onBlur = () => {
    //
  }

  const onMouseEnter = () => {
    setOpacity(1)
  }

  const onMouseLeave = () => {
    setOpacity(0)
  }
  return {
    cardProps: { onMouseLeave, onMouseEnter, onBlur, onFocus, onMouseMove, ref },
    cardSpotlightProps: { position, opacity },
  }
}
