import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useMatchable"
import { useSortable } from "@/hooks/useSortable"
import { ApiInvoiceType } from "@/services/invoices/schemas"
import { useInvoicesStore } from "."
import { Invoice } from "./localizers"

/**
 * hooks
 */
export const useInvoicesById = () => useInvoicesStore(D.getUnsafe("invoices"))
export const useInvoices = () => useInvoicesStore(flow(D.getUnsafe("invoices"), D.values))
export const useInvoice = (id: Option<string>) => useInvoicesStore(flow(D.getUnsafe("invoices"), D.get(id ?? "")))

/**
 * collection
 */
export const useFilteredInvoices = (initialLimit = 24, initialByMore = 12) => {
  const invoices = useInvoices()

  const [filterable, filterBy] = useFilterable<Invoice>(
    "invoices-filters",
    {
      notSubmitted: ({ status }) => status !== "submitted",
      submitted: ({ status }) => status === "submitted",
      pending: ({ status }) => status === "pending",
      invalid: ({ status }) => status === "invalid",
      credit: ({ type }) => type === "credit",
    },
    {
      notSubmitted: true,
    }
  )

  const [matchable, matchIn] = useMatchable<Invoice>([
    (
      //
      { invoiceReference }
    ) => invoiceReference,
    //
  ])

  const [sortable, sortBy] = useSortable<Invoice>(
    "invoices-sort",
    {
      reference: ({ invoiceReference }) => invoiceReference,
      date: ({ date, createdAt }) => date ?? createdAt,
    },
    "date",
    "DESC"
  )

  const filtered = React.useMemo(
    () => pipe(invoices, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [invoices, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<Invoice>(filtered.length, initialLimit, initialByMore)

  return { invoices, filtered, filterable, matchable, sortable, limitable, limit }
}

/**
 * form
 */
export const useInvoiceTypeOptions = () => {
  const typeOptions: {
    value: ApiInvoiceType
    label: string
  }[] = [
    { value: "debit", label: "Debit rating" },
    { value: "credit", label: "Credit rating" },
  ]
  return typeOptions
}
