import { makePathToApi } from "@/fns/path"
import { ApiMe } from "@/services/auth/schemas"

/**
 * localizeMe
 */
export const localizeMe = ({ rememberMeToken, ...parsed }: ApiMe) => ({
  ...D.selectKeys(parsed, ["id", "email", "username", "role", "status", "createdAt", "updatedAt"]),
  avatar: makePathToApi("uploads", "users", "avatars", `${parsed.email}.png`),
})
export type Me = ReturnType<typeof localizeMe>
