import { z } from "zod"
import { AuthErrorCode, NoContent, api } from "../api"
import { ApiCustomer, apiCustomer } from "./schemas"

/**
 * payloads
 */
const createPayload = z.object({
  name: z.string(),
  reference: z.string(),
  address1: z.string(),
  address2: z.string(),
  postalCode: z.string(),
  city: z.string(),
  country: z.string(),
  vat: z.string(),
  peppolId: z.string(),
})
const updatePayload = z.object({
  name: z.string().optional(),
  reference: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  vat: z.string().optional(),
  peppolId: z.string().optional(),
})

export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { customers: ApiCustomer[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("customers")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { customers: A.map(data.customers, apiCustomer.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { customer: ApiCustomer }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("customers", { data: createPayload.parse(payload) })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { customer: apiCustomer.parse(data.customer) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { customer: ApiCustomer }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`customers/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { customer: apiCustomer.parse(data.customer) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { customer: ApiCustomer }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const { success, data } = await api.put<RSuccess, RError>(`customers/${id}`, { data: updatePayload.parse(payload) })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { customer: apiCustomer.parse(data.customer) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`customers/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: null, error: false } as const
  },
}
