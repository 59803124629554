import { Select } from "@/components/ui/select"
import { useMatchable } from "@/hooks/useMatchable"
import { Combobox, ComboboxItem, ComboboxList, ComboboxProvider } from "@ariakit/react"
import * as SelectPrimitive from "@radix-ui/react-select"
import { CheckIcon, SearchIcon } from "lucide-react"
import { FormFieldWrapper, FormFieldWrapperProps, inputIconVariants, inputVariants, useFieldContext } from "."
import { extractInputProps, extractWrapperProps } from "./field-wrapper"

/**
 * FormSelect
 */
type Props = SelectInputProps & FormFieldWrapperProps
export const FormSelectVat: React.FC<Props> = ({ ...props }) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <SelectInput {...extractInputProps(props)} />
  </FormFieldWrapper>
)

type SelectInputProps = {
  placeholder?: string
  searchPlaceholder?: string
  className?: ClassName
  disabled?: boolean
}
const SelectInput: React.FC<SelectInputProps> = ({
  className,
  placeholder,
  searchPlaceholder = "search",
  disabled,
}) => {
  const [open, setOpen] = React.useState(false)
  const { value, setFieldValue, disabled: ctxDisabled } = useFieldContext<number>()

  const options = React.useMemo(() => {
    return [
      { value: 17, label: "17% Normal" },
      { value: 16, label: "16% Normal 2023" },
      { value: 14, label: "14% Intermediate" },
      { value: 13, label: "13% Intermediate 2023" },
      { value: 8, label: "8% Reduced" },
      { value: 7, label: "7% Reduced 2023" },
      { value: 3, label: "3% Super reduced" },
    ]
  }, [])

  const [{ search, setSearch }, matchIn] = useMatchable<(typeof options)[number]>(["label", "value"])
  const matches = React.useMemo(() => {
    const matches = pipe(options, matchIn)
    const selected = A.find(options, (option) => option.value === value)
    if (G.isNullable(selected)) return matches
    if (!A.some(matches, (option) => option.value === selected.value)) return A.append(matches, selected)
    return matches
  }, [search, value, options])

  return (
    <Select
      open={open}
      onOpenChange={setOpen}
      onValueChange={(v) => setFieldValue(+v)}
      defaultValue={`${value}`}
      disabled={ctxDisabled || disabled}
    >
      <ComboboxProvider
        open={open}
        setOpen={setOpen}
        resetValueOnHide
        includesBaseElement={false}
        setValue={(value) => {
          React.startTransition(() => {
            setSearch(value)
          })
        }}
      >
        <Select.Trigger className={cxm("w-full", className)}>
          <Select.Value placeholder={placeholder} />
        </Select.Trigger>
        <Select.Content>
          <div className="sticky top-0 z-20 bg-popover">
            <div className="relative m-2">
              <span className={inputIconVariants({ size: "xs", side: "left" })}>
                <SearchIcon className="size-4" />
              </span>
              <Combobox
                autoSelect
                placeholder={searchPlaceholder}
                className={inputVariants({ size: "xs", icon: "left", className: "focus-visible:ring-0" })}
                onBlurCapture={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                }}
              />
            </div>
          </div>
          <ComboboxList className="listbox">
            {A.mapWithIndex(matches, (index, option) => (
              <SelectPrimitive.Item key={index} value={`${option.value}`} asChild>
                <ComboboxItem
                  className={cx(
                    "relative flex items-center w-full py-1.5 pl-8 pr-2 cursor-default select-none rounded-sm text-sm outline-none",
                    "data-[active-item]:bg-accent data-[active-item]:text-accent-foreground",
                    "data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                  )}
                >
                  <SelectPrimitive.ItemText>{option.label}</SelectPrimitive.ItemText>
                  <SelectPrimitive.ItemIndicator className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                    <CheckIcon className="size-4" aria-hidden />
                  </SelectPrimitive.ItemIndicator>
                </ComboboxItem>
              </SelectPrimitive.Item>
            ))}
          </ComboboxList>
        </Select.Content>
      </ComboboxProvider>
    </Select>
  )
}
