import globalConfig from "@/config/global"

/**
 * isActivePath
 */
export const isActivePath = (path: string, currentPath: string, exact: boolean) => {
  if (exact) return path === currentPath
  return S.startsWith(currentPath, path)
}

/**
 * isActiveLocalePath
 */
export const isActiveLocalePath = (path: string, currentPath: string, exact: boolean) => {
  if (exact) return path === stripLocalePath(currentPath)
  return S.startsWith(stripLocalePath(currentPath), path)
}

/**
 * stripLocalePath
 */
export const stripLocalePath = (path: string) => {
  if (!path) return `/`
  return "/" + pipe(path, S.split("/"), A.sliceToEnd(2), A.join("/"))
}

/**
 * prependProtocol
 */
export const prependProtocol = (url: string, protocol = "https://"): string => {
  const supportedProtocols = ["http://", "https://", "ftp://", "sftp://"]
  if (!supportedProtocols.some((p) => url.startsWith(p))) {
    return protocol + url
  }
  return url
}

/**
 * makePath
 */
export const makePath = (...paths: string[]) => {
  const trimmedPaths = paths.map((path) => path.replace(/^\/|\/$/g, ""))
  return trimmedPaths.join("/")
}

/**
 * makePathToApi
 */
export const makePathToApi = (...paths: string[]) => {
  return makePath(globalConfig.api, ...paths)
}

/**
 * makePathToApp
 */
export const makePathToApp = (...paths: string[]) => {
  return makePath(globalConfig.host, ...paths)
}
