import * as PopoverPrimitive from "@radix-ui/react-popover"

/**
 * PopoverRoot
 */
const PopoverRoot: React.FC<PopoverPrimitive.PopoverProps> = (props) => <PopoverPrimitive.Root {...props} />

/**
 * PopoverPortal
 */
const PopoverPortal: React.FC<PopoverPrimitive.PopoverPortalProps> = (props) => <PopoverPrimitive.Portal {...props} />

/**
 * PopoverTrigger
 */
const PopoverTrigger = React.forwardRef<HTMLButtonElement, PopoverPrimitive.PopoverTriggerProps>((props, ref) => (
  <PopoverPrimitive.Trigger ref={ref} {...props} />
))

/**
 * PopoverContent
 */
type PopoverContentProps = React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
  size?: string
}
const PopoverContent = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, PopoverContentProps>(
  ({ className, size = "max-w-sm", align = "center", sideOffset = 4, ...props }, ref) => (
    <PopoverPortal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cxm(
          "z-50 w-full rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          size,
          className
        )}
        {...props}
      />
    </PopoverPortal>
  )
)

const Popover = Object.assign(PopoverRoot, {
  Portal: PopoverPortal,
  Trigger: PopoverTrigger,
  Content: PopoverContent,
})
export { Popover }
