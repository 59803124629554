/**
 * FormGroupInfo
 */
type Props = React.ComponentProps<"p">
export const FormGroupInfo: React.FC<Props> = ({ className, children, ...props }) => {
  return G.isNotNullable(children) ? (
    <p className={cxm("text-neutral-800-600 font-light text-xs", className)} {...props}>
      {children}
    </p>
  ) : null
}
