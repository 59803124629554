import { NoError, api } from "../api"
import { PeppolSearch, SearchResult, peppolMatch } from "./schemas"

export const service = {
  search: async (country: string, q: string) => {
    type RSuccess = PeppolSearch
    type RError = NoError

    if (S.isEmpty(q)) return { data: [] as SearchResult[], error: false } as const

    const { success, data } = await api.get<RSuccess, RError>("peppol", { params: { country, q } })
    if (!success) return { data: null, error: true, code: data.code } as const
    const results = A.filterMap(data.matches, (item) => {
      try {
        const parsed = peppolMatch.parse(item)
        const vat =
          parsed.entities[0]?.identifiers && parsed.entities[0]?.identifiers[0]?.value
            ? parsed.entities[0].identifiers[0].value
            : undefined
        const name = parsed.entities[0]?.name?.[0]?.name || ""
        const peppolId = parsed.participantID.value
        return {
          name,
          peppolId,
          vat,
        } as SearchResult
      } catch (error) {
        return O.None
      }
    })
    return { data: results, error: false } as const
  },
}
