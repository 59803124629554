import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { deleteErrorLog } from "@/store/error-logs/actions"

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteErrorLog,
    dictionary: {
      title: "Delete log",
      description: "Are you sure you want to delete this log?",
      success: "Log deleted",
      error: "Failed to delete log",
      progress: "Deleting log",
    },
  })

  return (
    <Context.Provider value={{ confirmDelete }}>
      {children}
      <Confirm {...deleteProps} />
    </Context.Provider>
  )
}

/**
 * types
 */
type ContextType = {
  confirmDelete: (id: string) => void
}
