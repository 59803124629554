import { PageHeader, PageWrapper } from "@/components/layout"
import { Breadcrumb } from "@/components/layout/breadcrumbs"
import { LoadingBar } from "@/components/ui/loading-bar"

/**
 * Loader
 */
export const PageLoader: React.FC<{ breadcrumbs?: Breadcrumb[] }> = ({ breadcrumbs = [] }) => {
  return (
    <PageWrapper>
      <PageHeader breadcrumbs={[...breadcrumbs, [<DotsLoader key={"loader-dots"} className='h-1' />, ""]]} />
      <LoadingBar />
    </PageWrapper>
  )
}

/**
 * DotsLoader
 */
export const DotsLoader: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, ...props }) => (
  <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 20' className={cxm(className)} {...props}>
    <circle cx='10' cy='10' r='10' fill='#666'>
      <animate
        attributeName='cx'
        from='10'
        to='40'
        dur='0.5s'
        calcMode='spline'
        keySplines='0.42 0 0.58 1'
        keyTimes='0;1'
        repeatCount='indefinite'
      />
    </circle>
    <circle cx='10' cy='10' r='0' fill='#555'>
      <animate
        attributeName='r'
        from='0'
        to='10'
        dur='0.5s'
        calcMode='spline'
        keySplines='0.42 0 0.58 1'
        keyTimes='0;1'
        repeatCount='indefinite'
      />
    </circle>
    <circle cx='40' cy='10' r='10' fill='#777'>
      <animate
        attributeName='cx'
        from='40'
        to='70'
        dur='0.5s'
        calcMode='spline'
        keySplines='0.42 0 0.58 1'
        keyTimes='0;1'
        repeatCount='indefinite'
      />
    </circle>
    <circle cx='70' cy='10' r='10' fill='#666'>
      <animate
        attributeName='r'
        from='10'
        to='0'
        dur='0.5s'
        calcMode='spline'
        keySplines='0.42 0 0.58 1'
        keyTimes='0;1'
        repeatCount='indefinite'
      />
    </circle>
  </svg>
)

/**
 * BreadcrumbLoader
 */
export const BreadcrumbLoader: React.FC<{ name: string; isLoading?: boolean }> = ({ name, isLoading = true }) =>
  isLoading ? (
    <span className='inline-flex items-center gap-2'>
      {name}
      <DotsLoader key={"loader-dots"} className='h-1' />
    </span>
  ) : (
    name
  )
