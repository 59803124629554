import { Layers, Search } from "lucide-react"
import { Fragment } from "react"
import { linkCx } from "../ui/link"

/**
 * CollectionEmpty
 */
type EmptyProps = {
  items: unknown[]
  results: unknown[]
  clear?: () => void
  create?: () => void
}
export const Empty: React.FC<EmptyProps> = ({ items, results, clear, create }) => {
  const getText = (key: keyof typeof emptyDictionary) => D.getUnsafe(emptyDictionary, key)

  return A.isEmpty(results) || A.isEmpty(items) ? (
    <div className="flex flex-col items-center justify-center flex-grow w-full max-w-screen-2xl mx-auto px-4 py-8 bg-card text-card-foreground">
      <div className="flex flex-col items-center justify-center gap-2">
        {A.isEmpty(items) ? (
          <Layers aria-hidden className="text-muted-foreground mb-4" size={48} strokeWidth={1} />
        ) : (
          <Search aria-hidden className="text-muted-foreground mb-4" size={48} strokeWidth={1} />
        )}
        <h2 className="text-2xl font-semibold">{getText(A.isEmpty(items) ? "no-item-title" : "no-result-title")}</h2>
        <p className="text-sm text-muted-foreground">
          <ReplaceActions
            content={getText(
              A.isEmpty(items)
                ? create
                  ? "no-item-content-create"
                  : "no-item-content"
                : clear
                ? "no-result-content-clear"
                : "no-result-content"
            )}
            actions={{ clear: clear ?? F.ignore, create: create ?? F.ignore }}
          />
        </p>
      </div>
    </div>
  ) : null
}

const ReplaceActions: React.FC<{ content: string; actions: Record<string, typeof F.ignore> }> = ({
  content,
  actions,
}) => {
  const parts = React.useMemo(() => S.splitByRe(content, /\[([^\]]+)\]\(action:([^)]+)\)/g), [content])
  return (
    <>
      {A.mapWithIndex(parts, (index, part) => {
        if (index % 3 === 0) return <Fragment key={index}>{part}</Fragment>
        if (!((index - 1) % 3 === 0)) return <Fragment key={index} />
        const action = D.get(actions, A.get(parts, index + 1) ?? "")
        if (G.isNullable(action)) return <Fragment key={index}>{part}</Fragment>
        return (
          <button className={linkCx} key={index} onClick={action}>
            {part}
          </button>
        )
      })}
    </>
  )
}

/**
 * dictionary
 */
const emptyDictionary = {
  "no-item-title": "List is Empty",
  "no-item-content-create":
    "It looks like there are no items here yet, you can create your first [by clicking here](action:create).",
  "no-item-content": "It looks like there are no items here yet.",
  "no-result-title": "No Results Found",
  "no-result-content-clear":
    "We couldn't find any items matching your search, try to [reset all filters](action:clear)",
  "no-result-content": "We couldn't find any items matching your search.",
}
