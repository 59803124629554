import { Form } from "."

/**
 * FormLabel
 */
type Props = React.ComponentProps<"label">
export const FormLabel: React.FC<Props> = ({ className, children, ...props }) => {
  return G.isNotNullable(children) ? (
    <Form.Label
      className={cxm(
        "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
        className
      )}
      {...props}
    >
      {children}
    </Form.Label>
  ) : null
}
