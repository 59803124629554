import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useMatchable"
import { useSortable } from "@/hooks/useSortable"
import { useErrorLogsStore } from "."
import { useCustomersById } from "../customers/hooks"
import { ErrorLog } from "./localizers"

/**
 * hooks
 */
export const useErrorLogsById = () => useErrorLogsStore(D.getUnsafe("errorLogs"))
export const useErrorLog = (id: Option<string>) => useErrorLogsStore(flow(D.getUnsafe("errorLogs"), D.get(id ?? "")))
export const useErrorLogs = () => useErrorLogsStore(flow(D.getUnsafe("errorLogs"), D.values))

/**
 * collection
 */
export const useFilteredErrorLogs = (initialLimit = 24, initialByMore = 12) => {
  const errorLogs = useErrorLogs()
  const customersById = useCustomersById()
  const [filterable, filterBy] = useFilterable<ErrorLog>(
    "error-logs-filters",
    {
      //
    },
    {}
  )

  const [matchable, matchIn] = useMatchable<ErrorLog>([
    ({ invoice }) => D.get(customersById, invoice.customerId)?.name ?? "",
  ])

  const [sortable, sortBy] = useSortable<ErrorLog>(
    "error-logs-sort",
    {
      createdAt: ({ createdAt }) => createdAt,
    },
    "createdAt"
  )

  const filtered = React.useMemo(
    () => pipe(errorLogs, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [errorLogs, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<ErrorLog>(filtered.length, initialLimit, initialByMore)

  return { errorLogs, filtered, filterable, matchable, sortable, limitable, limit }
}
