import { Select } from "@/components/ui/select"
import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "."
import { extractInputProps, extractWrapperProps } from "./field-wrapper"

/**
 * FormSelect
 */
type Props = SelectInputProps & FormFieldWrapperProps
export const FormSelect: React.FC<Props> = ({ options, ...props }) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <SelectInput {...extractInputProps(props)} options={options} />
  </FormFieldWrapper>
)

type SelectInputProps = {
  placeholder?: string
  options: FormSelectOption[]
  className?: ClassName
  disabled?: boolean
}
const SelectInput: React.FC<SelectInputProps> = ({ className, placeholder, disabled, options }) => {
  const { value, setFieldValue, disabled: ctxDisabled } = useFieldContext<string>()
  return (
    <Select onValueChange={setFieldValue} defaultValue={value} disabled={ctxDisabled || disabled}>
      <Select.Trigger className={cxm("w-full", className)}>
        <Select.Value placeholder={placeholder} />
      </Select.Trigger>
      <Select.Content className='max-w-full'>
        {A.mapWithIndex(options, (index, { label, ...option }) => (
          <Select.Item key={index} {...option} className='line-clamp-1'>
            {label}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  )
}

/**
 * helpers
 */
export const toSelectOptions = (options: string[], _: (t: string) => string, prefix: string) =>
  A.map(options, (o) => ({ label: _(`${prefix}-${o}`), value: o }))

/**
 * types
 */
export type FormSelectOption = React.ComponentPropsWithoutRef<typeof Select.Item> & {
  label: React.ReactNode
}
