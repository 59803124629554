import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { deleteCustomer } from "@/store/customers/actions"
import { Customer } from "@/store/customers/localizers"
import { CreateDialog } from "./CreateDialog"
import { EditDialog } from "./EditDialog"

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteCustomer,
    dictionary: {
      title: "Delete customer",
      description: "Are you sure you want to delete this customer?",
      success: "Customer deleted",
      error: "Failed to delete customer",
      progress: "Deleting customer",
    },
  })
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: edit, ...editProps } = useDialog<Customer>()

  return (
    <Context.Provider value={{ create, edit, confirmDelete }}>
      {children}
      <CreateDialog {...createProps} />
      <EditDialog {...editProps} />
      <Confirm {...deleteProps} />
    </Context.Provider>
  )
}

/**
 * types
 */
type ContextType = {
  create: () => void
  edit: (customer: Customer) => void
  confirmDelete: (id: string) => void
}
