import { Menu, useMenu } from "@/components/collection"
import { Customer } from "@/store/customers/localizers"
import { SquarePen, SquarePlus, Trash } from "lucide-react"
import { usePageContext } from "./Context"

type Props = { customer: Customer }

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<Props> = ({ customer }) => {
  const ctx = usePageContext()
  const { type } = useMenu()

  return (
    <>
      <Menu.Item onClick={() => ctx.edit(customer)}>
        <SquarePen />
        Edit customer
      </Menu.Item>
      <Menu.Item onClick={() => ctx.confirmDelete(customer.id)}>
        <Trash />
        Delete customer
      </Menu.Item>
      {type === "context-menu" && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={() => ctx.create()}>
            <SquarePlus aria-hidden />
            Create a new customer
          </Menu.Item>
        </>
      )}
    </>
  )
}
