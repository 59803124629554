type Iso3166 = { id: number; alpha2: string; fr: string; en: string; de: string }
export const countries: Iso3166[] = [
  {
    id: 442,
    alpha2: "lu",
    fr: "Luxembourg",
    en: "Luxembourg",
    de: "Luxemburg",
  },
  {
    id: 276,
    alpha2: "de",
    fr: "Allemagne",
    en: "Germany",
    de: "Deutschland",
  },
  {
    id: 56,
    alpha2: "be",
    fr: "Belgique",
    en: "Belgium",
    de: "Belgien",
  },
  {
    id: 250,
    alpha2: "fr",
    fr: "France",
    en: "France",
    de: "Frankreich",
  },
  {
    id: 4,
    alpha2: "af",
    fr: "Afghanistan",
    en: "Afghanistan",
    de: "Afghanistan",
  },
  {
    id: 248,
    alpha2: "ax",
    fr: "Îles Åland",
    en: "Åland Islands",
    de: "Åland",
  },
  {
    id: 8,
    alpha2: "al",
    fr: "Albanie",
    en: "Albania",
    de: "Albanien",
  },
  {
    id: 12,
    alpha2: "dz",
    fr: "Algérie",
    en: "Algeria",
    de: "Algerien",
  },
  {
    id: 16,
    alpha2: "as",
    fr: "Samoa américaines",
    en: "American Samoa",
    de: "Amerikanisch-Samoa",
  },
  {
    id: 20,
    alpha2: "ad",
    fr: "Andorre",
    en: "Andorra",
    de: "Andorra",
  },
  {
    id: 24,
    alpha2: "ao",
    fr: "Angola",
    en: "Angola",
    de: "Angola",
  },
  {
    id: 660,
    alpha2: "ai",
    fr: "Anguilla",
    en: "Anguilla",
    de: "Anguilla",
  },
  {
    id: 10,
    alpha2: "aq",
    fr: "Antarctique",
    en: "Antarctica",
    de: "Antarktis (Sonderstatus durch Antarktisvertrag)",
  },
  {
    id: 28,
    alpha2: "ag",
    fr: "Antigua-et-Barbuda",
    en: "Antigua and Barbuda",
    de: "Antigua und Barbuda",
  },
  {
    id: 32,
    alpha2: "ar",
    fr: "Argentine",
    en: "Argentina",
    de: "Argentinien",
  },
  {
    id: 51,
    alpha2: "am",
    fr: "Arménie",
    en: "Armenia",
    de: "Armenien",
  },
  {
    id: 533,
    alpha2: "aw",
    fr: "Aruba",
    en: "Aruba",
    de: "Aruba",
  },
  {
    id: 36,
    alpha2: "au",
    fr: "Australie",
    en: "Australia",
    de: "Australien",
  },
  {
    id: 40,
    alpha2: "at",
    fr: "Autriche",
    en: "Austria",
    de: "Österreich",
  },
  {
    id: 31,
    alpha2: "az",
    fr: "Azerbaïdjan",
    en: "Azerbaijan",
    de: "Aserbaidschan",
  },
  {
    id: 44,
    alpha2: "bs",
    fr: "Bahamas",
    en: "Bahamas",
    de: "Bahamas",
  },
  {
    id: 48,
    alpha2: "bh",
    fr: "Bahreïn",
    en: "Bahrain",
    de: "Bahrain",
  },
  {
    id: 50,
    alpha2: "bd",
    fr: "Bangladesh",
    en: "Bangladesh",
    de: "Bangladesch",
  },
  {
    id: 52,
    alpha2: "bb",
    fr: "Barbade",
    en: "Barbados",
    de: "Barbados",
  },
  {
    id: 112,
    alpha2: "by",
    fr: "Biélorussie",
    en: "Belarus",
    de: "Belarus",
  },
  {
    id: 84,
    alpha2: "bz",
    fr: "Belize",
    en: "Belize",
    de: "Belize",
  },
  {
    id: 204,
    alpha2: "bj",
    fr: "Bénin",
    en: "Benin",
    de: "Benin",
  },
  {
    id: 60,
    alpha2: "bm",
    fr: "Bermudes",
    en: "Bermuda",
    de: "Bermuda",
  },
  {
    id: 64,
    alpha2: "bt",
    fr: "Bhoutan",
    en: "Bhutan",
    de: "Bhutan",
  },
  {
    id: 68,
    alpha2: "bo",
    fr: "Bolivie",
    en: "Bolivia (Plurinational State of)",
    de: "Bolivien",
  },
  {
    id: 535,
    alpha2: "bq",
    fr: "Pays-Bas caribéens",
    en: "Bonaire, Sint Eustatius and Saba",
    de: "Bonaire, Saba, Sint Eustatius",
  },
  {
    id: 70,
    alpha2: "ba",
    fr: "Bosnie-Herzégovine",
    en: "Bosnia and Herzegovina",
    de: "Bosnien und Herzegowina",
  },
  {
    id: 72,
    alpha2: "bw",
    fr: "Botswana",
    en: "Botswana",
    de: "Botswana",
  },
  {
    id: 74,
    alpha2: "bv",
    fr: "Île Bouvet",
    en: "Bouvet Island",
    de: "Bouvetinsel",
  },
  {
    id: 76,
    alpha2: "br",
    fr: "Brésil",
    en: "Brazil",
    de: "Brasilien",
  },
  {
    id: 86,
    alpha2: "io",
    fr: "Territoire britannique de l'océan Indien",
    en: "British Indian Ocean Territory",
    de: "Britisches Territorium im Indischen Ozean",
  },
  {
    id: 96,
    alpha2: "bn",
    fr: "Brunei",
    en: "Brunei Darussalam",
    de: "Brunei",
  },
  {
    id: 100,
    alpha2: "bg",
    fr: "Bulgarie",
    en: "Bulgaria",
    de: "Bulgarien",
  },
  {
    id: 854,
    alpha2: "bf",
    fr: "Burkina Faso",
    en: "Burkina Faso",
    de: "Burkina Faso",
  },
  {
    id: 108,
    alpha2: "bi",
    fr: "Burundi",
    en: "Burundi",
    de: "Burundi",
  },
  {
    id: 116,
    alpha2: "kh",
    fr: "Cambodge",
    en: "Cambodia",
    de: "Kambodscha",
  },
  {
    id: 120,
    alpha2: "cm",
    fr: "Cameroun",
    en: "Cameroon",
    de: "Kamerun",
  },
  {
    id: 124,
    alpha2: "ca",
    fr: "Canada",
    en: "Canada",
    de: "Kanada",
  },
  {
    id: 132,
    alpha2: "cv",
    fr: "Cap-Vert",
    en: "Cabo Verde",
    de: "Kap Verde",
  },
  {
    id: 136,
    alpha2: "ky",
    fr: "Îles Caïmans",
    en: "Cayman Islands",
    de: "Kaimaninseln",
  },
  {
    id: 140,
    alpha2: "cf",
    fr: "République centrafricaine",
    en: "Central African Republic",
    de: "Zentralafrikanische Republik",
  },
  {
    id: 148,
    alpha2: "td",
    fr: "Tchad",
    en: "Chad",
    de: "Tschad",
  },
  {
    id: 152,
    alpha2: "cl",
    fr: "Chili",
    en: "Chile",
    de: "Chile",
  },
  {
    id: 156,
    alpha2: "cn",
    fr: "Chine",
    en: "China",
    de: "Volksrepublik China",
  },
  {
    id: 162,
    alpha2: "cx",
    fr: "Île Christmas",
    en: "Christmas Island",
    de: "Weihnachtsinsel",
  },
  {
    id: 166,
    alpha2: "cc",
    fr: "Îles Cocos",
    en: "Cocos (Keeling) Islands",
    de: "Kokosinseln",
  },
  {
    id: 170,
    alpha2: "co",
    fr: "Colombie",
    en: "Colombia",
    de: "Kolumbien",
  },
  {
    id: 174,
    alpha2: "km",
    fr: "Comores",
    en: "Comoros",
    de: "Komoren",
  },
  {
    id: 178,
    alpha2: "cg",
    fr: "République du Congo",
    en: "Congo",
    de: "Kongo, Republik",
  },
  {
    id: 180,
    alpha2: "cd",
    fr: "République démocratique du Congo",
    en: "Congo, Democratic Republic of the",
    de: "Kongo, Demokratische Republik",
  },
  {
    id: 184,
    alpha2: "ck",
    fr: "Îles Cook",
    en: "Cook Islands",
    de: "Cookinseln",
  },
  {
    id: 188,
    alpha2: "cr",
    fr: "Costa Rica",
    en: "Costa Rica",
    de: "Costa Rica",
  },
  {
    id: 384,
    alpha2: "ci",
    fr: "Côte d'Ivoire",
    en: "Côte d'Ivoire",
    de: "Elfenbeinküste",
  },
  {
    id: 191,
    alpha2: "hr",
    fr: "Croatie",
    en: "Croatia",
    de: "Kroatien",
  },
  {
    id: 192,
    alpha2: "cu",
    fr: "Cuba",
    en: "Cuba",
    de: "Kuba",
  },
  {
    id: 531,
    alpha2: "cw",
    fr: "Curaçao",
    en: "Curaçao",
    de: "Curaçao",
  },
  {
    id: 196,
    alpha2: "cy",
    fr: "Chypre",
    en: "Cyprus",
    de: "Zypern",
  },
  {
    id: 203,
    alpha2: "cz",
    fr: "Tchéquie",
    en: "Czechia",
    de: "Tschechien",
  },
  {
    id: 208,
    alpha2: "dk",
    fr: "Danemark",
    en: "Denmark",
    de: "Dänemark",
  },
  {
    id: 262,
    alpha2: "dj",
    fr: "Djibouti",
    en: "Djibouti",
    de: "Dschibuti",
  },
  {
    id: 212,
    alpha2: "dm",
    fr: "Dominique",
    en: "Dominica",
    de: "Dominica",
  },
  {
    id: 214,
    alpha2: "do",
    fr: "République dominicaine",
    en: "Dominican Republic",
    de: "Dominikanische Republik",
  },
  {
    id: 218,
    alpha2: "ec",
    fr: "Équateur",
    en: "Ecuador",
    de: "Ecuador",
  },
  {
    id: 818,
    alpha2: "eg",
    fr: "Égypte",
    en: "Egypt",
    de: "Ägypten",
  },
  {
    id: 222,
    alpha2: "sv",
    fr: "Salvador",
    en: "El Salvador",
    de: "El Salvador",
  },
  {
    id: 226,
    alpha2: "gq",
    fr: "Guinée équatoriale",
    en: "Equatorial Guinea",
    de: "Äquatorialguinea",
  },
  {
    id: 232,
    alpha2: "er",
    fr: "Érythrée",
    en: "Eritrea",
    de: "Eritrea",
  },
  {
    id: 233,
    alpha2: "ee",
    fr: "Estonie",
    en: "Estonia",
    de: "Estland",
  },
  {
    id: 231,
    alpha2: "et",
    fr: "Éthiopie",
    en: "Ethiopia",
    de: "Äthiopien",
  },
  {
    id: 238,
    alpha2: "fk",
    fr: "Malouines",
    en: "Falkland Islands (Malvinas)",
    de: "Falklandinseln",
  },
  {
    id: 234,
    alpha2: "fo",
    fr: "Îles Féroé",
    en: "Faroe Islands",
    de: "Färöer",
  },
  {
    id: 242,
    alpha2: "fj",
    fr: "Fidji",
    en: "Fiji",
    de: "Fidschi",
  },
  {
    id: 246,
    alpha2: "fi",
    fr: "Finlande",
    en: "Finland",
    de: "Finnland",
  },

  {
    id: 254,
    alpha2: "gf",
    fr: "Guyane",
    en: "French Guiana",
    de: "Französisch-Guayana",
  },
  {
    id: 258,
    alpha2: "pf",
    fr: "Polynésie française",
    en: "French Polynesia",
    de: "Französisch-Polynesien",
  },
  {
    id: 260,
    alpha2: "tf",
    fr: "Terres australes et antarctiques françaises",
    en: "French Southern Territories",
    de: "Französische Süd- und Antarktisgebiete",
  },
  {
    id: 266,
    alpha2: "ga",
    fr: "Gabon",
    en: "Gabon",
    de: "Gabun",
  },
  {
    id: 270,
    alpha2: "gm",
    fr: "Gambie",
    en: "Gambia",
    de: "Gambia",
  },
  {
    id: 268,
    alpha2: "ge",
    fr: "Géorgie",
    en: "Georgia",
    de: "Georgien",
  },

  {
    id: 288,
    alpha2: "gh",
    fr: "Ghana",
    en: "Ghana",
    de: "Ghana",
  },
  {
    id: 292,
    alpha2: "gi",
    fr: "Gibraltar",
    en: "Gibraltar",
    de: "Gibraltar",
  },
  {
    id: 300,
    alpha2: "gr",
    fr: "Grèce",
    en: "Greece",
    de: "Griechenland",
  },
  {
    id: 304,
    alpha2: "gl",
    fr: "Groenland",
    en: "Greenland",
    de: "Grönland",
  },
  {
    id: 308,
    alpha2: "gd",
    fr: "Grenade",
    en: "Grenada",
    de: "Grenada",
  },
  {
    id: 312,
    alpha2: "gp",
    fr: "Guadeloupe",
    en: "Guadeloupe",
    de: "Guadeloupe",
  },
  {
    id: 316,
    alpha2: "gu",
    fr: "Guam",
    en: "Guam",
    de: "Guam",
  },
  {
    id: 320,
    alpha2: "gt",
    fr: "Guatemala",
    en: "Guatemala",
    de: "Guatemala",
  },
  {
    id: 831,
    alpha2: "gg",
    fr: "Guernesey",
    en: "Guernsey",
    de: "Guernsey (Kanalinsel)",
  },
  {
    id: 324,
    alpha2: "gn",
    fr: "Guinée",
    en: "Guinea",
    de: "Guinea",
  },
  {
    id: 624,
    alpha2: "gw",
    fr: "Guinée-Bissau",
    en: "Guinea-Bissau",
    de: "Guinea-Bissau",
  },
  {
    id: 328,
    alpha2: "gy",
    fr: "Guyana",
    en: "Guyana",
    de: "Guyana",
  },
  {
    id: 332,
    alpha2: "ht",
    fr: "Haïti",
    en: "Haiti",
    de: "Haiti",
  },
  {
    id: 334,
    alpha2: "hm",
    fr: "Îles Heard-et-MacDonald",
    en: "Heard Island and McDonald Islands",
    de: "Heard und McDonaldinseln",
  },
  {
    id: 336,
    alpha2: "va",
    fr: "Saint-Siège (État de la Cité du Vatican)",
    en: "Holy See",
    de: "Vatikanstadt",
  },
  {
    id: 340,
    alpha2: "hn",
    fr: "Honduras",
    en: "Honduras",
    de: "Honduras",
  },
  {
    id: 344,
    alpha2: "hk",
    fr: "Hong Kong",
    en: "Hong Kong",
    de: "Hongkong",
  },
  {
    id: 348,
    alpha2: "hu",
    fr: "Hongrie",
    en: "Hungary",
    de: "Ungarn",
  },
  {
    id: 352,
    alpha2: "is",
    fr: "Islande",
    en: "Iceland",
    de: "Island",
  },
  {
    id: 356,
    alpha2: "in",
    fr: "Inde",
    en: "India",
    de: "Indien",
  },
  {
    id: 360,
    alpha2: "id",
    fr: "Indonésie",
    en: "Indonesia",
    de: "Indonesien",
  },
  {
    id: 364,
    alpha2: "ir",
    fr: "Iran",
    en: "Iran (Islamic Republic of)",
    de: "Iran",
  },
  {
    id: 368,
    alpha2: "iq",
    fr: "Irak",
    en: "Iraq",
    de: "Irak",
  },
  {
    id: 372,
    alpha2: "ie",
    fr: "Irlande",
    en: "Ireland",
    de: "Irland",
  },
  {
    id: 833,
    alpha2: "im",
    fr: "Île de Man",
    en: "Isle of Man",
    de: "Insel Man",
  },
  {
    id: 376,
    alpha2: "il",
    fr: "Israël",
    en: "Israel",
    de: "Israel",
  },
  {
    id: 380,
    alpha2: "it",
    fr: "Italie",
    en: "Italy",
    de: "Italien",
  },
  {
    id: 388,
    alpha2: "jm",
    fr: "Jamaïque",
    en: "Jamaica",
    de: "Jamaika",
  },
  {
    id: 392,
    alpha2: "jp",
    fr: "Japon",
    en: "Japan",
    de: "Japan",
  },
  {
    id: 832,
    alpha2: "je",
    fr: "Jersey",
    en: "Jersey",
    de: "Jersey (Kanalinsel)",
  },
  {
    id: 400,
    alpha2: "jo",
    fr: "Jordanie",
    en: "Jordan",
    de: "Jordanien",
  },
  {
    id: 398,
    alpha2: "kz",
    fr: "Kazakhstan",
    en: "Kazakhstan",
    de: "Kasachstan",
  },
  {
    id: 404,
    alpha2: "ke",
    fr: "Kenya",
    en: "Kenya",
    de: "Kenia",
  },
  {
    id: 296,
    alpha2: "ki",
    fr: "Kiribati",
    en: "Kiribati",
    de: "Kiribati",
  },
  {
    id: 408,
    alpha2: "kp",
    fr: "Corée du Nord",
    en: "Korea (Democratic People's Republic of)",
    de: "Korea, Nord (Nordkorea)",
  },
  {
    id: 410,
    alpha2: "kr",
    fr: "Corée du Sud",
    en: "Korea, Republic of",
    de: "Korea, Süd (Südkorea)",
  },
  {
    id: 414,
    alpha2: "kw",
    fr: "Koweït",
    en: "Kuwait",
    de: "Kuwait",
  },
  {
    id: 417,
    alpha2: "kg",
    fr: "Kirghizistan",
    en: "Kyrgyzstan",
    de: "Kirgisistan",
  },
  {
    id: 418,
    alpha2: "la",
    fr: "Laos",
    en: "Lao People's Democratic Republic",
    de: "Laos",
  },
  {
    id: 428,
    alpha2: "lv",
    fr: "Lettonie",
    en: "Latvia",
    de: "Lettland",
  },
  {
    id: 422,
    alpha2: "lb",
    fr: "Liban",
    en: "Lebanon",
    de: "Libanon",
  },
  {
    id: 426,
    alpha2: "ls",
    fr: "Lesotho",
    en: "Lesotho",
    de: "Lesotho",
  },
  {
    id: 430,
    alpha2: "lr",
    fr: "Liberia",
    en: "Liberia",
    de: "Liberia",
  },
  {
    id: 434,
    alpha2: "ly",
    fr: "Libye",
    en: "Libya",
    de: "Libyen",
  },
  {
    id: 438,
    alpha2: "li",
    fr: "Liechtenstein",
    en: "Liechtenstein",
    de: "Liechtenstein",
  },
  {
    id: 440,
    alpha2: "lt",
    fr: "Lituanie",
    en: "Lithuania",
    de: "Litauen",
  },
  {
    id: 446,
    alpha2: "mo",
    fr: "Macao",
    en: "Macao",
    de: "Macau",
  },
  {
    id: 807,
    alpha2: "mk",
    fr: "Macédoine du Nord",
    en: "North Macedonia",
    de: "Nordmazedonien",
  },
  {
    id: 450,
    alpha2: "mg",
    fr: "Madagascar",
    en: "Madagascar",
    de: "Madagaskar",
  },
  {
    id: 454,
    alpha2: "mw",
    fr: "Malawi",
    en: "Malawi",
    de: "Malawi",
  },
  {
    id: 458,
    alpha2: "my",
    fr: "Malaisie",
    en: "Malaysia",
    de: "Malaysia",
  },
  {
    id: 462,
    alpha2: "mv",
    fr: "Maldives",
    en: "Maldives",
    de: "Malediven",
  },
  {
    id: 466,
    alpha2: "ml",
    fr: "Mali",
    en: "Mali",
    de: "Mali",
  },
  {
    id: 470,
    alpha2: "mt",
    fr: "Malte",
    en: "Malta",
    de: "Malta",
  },
  {
    id: 584,
    alpha2: "mh",
    fr: "Îles Marshall",
    en: "Marshall Islands",
    de: "Marshallinseln",
  },
  {
    id: 474,
    alpha2: "mq",
    fr: "Martinique",
    en: "Martinique",
    de: "Martinique",
  },
  {
    id: 478,
    alpha2: "mr",
    fr: "Mauritanie",
    en: "Mauritania",
    de: "Mauretanien",
  },
  {
    id: 480,
    alpha2: "mu",
    fr: "Maurice",
    en: "Mauritius",
    de: "Mauritius",
  },
  {
    id: 175,
    alpha2: "yt",
    fr: "Mayotte",
    en: "Mayotte",
    de: "Mayotte",
  },
  {
    id: 484,
    alpha2: "mx",
    fr: "Mexique",
    en: "Mexico",
    de: "Mexiko",
  },
  {
    id: 583,
    alpha2: "fm",
    fr: "États fédérés de Micronésie",
    en: "Micronesia (Federated States of)",
    de: "Mikronesien",
  },
  {
    id: 504,
    alpha2: "ma",
    fr: "Maroc",
    en: "Morocco",
    de: "Marokko",
  },
  {
    id: 498,
    alpha2: "md",
    fr: "Moldavie",
    en: "Moldova, Republic of",
    de: "Moldau",
  },
  {
    id: 492,
    alpha2: "mc",
    fr: "Monaco",
    en: "Monaco",
    de: "Monaco",
  },
  {
    id: 496,
    alpha2: "mn",
    fr: "Mongolie",
    en: "Mongolia",
    de: "Mongolei",
  },
  {
    id: 499,
    alpha2: "me",
    fr: "Monténégro",
    en: "Montenegro",
    de: "Montenegro",
  },
  {
    id: 500,
    alpha2: "ms",
    fr: "Montserrat",
    en: "Montserrat",
    de: "Montserrat",
  },
  {
    id: 508,
    alpha2: "mz",
    fr: "Mozambique",
    en: "Mozambique",
    de: "Mosambik",
  },
  {
    id: 104,
    alpha2: "mm",
    fr: "Birmanie",
    en: "Myanmar",
    de: "Myanmar",
  },
  {
    id: 516,
    alpha2: "na",
    fr: "Namibie",
    en: "Namibia",
    de: "Namibia",
  },
  {
    id: 520,
    alpha2: "nr",
    fr: "Nauru",
    en: "Nauru",
    de: "Nauru",
  },
  {
    id: 524,
    alpha2: "np",
    fr: "Népal",
    en: "Nepal",
    de: "Nepal",
  },
  {
    id: 528,
    alpha2: "nl",
    fr: "Pays-Bas",
    en: "Netherlands",
    de: "Niederlande",
  },
  {
    id: 540,
    alpha2: "nc",
    fr: "Nouvelle-Calédonie",
    en: "New Caledonia",
    de: "Neukaledonien",
  },
  {
    id: 554,
    alpha2: "nz",
    fr: "Nouvelle-Zélande",
    en: "New Zealand",
    de: "Neuseeland",
  },
  {
    id: 558,
    alpha2: "ni",
    fr: "Nicaragua",
    en: "Nicaragua",
    de: "Nicaragua",
  },
  {
    id: 562,
    alpha2: "ne",
    fr: "Niger",
    en: "Niger",
    de: "Niger",
  },
  {
    id: 566,
    alpha2: "ng",
    fr: "Nigeria",
    en: "Nigeria",
    de: "Nigeria",
  },
  {
    id: 570,
    alpha2: "nu",
    fr: "Niue",
    en: "Niue",
    de: "Niue",
  },
  {
    id: 574,
    alpha2: "nf",
    fr: "Île Norfolk",
    en: "Norfolk Island",
    de: "Norfolkinsel",
  },
  {
    id: 580,
    alpha2: "mp",
    fr: "Îles Mariannes du Nord",
    en: "Northern Mariana Islands",
    de: "Nördliche Marianen",
  },
  {
    id: 578,
    alpha2: "no",
    fr: "Norvège",
    en: "Norway",
    de: "Norwegen",
  },
  {
    id: 512,
    alpha2: "om",
    fr: "Oman",
    en: "Oman",
    de: "Oman",
  },
  {
    id: 586,
    alpha2: "pk",
    fr: "Pakistan",
    en: "Pakistan",
    de: "Pakistan",
  },
  {
    id: 585,
    alpha2: "pw",
    fr: "Palaos",
    en: "Palau",
    de: "Palau",
  },
  {
    id: 275,
    alpha2: "ps",
    fr: "Palestine",
    en: "Palestine, State of",
    de: "Palästina",
  },
  {
    id: 591,
    alpha2: "pa",
    fr: "Panama",
    en: "Panama",
    de: "Panama",
  },
  {
    id: 598,
    alpha2: "pg",
    fr: "Papouasie-Nouvelle-Guinée",
    en: "Papua New Guinea",
    de: "Papua-Neuguinea",
  },
  {
    id: 600,
    alpha2: "py",
    fr: "Paraguay",
    en: "Paraguay",
    de: "Paraguay",
  },
  {
    id: 604,
    alpha2: "pe",
    fr: "Pérou",
    en: "Peru",
    de: "Peru",
  },
  {
    id: 608,
    alpha2: "ph",
    fr: "Philippines",
    en: "Philippines",
    de: "Philippinen",
  },
  {
    id: 612,
    alpha2: "pn",
    fr: "Îles Pitcairn",
    en: "Pitcairn",
    de: "Pitcairninseln",
  },
  {
    id: 616,
    alpha2: "pl",
    fr: "Pologne",
    en: "Poland",
    de: "Polen",
  },
  {
    id: 620,
    alpha2: "pt",
    fr: "Portugal",
    en: "Portugal",
    de: "Portugal",
  },
  {
    id: 630,
    alpha2: "pr",
    fr: "Porto Rico",
    en: "Puerto Rico",
    de: "Puerto Rico",
  },
  {
    id: 634,
    alpha2: "qa",
    fr: "Qatar",
    en: "Qatar",
    de: "Katar",
  },
  {
    id: 638,
    alpha2: "re",
    fr: "La Réunion",
    en: "Réunion",
    de: "Réunion",
  },
  {
    id: 642,
    alpha2: "ro",
    fr: "Roumanie",
    en: "Romania",
    de: "Rumänien",
  },
  {
    id: 643,
    alpha2: "ru",
    fr: "Russie",
    en: "Russian Federation",
    de: "Russland",
  },
  {
    id: 646,
    alpha2: "rw",
    fr: "Rwanda",
    en: "Rwanda",
    de: "Ruanda",
  },
  {
    id: 652,
    alpha2: "bl",
    fr: "Saint-Barthélemy",
    en: "Saint Barthélemy",
    de: "Saint-Barthélemy",
  },
  {
    id: 654,
    alpha2: "sh",
    fr: "Sainte-Hélène, Ascension et Tristan da Cunha",
    en: "Saint Helena, Ascension and Tristan da Cunha",
    de: "St. Helena, Ascension und Tristan da Cunha",
  },
  {
    id: 659,
    alpha2: "kn",
    fr: "Saint-Christophe-et-Niévès",
    en: "Saint Kitts and Nevis",
    de: "St. Kitts und Nevis",
  },
  {
    id: 662,
    alpha2: "lc",
    fr: "Sainte-Lucie",
    en: "Saint Lucia",
    de: "St. Lucia",
  },
  {
    id: 663,
    alpha2: "mf",
    fr: "Saint-Martin",
    en: "Saint Martin (French part)",
    de: "Saint-Martin (französischer Teil)",
  },
  {
    id: 666,
    alpha2: "pm",
    fr: "Saint-Pierre-et-Miquelon",
    en: "Saint Pierre and Miquelon",
    de: "Saint-Pierre und Miquelon",
  },
  {
    id: 670,
    alpha2: "vc",
    fr: "Saint-Vincent-et-les-Grenadines",
    en: "Saint Vincent and the Grenadines",
    de: "St. Vincent und die Grenadinen",
  },
  {
    id: 882,
    alpha2: "ws",
    fr: "Samoa",
    en: "Samoa",
    de: "Samoa",
  },
  {
    id: 674,
    alpha2: "sm",
    fr: "Saint-Marin",
    en: "San Marino",
    de: "San Marino",
  },
  {
    id: 678,
    alpha2: "st",
    fr: "Sao Tomé-et-Principe",
    en: "Sao Tome and Principe",
    de: "São Tomé und Príncipe",
  },
  {
    id: 682,
    alpha2: "sa",
    fr: "Arabie saoudite",
    en: "Saudi Arabia",
    de: "Saudi-Arabien",
  },
  {
    id: 686,
    alpha2: "sn",
    fr: "Sénégal",
    en: "Senegal",
    de: "Senegal",
  },
  {
    id: 688,
    alpha2: "rs",
    fr: "Serbie",
    en: "Serbia",
    de: "Serbien",
  },
  {
    id: 690,
    alpha2: "sc",
    fr: "Seychelles",
    en: "Seychelles",
    de: "Seychellen",
  },
  {
    id: 694,
    alpha2: "sl",
    fr: "Sierra Leone",
    en: "Sierra Leone",
    de: "Sierra Leone",
  },
  {
    id: 702,
    alpha2: "sg",
    fr: "Singapour",
    en: "Singapore",
    de: "Singapur",
  },
  {
    id: 534,
    alpha2: "sx",
    fr: "Saint-Martin",
    en: "Sint Maarten (Dutch part)",
    de: "Sint Maarten",
  },
  {
    id: 703,
    alpha2: "sk",
    fr: "Slovaquie",
    en: "Slovakia",
    de: "Slowakei",
  },
  {
    id: 705,
    alpha2: "si",
    fr: "Slovénie",
    en: "Slovenia",
    de: "Slowenien",
  },
  {
    id: 90,
    alpha2: "sb",
    fr: "Îles Salomon",
    en: "Solomon Islands",
    de: "Salomonen",
  },
  {
    id: 706,
    alpha2: "so",
    fr: "Somalie",
    en: "Somalia",
    de: "Somalia",
  },
  {
    id: 710,
    alpha2: "za",
    fr: "Afrique du Sud",
    en: "South Africa",
    de: "Südafrika",
  },
  {
    id: 239,
    alpha2: "gs",
    fr: "Géorgie du Sud-et-les îles Sandwich du Sud",
    en: "South Georgia and the South Sandwich Islands",
    de: "Südgeorgien und die Südlichen Sandwichinseln",
  },
  {
    id: 728,
    alpha2: "ss",
    fr: "Soudan du Sud",
    en: "South Sudan",
    de: "Südsudan",
  },
  {
    id: 724,
    alpha2: "es",
    fr: "Espagne",
    en: "Spain",
    de: "Spanien",
  },
  {
    id: 144,
    alpha2: "lk",
    fr: "Sri Lanka",
    en: "Sri Lanka",
    de: "Sri Lanka",
  },
  {
    id: 729,
    alpha2: "sd",
    fr: "Soudan",
    en: "Sudan",
    de: "Sudan",
  },
  {
    id: 740,
    alpha2: "sr",
    fr: "Suriname",
    en: "Suriname",
    de: "Suriname",
  },
  {
    id: 744,
    alpha2: "sj",
    fr: "Svalbard et ile Jan Mayen",
    en: "Svalbard and Jan Mayen",
    de: "Spitzbergen und Jan Mayen",
  },
  {
    id: 748,
    alpha2: "sz",
    fr: "Eswatini",
    en: "Eswatini",
    de: "Eswatini",
  },
  {
    id: 752,
    alpha2: "se",
    fr: "Suède",
    en: "Sweden",
    de: "Schweden",
  },
  {
    id: 756,
    alpha2: "ch",
    fr: "Suisse",
    en: "Switzerland",
    de: "Schweiz",
  },
  {
    id: 760,
    alpha2: "sy",
    fr: "Syrie",
    en: "Syrian Arab Republic",
    de: "Syrien",
  },
  {
    id: 158,
    alpha2: "tw",
    fr: "Taïwan / (République de Chine (Taïwan))",
    en: "Taiwan, Province of China",
    de: "Republik China",
  },
  {
    id: 762,
    alpha2: "tj",
    fr: "Tadjikistan",
    en: "Tajikistan",
    de: "Tadschikistan",
  },
  {
    id: 834,
    alpha2: "tz",
    fr: "Tanzanie",
    en: "Tanzania, United Republic of",
    de: "Tansania",
  },
  {
    id: 764,
    alpha2: "th",
    fr: "Thaïlande",
    en: "Thailand",
    de: "Thailand",
  },
  {
    id: 626,
    alpha2: "tl",
    fr: "Timor oriental",
    en: "Timor-Leste",
    de: "Osttimor",
  },
  {
    id: 768,
    alpha2: "tg",
    fr: "Togo",
    en: "Togo",
    de: "Togo",
  },
  {
    id: 772,
    alpha2: "tk",
    fr: "Tokelau",
    en: "Tokelau",
    de: "Tokelau",
  },
  {
    id: 776,
    alpha2: "to",
    fr: "Tonga",
    en: "Tonga",
    de: "Tonga",
  },
  {
    id: 780,
    alpha2: "tt",
    fr: "Trinité-et-Tobago",
    en: "Trinidad and Tobago",
    de: "Trinidad und Tobago",
  },
  {
    id: 788,
    alpha2: "tn",
    fr: "Tunisie",
    en: "Tunisia",
    de: "Tunesien",
  },
  {
    id: 792,
    alpha2: "tr",
    fr: "Turquie",
    en: "Türkiye",
    de: "Türkei",
  },
  {
    id: 795,
    alpha2: "tm",
    fr: "Turkménistan",
    en: "Turkmenistan",
    de: "Turkmenistan",
  },
  {
    id: 796,
    alpha2: "tc",
    fr: "Îles Turques-et-Caïques",
    en: "Turks and Caicos Islands",
    de: "Turks- und Caicosinseln",
  },
  {
    id: 798,
    alpha2: "tv",
    fr: "Tuvalu",
    en: "Tuvalu",
    de: "Tuvalu",
  },
  {
    id: 800,
    alpha2: "ug",
    fr: "Ouganda",
    en: "Uganda",
    de: "Uganda",
  },
  {
    id: 804,
    alpha2: "ua",
    fr: "Ukraine",
    en: "Ukraine",
    de: "Ukraine",
  },
  {
    id: 784,
    alpha2: "ae",
    fr: "Émirats arabes unis",
    en: "United Arab Emirates",
    de: "Vereinigte Arabische Emirate",
  },
  {
    id: 826,
    alpha2: "gb",
    fr: "Royaume-Uni",
    en: "United Kingdom of Great Britain and Northern Ireland",
    de: "Vereinigtes Königreich",
  },
  {
    id: 840,
    alpha2: "us",
    fr: "États-Unis",
    en: "United States of America",
    de: "Vereinigte Staaten",
  },
  {
    id: 581,
    alpha2: "um",
    fr: "Îles mineures éloignées des États-Unis",
    en: "United States Minor Outlying Islands",
    de: "United States Minor Outlying Islands",
  },
  {
    id: 858,
    alpha2: "uy",
    fr: "Uruguay",
    en: "Uruguay",
    de: "Uruguay",
  },
  {
    id: 860,
    alpha2: "uz",
    fr: "Ouzbékistan",
    en: "Uzbekistan",
    de: "Usbekistan",
  },
  {
    id: 548,
    alpha2: "vu",
    fr: "Vanuatu",
    en: "Vanuatu",
    de: "Vanuatu",
  },
  {
    id: 862,
    alpha2: "ve",
    fr: "Venezuela",
    en: "Venezuela (Bolivarian Republic of)",
    de: "Venezuela",
  },
  {
    id: 704,
    alpha2: "vn",
    fr: "Viêt Nam",
    en: "Viet Nam",
    de: "Vietnam",
  },
  {
    id: 92,
    alpha2: "vg",
    fr: "Îles Vierges britanniques",
    en: "Virgin Islands (British)",
    de: "Britische Jungferninseln",
  },
  {
    id: 850,
    alpha2: "vi",
    fr: "Îles Vierges des États-Unis",
    en: "Virgin Islands (U.S.)",
    de: "Amerikanische Jungferninseln",
  },
  {
    id: 876,
    alpha2: "wf",
    fr: "Wallis-et-Futuna",
    en: "Wallis and Futuna",
    de: "Wallis und Futuna",
  },
  {
    id: 732,
    alpha2: "eh",
    fr: "République arabe sahraouie démocratique",
    en: "Western Sahara",
    de: "Westsahara",
  },
  {
    id: 887,
    alpha2: "ye",
    fr: "Yémen",
    en: "Yemen",
    de: "Jemen",
  },
  {
    id: 894,
    alpha2: "zm",
    fr: "Zambie",
    en: "Zambia",
    de: "Sambia",
  },
  {
    id: 716,
    alpha2: "zw",
    fr: "Zimbabwe",
    en: "Zimbabwe",
    de: "Simbabwe",
  },
]
