import { z } from "zod"

/**
 * ApiUserRole
 */
export const apiUserRole = z.union([z.literal("member"), z.literal("admin")])
export type ApiUserRole = z.infer<typeof apiUserRole>

/**
 * ApiUserStatus
 */
export const apiUserStatus = z.union([z.literal("pending"), z.literal("active")])
export type ApiUserStatus = z.infer<typeof apiUserStatus>

/**
 * ApiMe
 */
export const apiMe = z.object({
  id: z.string().uuid(),
  email: z.string(),
  username: z.string(),
  role: apiUserRole,
  status: apiUserStatus,
  rememberMeToken: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiMe = z.infer<typeof apiMe>
