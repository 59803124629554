import { HN } from "../ui/hn"

/**
 * FormHeaderRoot
 */
const FormHeaderRoot = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cxm("flex flex-col space-y-1.5 pt-4 text-center sm:text-left", className)}
    {...props}
  />
))

/**
 * FormHeaderTitle
 */
const FormHeaderTitle = React.forwardRef<
  React.ElementRef<"h1">,
  React.ComponentPropsWithoutRef<"h1"> & { level?: number | undefined }
>(({ className, level = 3, ...props }, ref) => (
  <HN
    level={level}
    ref={ref}
    className={cxm("text-base font-semibold leading-none tracking-tight", className)}
    {...props}
  />
))

/**
 * FormHeaderDescription
 */
const FormHeaderDescription = React.forwardRef<
  React.ElementRef<"p">,
  React.ComponentPropsWithoutRef<"p">
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cxm("text-sm text-muted-foreground font-light", className)} {...props} />
))

export const FormHeader = Object.assign(FormHeaderRoot, {
  Title: FormHeaderTitle,
  Description: FormHeaderDescription,
})
