/**
 * parseCommaNumber
 * parse a string number with comma as a number
 */
export const parseCommaNumber = (number: string) => {
  return Number(number.replace(".", "").replace(",", "."))
}

/**
 * parseDotNumber
 * parse a string number with dot as a number
 */
export const parseDotNumber = (number: string) => {
  return Number(number)
}
