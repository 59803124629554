import { Empty, Grid, LimitMore, Toolbar } from "@/components/collection"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout"
import { usePromise } from "@/hooks/usePromise"
import { getCustomers } from "@/store/customers/actions"
import { getErrorLogs } from "@/store/error-logs/actions"
import { useFilteredErrorLogs } from "@/store/error-logs/hooks"
import { getInvoices } from "@/store/invoices/actions"
import { ContextProvider } from "./Context"
import { Item } from "./Item"

/**
 * ErrorLogs
 */
const ErrorLogs: React.FC = () => {
  usePromise(getErrorLogs)
  usePromise(getInvoices)
  usePromise(getCustomers)
  const { errorLogs, filtered, matchable, sortable, limit, limitable } = useFilteredErrorLogs()
  return (
    <ContextProvider>
      <PageWrapper>
        <PageHeader breadcrumbs={[["Error Logs", "/dashboard/error-logs"]]} />
        <PageContent>
          <Toolbar>
            <Toolbar.Search {...matchable} />
            <Toolbar.Sort
              {...sortable}
              dictionary={{
                createdAt: "Created date",
              }}
            />
          </Toolbar>
          <Grid view='list'>
            {A.map(limit(filtered), (log) => (
              <Item key={log.id} log={log} />
            ))}
          </Grid>
          <Empty
            items={errorLogs}
            results={filtered}
            clear={() => {
              matchable.setSearch("")
            }}
          />
          <LimitMore {...limitable} />
        </PageContent>
      </PageWrapper>
    </ContextProvider>
  )
}
export default ErrorLogs
