import { Form, useFormContext } from "."
import { AdaptHeight } from "../ui/adapt-height"

/**
 * FormAssertive
 */
type Props = React.ComponentProps<"p">
export const FormAssertive: React.FC<Props> = ({ className, ...props }) => {
  const ctx = useFormContext()
  return G.isNotNullable(ctx.alert) || G.isNotNullable(props.children) ? (
    <AdaptHeight animateInitial>
      <Form.Assertive className={cxm("text-sm text-red-600 font-light", className)} {...props} />
    </AdaptHeight>
  ) : null
}
