import {
  Form,
  FormAssertive,
  FormInput,
  FormSelect,
  FormSelectPeppolId,
  FormSubmit,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { formatError } from "@/fns/format-error"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { countries } from "@/services/countries"
import { resetAllStoresAndReload } from "@/store"
import { createCustomer } from "@/store/customers/actions"
import { match } from "ts-pattern"

/**
 * CreateDialog
 */
export const CreateDialog: React.FC<UseDialogProps<void>> = ({ item, onOpenChange, open }) => {
  return (
    <Dialog {...{ open, onOpenChange }} title='Create a new customer' className='max-w-2xl'>
      {item !== false && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}

const DialogForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const countryOptions = useMemoOnce(() => countries.map(({ id, fr }) => ({ value: `${id}`, label: fr })))

  const { min, max } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      name: "",
      reference: "",
      vat: "",
      peppolId: "",
      address1: "",
      address2: "",
      postalCode: "",
      city: "",
      country: `${A.head(countries)?.id ?? ""}`,
    })),
    validate: validator({
      name: [min(1, "Customer name is required"), max(100, "Customer name cannot exceed 100 characters")],
      reference: [min(1, "Reference is required")],
      vat: [min(1, "VAT is required")],
      peppolId: [min(1, "Peppol ID is required")],
      address1: [min(1, "Address is required")],
      postalCode: [min(1, "Postal code is required")],
      city: [min(1, "City is required")],
      country: [min(1, "Country is required"), max(100, "Country cannot exceed 100 characters")],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid && !form.attemptedSubmit) return formatError("VALIDATION_FAILURE")
      const payload = { ...values, country: A.find(countries, ({ id }) => `${id}` === values.country)?.fr ?? "" }
      return match(await createCustomer(payload))
        .with({ error: false }, () => {
          toast.success("Customer created")
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", formatError)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .with("FETCH_ERROR", (code) => toast.error(formatError(code)))
        )
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <FormInput label='Customer Name' name='name' maxLength={100} placeholder='enter the customer name' />
      <FormInput label='Reference' name='reference' placeholder='enter the reference' maxLength={20} />
      <FormInput label='VAT' name='vat' placeholder='LU99999999' maxLength={20} />
      <div className='grid gap-2'>
        <FormInput label='Peppol ID' name='peppolId' placeholder='9938:lu1359935' maxLength={255} />
        <FormSelectPeppolId name='peppolId' />
      </div>
      <div>
        <FormInput label='Address' name='address1' placeholder='enter customer address' maxLength={255} />
        <FormInput name='address2' maxLength={255} />
      </div>
      <FormInput label='Postal Code' name='postalCode' placeholder='L-1234' maxLength={20} />
      <FormInput label='City' name='city' placeholder='Luxembourg' maxLength={100} />
      <FormSelect label='Country' name='country' options={countryOptions} />
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant='secondary'>Back</Button>
        </Dialog.Close>
        <FormSubmit>{form.attemptedSubmit ? "Create despite errors" : "Create"}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
