import { Menu, useMenu } from "@/components/collection"
import { invoiceIsValid } from "@/fns/invoice"
import { useCustomer } from "@/store/customers/hooks"
import { Invoice } from "@/store/invoices/localizers"
import {
  Calculator,
  CloudDownload,
  CloudUpload,
  ReceiptText,
  SquarePen,
  SquarePlus,
  Trash,
  UploadIcon,
} from "lucide-react"
import { usePageContext } from "./Context"

type Props = {
  invoice: Invoice
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<Props> = ({ invoice }) => {
  const ctx = usePageContext()
  const { type } = useMenu()
  const isNotSubmitted = invoice.status !== "submitted"
  const customer = useCustomer(invoice.customerId)
  const isValid = invoiceIsValid(invoice, customer ? [customer] : [])
  return (
    <>
      <Menu.Item onClick={() => navigate(`/dashboard/invoices/${invoice.id}`)}>
        <ReceiptText />
        Display invoice
      </Menu.Item>
      {isNotSubmitted && (
        <Menu.Item onClick={() => ctx.edit(invoice)}>
          <SquarePen />
          Edit invoice details
        </Menu.Item>
      )}
      <Menu.Item onClick={() => ctx.download(invoice)}>
        <CloudDownload />
        Download JSON document
      </Menu.Item>
      {isNotSubmitted && (
        <Menu.Item
          onClick={() => isValid && ctx.submit(invoice)}
          className={cx(!isValid && "text-red-600")}
        >
          <CloudUpload />
          Submit to Peppol
        </Menu.Item>
      )}
      <Menu.Item onClick={() => ctx.details(invoice)}>
        <Calculator />
        Display details of calculation
      </Menu.Item>
      {isNotSubmitted && (
        <Menu.Item onClick={() => ctx.confirmDelete(invoice.id)}>
          <Trash />
          Delete invoice
        </Menu.Item>
      )}
      {type === "context-menu" && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={() => ctx.upload([])}>
            <UploadIcon aria-hidden />
            Upload file from billing software
          </Menu.Item>
          <Menu.Item onClick={() => ctx.create()}>
            <SquarePlus aria-hidden />
            Create a new invoice
          </Menu.Item>
        </>
      )}
    </>
  )
}
