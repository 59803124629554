import { Fields, Menu, PageLoader, Section } from "@/components/collection"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout"
import { scrollbarThinCx } from "@/components/ui/scrollbar"
import { en } from "@/fns/date-fns"
import { usePromise } from "@/hooks/usePromise"
import { getCustomers } from "@/store/customers/actions"
import { useCustomer } from "@/store/customers/hooks"
import { getErrorLog } from "@/store/error-logs/actions"
import { useErrorLog } from "@/store/error-logs/hooks"
import { ErrorLog } from "@/store/error-logs/localizers"
import { CalendarClock, FileJson2, ReceiptText, Trash, TriangleAlert, UserIcon } from "lucide-react"
import { CopyBlock, dracula } from "react-code-blocks"
import { Redirect } from "wouter"
import { ContextProvider, usePageContext } from "../Context"
import { ErrorParser } from "./Parser"

/**
 * Page: ErrorLog
 */
type PageParams = { id: string }
type Props = { log: ErrorLog }
const ErrorLogPage: React.FC<PageParams> = ({ id }) => {
  const log = useErrorLog(id)
  usePromise(getCustomers)
  const [result, inProgress] = usePromise(() => getErrorLog(id))
  if (inProgress) return <PageLoader breadcrumbs={[["Error Logs", "/dashboard/error-logs"]]} />
  if (!result || result.error || G.isNullable(log)) return <Redirect to='/dashboard/error-logs' />

  return (
    <ContextProvider>
      <PageWrapper>
        <PageHeader
          breadcrumbs={[
            ["Error Logs", "/dashboard/error-logs"],
            [id, `/dashboard/error-logs/${id}`],
          ]}
        />
        <PageContent>
          <SectionHeader log={log} />
          <SectionError log={log} />
          <SectionDocument log={log} />
          <SectionInvoice log={log} />
        </PageContent>
      </PageWrapper>
    </ContextProvider>
  )
}
export default ErrorLogPage

/**
 * SectionHeader
 */
export const SectionHeader: React.FC<Props> = ({ log }) => {
  const { invoice } = log
  const { customerId, createdAt } = invoice
  const customer = useCustomer(customerId)
  return (
    <Section className='mt-16'>
      <Section.Header>
        <Section.HeaderTitle className='flex gap-2'>
          <ReceiptText size={16} aria-hidden /> {invoice.invoiceReference}
        </Section.HeaderTitle>
        <Section.Menu menu={<ContextMenu log={log} />} />
      </Section.Header>
      <Fields className='mt-4 p-8 bg-card rounded-md max-w-xl' divider>
        <Fields.Item name='Customer' icon={<UserIcon aria-hidden />} value={customer?.name ?? "Unknown"} />
        <Fields.Item
          name='Request date'
          icon={<CalendarClock aria-hidden />}
          value={T.format(createdAt, "PPPPp", { locale: en })}
        />
      </Fields>
    </Section>
  )
}

/**
 * SectionError
 */
const SectionError: React.FC<Props> = ({ log }) => {
  const { beinvoice } = log
  return (
    <Section className='mt-16'>
      <Section.Header>
        <Section.HeaderTitle className='flex gap-2'>
          <TriangleAlert size={16} aria-hidden />
          Be Invoice errors
        </Section.HeaderTitle>
      </Section.Header>
      <div className={cx("w-full overflow-x-auto", scrollbarThinCx)}>
        <ErrorParser errorMessage={beinvoice} onlyError />
      </div>
    </Section>
  )
}

/**
 * SectionInvoice
 */
const SectionDocument: React.FC<Props> = ({ log }) => {
  return (
    <Section className='mt-16 p-0'>
      <Section.Header className='pt-8 px-8'>
        <Section.HeaderTitle className='flex gap-2'>
          <FileJson2 size={16} aria-hidden />
          Document send to BeIvoice
        </Section.HeaderTitle>
      </Section.Header>
      <div className='grid'>
        <div className={cx("grid text-xs overflow-x-scroll bg-[#353535]", scrollbarThinCx)}>
          <CopyBlock text={formatJsonString(log.parsedJson)} language={"json"} theme={dracula} />
        </div>
      </div>
    </Section>
  )
}

/**
 * SectionInvoice
 */
const SectionInvoice: React.FC<Props> = ({ log }) => {
  return (
    <Section className='mt-16 p-0'>
      <Section.Header className='pt-8 px-8'>
        <Section.HeaderTitle className='flex gap-2'>
          <FileJson2 size={16} aria-hidden />
          Invoice used to parse document
        </Section.HeaderTitle>
      </Section.Header>
      <div className='grid'>
        <div className={cx("grid text-xs overflow-x-scroll bg-[#353535]", scrollbarThinCx)}>
          <CopyBlock text={formatJsonString(log.invoiceUnparsed)} language={"json"} theme={dracula} />
        </div>
      </div>
    </Section>
  )
}

/**
 * ContextMenu
 */
const ContextMenu: React.FC<Props> = ({ log }) => {
  const ctx = usePageContext()
  return (
    <>
      <Menu.Item onClick={() => ctx.confirmDelete(log.id)}>
        <Trash />
        Delete log
      </Menu.Item>
    </>
  )
}

/**
 * helpers
 */
const formatJsonString = (jsonString: string): string => {
  try {
    const jsonObject = JSON.parse(jsonString)
    return JSON.stringify(jsonObject, null, 2) // Indentation de 2 espaces
  } catch (error) {
    console.error("Invalid JSON string:", error)
    return jsonString // Retourne la chaîne originale en cas d'erreur
  }
}
