import {
  FormFieldWrapper,
  FormFieldWrapperProps,
  inputIconVariants,
  inputVariants,
  useFieldContext,
} from "@/components/form"
import { Select } from "@/components/ui/select"
import { useMatchable } from "@/hooks/useMatchable"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { countries } from "@/services/countries"
import { service } from "@/services/search-peppol/service"
import * as Ariakit from "@ariakit/react"
import { SearchIcon } from "lucide-react"
import { scrollbarThinCx } from "../ui/scrollbar"
import { extractInputProps, extractWrapperProps } from "./field-wrapper"

/**
 * FormSelectArticles
 */
type Props = SelectPeppolIdProps & FormFieldWrapperProps
export const FormSelectPeppolId: React.FC<Props> = ({ ...props }) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <FormSelectPeppolIdInput {...extractInputProps(props)} />
  </FormFieldWrapper>
)

type SelectPeppolIdProps = {
  placeholder?: string
  className?: ClassName
  disabled?: boolean
  max?: number
}
const FormSelectPeppolIdInput: React.FC<SelectPeppolIdProps> = ({ className, placeholder }) => {
  const { setFieldValue } = useFieldContext<string>()
  const [{ search, setSearch }, matchIn] = useMatchable<Option>([D.getUnsafe("label")])

  const [country, setCountry] = React.useState<string>(() => S.toUpperCase(A.head(countries)?.alpha2 ?? ""))
  const countryOptions = useMemoOnce(() =>
    countries.map(({ fr, alpha2 }) => ({ value: S.toUpperCase(alpha2), label: fr }))
  )

  const [options, setOptions] = React.useState<Option[]>([])
  const filtered = React.useMemo(() => pipe(options, matchIn), [options, matchIn, search])

  React.useEffect(() => {
    searchPeppol(country, search, setOptions)
  }, [search, country])

  return (
    <div className='grid grid-cols-3 gap-2'>
      <div className='grid relative col-span-2'>
        <Ariakit.ComboboxProvider value={search} setValue={setSearch}>
          <Ariakit.Combobox
            placeholder={"Search on Peppol Api"}
            className={inputVariants({ icon: "left", size: "default", className })}
          />
          <Ariakit.ComboboxLabel className={inputIconVariants()}>
            <SearchIcon />
          </Ariakit.ComboboxLabel>
          <Ariakit.ComboboxPopover
            className={cxm(
              "p-1 rounded-md border border-input max-w-lg shadow-md max-h-64 overflow-y-auto bg-popover text-popover-foreground",
              "slide-in-from-top-2",
              scrollbarThinCx,
              A.isEmpty(filtered) && "hidden"
            )}
          >
            <Ariakit.ComboboxList className='flex flex-col'>
              {filtered.map((option) => (
                <Ariakit.ComboboxItem
                  key={option.value}
                  value={option.label}
                  onClick={() => setFieldValue(option.value)}
                  className={cxm(
                    "w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
                    "data-[active-item]:bg-accent data-[active-item]:text-accent-foreground"
                  )}
                >
                  <span className='inline-block text-sm leading-tight'>{option.label}</span>
                </Ariakit.ComboboxItem>
              ))}
            </Ariakit.ComboboxList>
          </Ariakit.ComboboxPopover>
        </Ariakit.ComboboxProvider>
      </div>
      <Select onValueChange={setCountry} defaultValue={country}>
        <Select.Trigger className='w-full'>
          <Select.Value placeholder={placeholder} />
        </Select.Trigger>
        <Select.Content>
          {A.mapWithIndex(countryOptions, (index, { label, ...option }) => (
            <Select.Item key={index} {...option}>
              {label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </div>
  )
}

type Option = {
  value: string
  label: string
}

let raceId = 0
const searchPeppol = async (country: string, q: string, setOptions: React.Dispatch<React.SetStateAction<Option[]>>) => {
  const id = ++raceId
  const { data, error } = await service.search(country, q)
  if (id < raceId) return
  if (error) return
  const options = A.map(data, ({ peppolId, vat, name }) => ({
    value: peppolId,
    label: `${name}${G.isNotNullable(vat) ? ` (${vat})` : ``}`,
  }))
  setOptions(options)
}
