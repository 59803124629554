import { useLocation } from "wouter"

/**
 * useActiveLocation
 */
export const useActiveLocation = () => {
  const [location] = useLocation()
  const isActiveLocation = (path: string) => {
    return location === path
  }
  const isActivePath = (path: string, expect: string[] = []) => {
    if (A.isNotEmpty(expect) && expect.includes(location)) return false
    return location.startsWith(path)
  }
  return { isActiveLocation, isActivePath }
}
