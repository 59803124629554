/**
 * Toolbar
 */
type Props = {
  size?: "default" | "sm" | "xs"
  children: React.ReactNode
} & React.ComponentPropsWithoutRef<"div">
export const ToolbarRoot: React.FC<Props> = ({ size = "default", className, ...props }) => (
  <ToolbarContext.Provider value={{ size }}>
    <div
      {...props}
      className={cxm(
        "@container/toolbar flex w-full max-w-screen-2xl mx-auto flex-wrap",
        size === "default" && "gap-4 pb-4",
        size === "sm" && "gap-2 pb-2",
        size === "xs" && "gap-2 pb-2",
        className
      )}
    />
  </ToolbarContext.Provider>
)

const ToolbarContext = React.createContext<{ size: Props["size"] }>({ size: "default" })
export const useToolbarContext = () => React.useContext(ToolbarContext)
