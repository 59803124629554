import { Avatar } from "@/components/ui/avatar"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import { signOut } from "@/store/auth/actions"
import { useMe } from "@/store/auth/hooks"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { LogOut, User } from "lucide-react"

/**
 * UserDropdown
 */
type Props = {
  expanded?: boolean
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
}
export const UserDropdown: React.FC<Props> = ({ expanded, align = "end", side = "right" }) => {
  const me = useMe()
  const fullName = S.trim(`${me.username ?? ""}`)

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <button className='group/button flex items-center gap-4 text-sm outline-none'>
          <span className='inline-flex items-center justify-center rounded-full bg-card [&_svg]:w-4 [&_svg]:h-4 ring-offset-background transition-colors group-focus-visible/button:outline-none group-focus-visible/button:ring-2 group-focus-visible/button:ring-ring group-focus-visible/button:ring-offset-2'>
            <Avatar>
              <Avatar.Image src={me.avatar} alt={me?.username} className='object-cover' />
              <Avatar.Fallback>
                <User aria-hidden />
              </Avatar.Fallback>
            </Avatar>
            <span className='sr-only'>Open user dropdown</span>
          </span>
          {false && expanded && S.isNotEmpty(fullName) && (
            <span className='text-sm font-light whitespace-nowrap'>{fullName}</span>
          )}
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align={align} side={side} className='w-56'>
        <DropdownMenu.Item onClick={signOut}>
          <LogOut aria-hidden />
          sign-out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
