import { matchSorter } from "match-sorter"

/**
 * useMatchable
 */
export const useMatchable = <T extends Record<string, unknown>>(keys: (string | ((item: T) => string))[]) => {
  const [search, setSearch] = React.useState("")

  const searchIn = (items: T[]) => matchSorter(items, normString(search), { keys })

  return [{ search, setSearch }, searchIn] as const
}

/**
 * helpers
 */
const normString = (term: string) =>
  term
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[.,/"#!$%^&*;:{}=\-_`~()]/g, "")
