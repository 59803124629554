/**
 * byId
 */
export const byId = <L extends any[], R = void>(
  items: L,
  localize?: (item: L[number]) => R
): { [key: string]: R extends void ? L[number] : R } =>
  items.reduce((items, item) => {
    items[item.id] = localize ? localize(item) : item
    return items
  }, {})
