import { Grid, Toolbar } from "@/components/collection"
import { useFilteredCustomers } from "@/store/customers/hooks"
import { usePageContext } from "./Context"
import { Filters } from "./Filters"
import { Item } from "./Item"

/**
 * Collection
 */
const Collection: React.FC = () => {
  const ctx = usePageContext()
  const { filtered, filterable, matchable, sortable, limit } = useFilteredCustomers()
  return (
    <>
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={() => ctx.create()}>Create a new customer</Toolbar.Button>
        <Filters {...filterable} />
        <Toolbar.Sort
          {...sortable}
          dictionary={{
            name: "Sort by names",
            reference: "Sort by references",
            city: "Sort by cities",
          }}
        />
      </Toolbar>
      <Grid view="list">
        {A.map(limit(filtered), customer => (
          <Item key={customer.id} customer={customer} />
        ))}
      </Grid>
    </>
  )
}
export default Collection
