import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Card } from "@/components/ui/card"
import { formatError } from "@/fns/format-error"
import { signIn } from "@/store/auth/actions"
import { match } from "ts-pattern"

/**
 * Home
 */
const Home: React.FC = () => {
  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    values: {
      email: "",
      password: "",
    },
    validate: validator({
      email: [min(1, "Email is required"), isEmail("Please enter a valid email address")],
      password: [min(1, "Password is required")],
    }),
    onSubmit: async ({ values }) => {
      return match(await signIn(values))
        .with({ error: false }, () => navigate("/dashboard"))
        .otherwise(({ code }) => formatError(code))
    },
  })

  return (
    <div className='flex justify-center items-center w-full min-h-screen p-8 bg-secondary'>
      <Card className='w-full max-w-xs'>
        <Card.Header>
          <Card.Title>Login</Card.Title>
          <Card.Description>Please enter your email and password to login</Card.Description>
        </Card.Header>
        <Card.Content>
          <Form form={form} className='grid gap-4'>
            <FormAssertive />
            <FormInput label='Email' name='email' type='email' placeholder='your-address@email.com' />
            <FormInput label='Password' name='password' type='password' placeholder={"enter your password"} />
            <FormSubmit variant='default'>Next</FormSubmit>
          </Form>
        </Card.Content>
      </Card>
    </div>
  )
}
export default Home
