/**
 * formatError
 */
export const formatError = (error: ErrorCode) => {
  return errorCodes[error] || error
}

/**
 * errorCodes
 */
const errorCodes = {
  INVALID_CREDENTIALS: "The email or password you entered is incorrect.",
  ACCOUNT_NOT_ACTIVE: "Your account is not active. Please contact your administrator.",
  VALIDATION_FAILURE: "Some of your input is invalid. Please check your entries and try again.",
  FETCH_ERROR: "We're having trouble connecting to the server. Please check your internet connection and try again.",
  UNAUTHORIZED_ACCESS:
    "You do not have the necessary permissions to perform this action. Please contact your administrator if you believe this is an error.",
  RESOURCE_NOT_ALLOWED:
    "You do not have the necessary permissions to perform this action. Please contact your administrator if you believe this is an error.",
  RESOURCE_NOT_FOUND: "The ressource you are trying to edit does not exist anymore.",
  LIMIT_EXCEEDED: "You have exceeded the number of allowed attempts. Please try again later.",
}
/**
 * types
 */
type ErrorCode = keyof typeof errorCodes
