import { Form, FormAssertive, FormSubmit, formatDateToFormInput, useForm } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { formatError } from "@/fns/format-error"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { createInvoice } from "@/store/invoices/actions"
import { match } from "ts-pattern"
import {
  FormValues,
  InvoiceForm,
  InvoiceLinesForm,
  formValuesToPayload,
  invoiceValidator,
} from "./InvoiceForm"

/**
 * CreateDialog
 */
export const CreateDialog: React.FC<UseDialogProps<void>> = ({ item, onOpenChange, open }) => {
  return (
    <Dialog {...{ open, onOpenChange }} title="Create a new invoice" className="max-w-3xl">
      {item !== false && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}

const DialogForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const form = useForm<FormValues>({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      invoiceReference: "",
      type: "debit",
      date: formatDateToFormInput(new Date()),
      senderProjectReference: "",
      dueDate: formatDateToFormInput(new Date()),
      dueDateText: "Paiement à 30 jours",
      customerId: "",
      customerProjectReference: "",
      note: "",
      attachment: [],
      lines: [],
      linesById: {},
    })),
    validate: invoiceValidator,
    onSubmit: async ({ values }) => {
      if (!form.isValid && !form.attemptedSubmit) return formatError("VALIDATION_FAILURE")
      return match(await createInvoice(formValuesToPayload(values)))
        .with({ error: false }, () => {
          toast.success("Invoice created")
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", formatError)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .with("FETCH_ERROR", code => toast.error(formatError(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <InvoiceForm />
      <InvoiceLinesForm />
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant="secondary">Back</Button>
        </Dialog.Close>
        <FormSubmit>
          {!form.isSubmitting && form.attemptedSubmit ? "Create despite errors" : "Create"}
        </FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
