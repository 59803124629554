import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import { SrOnly } from "@/components/ui/sr-only"
import { ArrowDownNarrowWide, ArrowDownWideNarrow } from "lucide-react"

/**
 * ToolbarSort
 */
type Props = {
  size?: "xs" | "sm" | "default"
  dictionary: Record<string, string>
  sort: {
    field: string
    direction: "ASC" | "DESC"
  }
  toggle: (field: string) => void
  isDESC: (field: string) => boolean
  isActive: (field: string) => boolean
  list: string[]
}
export const ToolbarSort: React.FC<Props> = ({
  dictionary,
  size = "default",
  sort,
  toggle,
  isDESC,
  isActive,
  list,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button variant='secondary' size={size} icon aria-label={"Sort"}>
          {sort.direction === "ASC" ? <ArrowDownNarrowWide aria-hidden /> : <ArrowDownWideNarrow aria-hidden />}
          <SrOnly>Sort</SrOnly>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align='end' side='bottom' className='w-56'>
        {A.map(list, (item) => (
          <DropdownMenu.Item onClick={() => toggle(item)} active={isActive(item)} key={item}>
            {isActive(item) ? (
              isDESC(item) ? (
                <ArrowDownNarrowWide aria-hidden />
              ) : (
                <ArrowDownWideNarrow aria-hidden />
              )
            ) : (
              <ArrowDownNarrowWide aria-hidden />
            )}
            {D.get(dictionary, item)}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
