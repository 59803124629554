import { useSpotlight } from "@/components/ui/hooks/useSpotlight"
import { Image, ImageProps, ImageRef } from "@/components/ui/image"
import { Spotlight } from "@/components/ui/spotlight"

/**
 * CardRoot
 */
const CardRoot = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cxm("rounded-lg border bg-card text-card-foreground shadow-md", className)} {...props} />
  )
)

/**
 * CardSpotlight
 */
const CardSpotlight: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...props }) => {
  const { cardProps, cardSpotlightProps } = useSpotlight()
  return (
    <div
      className={cxm("relative rounded-lg border bg-card text-card-foreground shadow-sm overflow-hidden", className)}
      {...cardProps}
      {...props}
    >
      {children}
      <Spotlight {...cardSpotlightProps} />
    </div>
  )
}

/**
 * CardImage
 */
const CardImage = React.forwardRef<ImageRef, ImageProps>(({ className, ...props }, ref) => (
  <Image
    className={cxm(
      "w-full aspect-video object-cover rounded-t-md text-muted-foreground/10 pointer-events-none",
      className
    )}
    ref={ref}
    {...props}
  />
))

/**
 * CardHeader
 */
const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cxm("relative flex flex-col space-y-1.5 p-6", className)} {...props} />
  )
)

/**
 * CardTitle
 */
const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h3 ref={ref} className={cxm("text-2xl font-semibold leading-none tracking-tight", className)} {...props} />
  )
)

/**
 * CardDescription
 */
const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <p ref={ref} className={cxm("text-sm text-muted-foreground", className)} {...props} />
  )
)

/**
 * CardContent
 */
const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cxm("p-6 pt-0", className)} {...props} />
)

/**
 * CardFooter
 */
const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cxm("flex items-center p-6 pt-0", className)} {...props} />
  )
)

/**
 * exports
 */
export const Card = Object.assign(CardRoot, {
  Spotlight: CardSpotlight,
  Image: CardImage,
  Header: CardHeader,
  Title: CardTitle,
  Description: CardDescription,
  Content: CardContent,
  Footer: CardFooter,
})
