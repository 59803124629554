import { Card, Fields, Menu } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { textPlaceholder } from "@/fns/string"
import { customerIsValid } from "@/store/customers/helpers"
import { Customer } from "@/store/customers/localizers"
import { SquarePen, TriangleAlert } from "lucide-react"
import { usePageContext } from "../../customers/Context"

/**
 * CustomerCard
 */
type Props = { customer: Customer; className?: ClassName }
export const CustomerCard: React.FC<Props> = ({ customer, className }) => {
  const ctx = usePageContext()
  return (
    <Card className={className}>
      {customer && (
        <Card.Header>
          <Card.Title className='max-w-lg'>
            {textPlaceholder(customer?.name, "Unnamed customer")}
            {!customerIsValid(customer) && (
              <Badge variant='ghost' icon aria-label='Invalid customer' className='text-amber-600'>
                <TriangleAlert />
              </Badge>
            )}
          </Card.Title>
          <Card.Menu
            menu={
              <Menu.Item onClick={() => ctx.edit(customer)}>
                <SquarePen />
                Edit customer
              </Menu.Item>
            }
          />
        </Card.Header>
      )}
      <Card.Content>
        <Fields divider>
          <Fields.Item
            name='Address'
            value={
              <>
                {customer.address1}
                <br />
                {customer.address2 && (
                  <>
                    {customer.address2}
                    <br />
                  </>
                )}
                {customer.postalCode} {customer.city} {customer.country}
              </>
            }
          />
          <Fields.Item name='VAT' value={customer.vat} className='grid' />
          <Fields.Item name='Peppol ID' value={customer.peppolId} className='grid' />
        </Fields>
      </Card.Content>
    </Card>
  )
}
