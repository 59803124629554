import { useEventListener } from "@/hooks/useEventListener"

/**
 * HiddenEasterEgg
 */
type Props = {
  code: (string | number)[]
  children: React.ReactNode
  reset?: number
}
export const HiddenEasterEgg: React.FC<Props> = ({ code, children = undefined, reset = 0 }) => {
  const [index, setIndex] = React.useState(0)
  const [hide, setHide] = React.useState(true)
  const indexRef = React.useRef(index)
  indexRef.current = index

  let clickCount = 0
  let clicksCode = ""

  useEventListener("keydown", e => {
    if (A.includes(["Shift", "Meta", "Alt"], e.key)) return
    if (!hide && e.key === "Escape") return setHide(true)
    if (e.key === code[indexRef.current]) {
      if (indexRef.current === code.length - 1) {
        setHide(false)
        setIndex(0)
        if (reset)
          setTimeout(() => {
            setHide(true)
            setIndex(0)
          }, reset)
      } else setIndex(prevIndex => prevIndex + 1)
    } else setIndex(0)
  })

  useEventListener("click", () => {
    clickCount++
    if (clickCount === 1) {
      setTimeout(() => {
        clicksCode = clickCount === 1 ? `${clicksCode}1` : `${clicksCode}2`
        if (clicksCode === code.join("")) {
          setHide(false)
          if (reset) setTimeout(() => setHide(true), reset)
        } else {
          if (!code.join("").startsWith(clicksCode)) clicksCode = ""
        }
        clickCount = 0
      }, 300)
    }
  })
  return <>{!hide && children}</>
}
