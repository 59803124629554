import { Empty, Grid, LimitMore, Toolbar } from "@/components/collection"
import { useFilteredInvoices } from "@/store/invoices/hooks"
import { usePageContext } from "./Context"
import { Filters } from "./Filters"
import { Item } from "./Item"

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { invoices, filtered, matchable, filterable, sortable, limit, limitable } =
    useFilteredInvoices()
  const { create, upload } = usePageContext()
  return (
    <>
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={() => upload([])}>
          Upload file from billing software
        </Toolbar.Button>
        <Toolbar.Button onClick={() => create()}>Create new invoice</Toolbar.Button>
        <Filters {...filterable} />
        <Toolbar.Sort
          {...sortable}
          dictionary={{
            name: "Sort by names",
            reference: "Sort by references",
            city: "Sort by cities",
            date: "Sort by created date",
          }}
        />
      </Toolbar>
      <Grid view="list">
        {A.map(limit(filtered), invoice => (
          <Item key={invoice.id} invoice={invoice} />
        ))}
      </Grid>
      <Empty
        items={invoices}
        results={filtered}
        clear={() => {
          filterable.reset()
          matchable.setSearch("")
        }}
        create={create}
      />
      <LimitMore {...limitable} />
    </>
  )
}
