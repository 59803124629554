/**
 * ListLeftRight
 */
export const ListLeftRight = React.forwardRef<
  HTMLUListElement,
  React.ComponentPropsWithoutRef<"ul"> & { divider?: boolean }
>(({ className, divider = false, ...props }, ref) => (
  <ul
    className={cxm("flex flex-col space-y-3 text-sm [&>*>:last-child]:justify-end", divider && "divide-y", className)}
    ref={ref}
    {...props}
  />
))

/**
 * ListItem
 */
export const ListItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<"li"> & { label: React.ReactNode; value?: React.ReactNode }
>(({ label, value, children, className, ...props }, ref) => (
  <li className={cxm("grid grid-cols-2 pt-3", className)} ref={ref} {...props}>
    <span className='flex items-center font-medium'>{label}</span>
    <span className='flex items-center'>{value ?? children}</span>
  </li>
))
