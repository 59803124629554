import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import { SrOnly } from "./sr-only"

/**
 * DialogRoot
 */
const DialogRoot: React.FC<DialogPrimitive.DialogProps> = (props) => <DialogPrimitive.Root {...props} />

/**
 * DialogTrigger
 */
const DialogTrigger = React.forwardRef<HTMLButtonElement, DialogPrimitive.DialogTriggerProps>((props, ref) => (
  <DialogPrimitive.Trigger ref={ref} {...props} />
))

/**
 * DialogPortal
 */
const DialogPortal: React.FC<DialogPrimitive.DialogPortalProps> = (props) => <DialogPrimitive.Portal {...props} />

/**
 * DialogClose
 */
const DialogClose = React.forwardRef<HTMLButtonElement, DialogPrimitive.DialogCloseProps>((props, ref) => (
  <DialogPrimitive.Close ref={ref} {...props} />
))

/**
 * DialogOverlay
 */
const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cxm(
      "fixed inset-0 z-50 p-2 md:p-8",
      "bg-background/80 backdrop-blur-sm",
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      "overflow-y-auto max-h-screen grid place-items-center",
      className
    )}
    {...props}
  />
))

/**
 * DialogContent
 */
type DialogContentProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
  hideClose?: true
  animate?: boolean
  size?: string
}
const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
  ({ className, hideClose = false, size = "max-w-lg", animate = true, children, ...props }, ref) => {
    return (
      <DialogPortal>
        <DialogOverlay>
          <DialogPrimitive.Content
            ref={ref}
            className={cxm(
              "@container/dialog relative z-50 grid w-full p-4 lg:p-6 pt-6 gap-4 sm:rounded-lg",
              "border bg-background shadow-lg",
              animate &&
                cxm(
                  "duration-200",
                  "data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95",
                  "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
                ),
              size,
              className
            )}
            {...props}
          >
            {children}
            {!hideClose && (
              <DialogPrimitive.Close className='absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'>
                <X size={16} aria-hidden />
                <SrOnly>Close</SrOnly>
              </DialogPrimitive.Close>
            )}
          </DialogPrimitive.Content>
        </DialogOverlay>
      </DialogPortal>
    )
  }
)

/**
 * DialogHeader
 */
const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cxm("flex flex-col space-y-1.5 text-center sm:text-left", className)} {...props} />
)

/**
 * DialogTitle
 */
const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cxm("text-lg font-semibold leading-none tracking-tight", className)}
    {...props}
  />
))

/**
 * DialogDescription
 */
const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description ref={ref} className={cxm("text-sm text-muted-foreground", className)} {...props} />
))

/**
 * DialogFooter
 */
const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cxm(
      "flex flex-col-reverse @sm/dialog:flex-row @sm/dialog:justify-end @sm/dialog:space-x-2 gap-2 @sm/gap-0",
      className
    )}
    {...props}
  />
)

/**
 * DialogShortcut
 */
interface DialogShortcutProps extends React.ComponentProps<typeof DialogRoot> {
  open?: boolean
  animate?: boolean
  onCloseAutoFocus?: () => void
  className?: ClassName
  title?: React.ReactNode
  description?: React.ReactNode
}
const DialogShortcut = React.forwardRef<React.ElementRef<typeof DialogContent>, DialogShortcutProps>(
  ({ open = true, onCloseAutoFocus, className, title, description, animate, children, ...props }, ref) => {
    const displayHeader = G.isNotNullable(title) || G.isNotNullable(description)
    return (
      <DialogRoot open={open} {...props} modal>
        <DialogContent className={className} ref={ref} onCloseAutoFocus={onCloseAutoFocus} animate={animate}>
          {displayHeader && (
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
              <DialogDescription>{description}</DialogDescription>
            </DialogHeader>
          )}
          <div>{children}</div>
        </DialogContent>
      </DialogRoot>
    )
  }
)

/**
 * exports
 */
const Dialog = Object.assign(DialogShortcut, {
  Root: DialogRoot,
  Portal: DialogPortal,
  Overlay: DialogOverlay,
  Close: DialogClose,
  Trigger: DialogTrigger,
  Content: DialogContent,
  Header: DialogHeader,
  Footer: DialogFooter,
  Title: DialogTitle,
  Description: DialogDescription,
})
export { Dialog }
