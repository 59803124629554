import { Fields } from "@/components/collection"
import {
  Form,
  FormAssertive,
  FormFiles,
  FormHeader,
  FormSubmit,
  formatDateToFormInput,
  useForm,
  validator,
} from "@/components/form"
import { FormFileType, isFile } from "@/components/form/files"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { byId } from "@/fns/collection"
import { formatError } from "@/fns/format-error"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { ApiInvoiceParsedFile, parsedFileToInvoice } from "@/services/invoices/parse"
import { service } from "@/services/invoices/service"
import { resetAllStoresAndReload } from "@/store"
import { useCustomers } from "@/store/customers/hooks"
import { createInvoice } from "@/store/invoices/actions"
import { InvoiceLine } from "@/store/invoices/localizers"
import { match } from "ts-pattern"
import {
  FormValues,
  InvoiceForm,
  InvoiceLinesForm,
  basicFields,
  formValuesToPayload,
  invoiceValidator,
} from "./InvoiceForm"
import { PaymentDetails } from "./PaymentDetails"

/**
 * UploadDialog
 */
export const UploadDialog: React.FC<UseDialogProps<FormFileType[]>> = ({
  item,
  onOpenChange,
  open,
}) => {
  return (
    <Dialog
      {...{ open, onOpenChange }}
      title="Upload an exported invoice"
      description="Upload your billing software text file, and we'll parse it to create an invoice quickly and accurately."
      className="max-w-2xl"
    >
      {item !== false && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}

/**
 * Step1
 * upload and parse the file
 */
const Step1: React.FC<UseDialogFormProps<FormFileType[]>> = ({ item }) => {
  const { setParsedFile, nextStep } = React.useContext(StepsContext)
  const { min } = validator
  const form = useForm({
    values: useMemoOnce(() => ({
      files: item,
    })),
    validate: validator({
      files: [min(1, "Please upload at least one file")],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid && !form.attemptedSubmit) return formatError("VALIDATION_FAILURE")
      const files = values.files.filter(isFile)
      return match(await service.parse({ files }))
        .with({ error: false }, ({ data }) => {
          const file = A.head(data.files)
          if (G.isNullable(file)) {
            form.setValues({ files: [] })
            toast.error("Parse error try to upload another file")
            return
          }
          toast.success("File parsed successfully")
          setParsedFile(file)
          nextStep()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", formatError)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .with("FETCH_ERROR", code => toast.error(formatError(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <h3 className="text-lg font-semibold leading-none tracking-tight">
        Step 1: Upload your text file from the billing software.
      </h3>
      <FormAssertive />
      <FormFiles label="Select a text file" name="files" multiple={false} accept={["txt"]} />
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant="secondary">Cancel</Button>
        </Dialog.Close>
        <FormSubmit>{"Next"}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * Step2
 * create the invoice
 */
const Step2: React.FC = () => {
  const { parsedFile, previousStep, onOpenChange } = React.useContext(StepsContext)
  const customers = useCustomers()
  const invoice = parsedFileToInvoice(parsedFile, customers)
  const form = useForm<FormValues>({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(invoice, basicFields),
      date: formatDateToFormInput(invoice.date),
      dueDate: formatDateToFormInput(invoice.dueDate),
      attachment: [],
      lines: A.map(invoice.invoiceLines, D.getUnsafe("id")),
      linesById: byId(invoice.invoiceLines),
    })),
    validate: invoiceValidator,
    onSubmit: async ({ values }) => {
      if (!form.isValid && !form.attemptedSubmit) return formatError("VALIDATION_FAILURE")
      return match(await createInvoice(formValuesToPayload(values)))
        .with({ error: false }, ({ id }) => {
          toast.success("Invoice updated")
          navigate(`/dashboard/invoices/${id}`)
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", formatError)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .with("FETCH_ERROR", code => toast.error(formatError(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <h3 className="text-lg font-semibold leading-none tracking-tight">
        Step 2: Review and validate the invoice details.
      </h3>
      <FormAssertive />
      <InvoiceForm />
      <InvoiceLinesForm />
      <FormHeader>
        <FormHeader.Title>Payment details</FormHeader.Title>
      </FormHeader>
      <Fields divider>
        <PaymentDetails lines={D.values(form.values.linesById) as InvoiceLine[]} stretch />
      </Fields>
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant="secondary">Close</Button>
        </Dialog.Close>
        <Button variant="secondary" onClick={previousStep}>
          Previous
        </Button>
        <FormSubmit>{form.attemptedSubmit ? "Create despite errors" : "Create"}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<FormFileType[]>> = props => {
  const { onOpenChange } = props
  const [step, setStep] = React.useState(1)
  const [parsedFiles, setParsedFiles] = React.useState<ApiInvoiceParsedFile>([])
  const nextStep = () => setStep(step => N.clamp(step + 1, 1, 2))
  const previousStep = () => setStep(step => N.clamp(step - 1, 1, 2))
  return (
    <StepsContext.Provider
      value={{
        onOpenChange,
        step,
        setStep,
        previousStep,
        nextStep,
        parsedFile: parsedFiles,
        setParsedFile: setParsedFiles,
      }}
    >
      {step === 1 && <Step1 {...props} />}
      {step === 2 && <Step2 />}
    </StepsContext.Provider>
  )
}

/**
 * context
 */
type StepsContextType = {
  onOpenChange: (state: boolean) => void
  step: number
  setStep: (step: number) => void
  previousStep: () => void
  nextStep: () => void
  parsedFile: ApiInvoiceParsedFile
  setParsedFile: (file: ApiInvoiceParsedFile) => void
}
const StepsContext = React.createContext<StepsContextType>({} as StepsContextType)
