import { ThemeDropdown } from "@/components/theme/dropdown"
import { useActiveLocation } from "@/components/ui/hooks/useActiveLocation"
import { BugPlay, ReceiptText, SearchCode, Users } from "lucide-react"
import { SidebarDivider as Divider, SidebarMenu as Menu, SidebarButton, SidebarWrapper as Wrapper } from "./components"
import { SidebarContext } from "./context"

/**
 * Sidebar
 */
export const Sidebar: React.FC = () => {
  const { isDesktop, isMobile, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  const { isActivePath, isActiveLocation } = useActiveLocation()
  const beforeClick = (): true => {
    if (isMobile) sidebar.close()
    return true
  }
  return (
    <Wrapper>
      <div className='flex flex-col w-full overflow-x-hidden'>
        <div className='flex items-center w-full h-16 cursor-pointer' onClick={() => navigate("/dashboard")}>
          <span className='relative flex flex-col justify-center items-center gap-0.5 w-16 shrink-0 font-bold text-xl text-slate-600 dark:text-slate-200'>
            <svg viewBox='0 0 32 10' className='w-8 fill-current' xmlns='http://www.w3.org/2000/svg'>
              <path d='M3.30726 0.279785L0 3.00405V6.78422L3.30726 4.05996V9.88862H7.01676V0.279785H3.30726Z' />
              <path d='M32 9.88862V0.279785H28.2905L24.9833 3.00405V6.78422L28.2905 4.05996V9.88862H32Z' />
              <path d='M22.3688 9.88911V5.83439C22.3688 2.1598 19.5084 0.111328 16.2012 0.111328C12.8939 0.111328 10.0112 2.1598 10.0112 5.83439V9.88911H13.7207V5.89775C13.7207 4.27164 14.7487 3.27907 16.2012 3.27907C17.6537 3.27907 18.6593 4.27164 18.6593 5.89775V9.88911H22.3688Z' />
            </svg>
            <span className='text-[9px] leading-none uppercase'>Peppol</span>
          </span>
          <span
            className={cxm(
              "flex flex-col text-xs leading-[14px] text-primary/80 font-semibold border-primary/50  border-l-2 pl-2 uppercase whitespace-nowrap transition-opacity duration-300",
              expanded ? "opacity-1" : "opacity-0"
            )}
          >
            Invoice
            <br /> Parser
          </span>
        </div>
        <Divider />
      </div>
      <Menu>
        <SidebarButton
          icon={<ReceiptText aria-hidden />}
          onClick={() => navigate("/dashboard")}
          active={isActiveLocation("/dashboard")}
        >
          Invoices
        </SidebarButton>
        <SidebarButton
          icon={<Users aria-hidden />}
          onClick={() => navigate("/dashboard/customers")}
          active={isActiveLocation("/dashboard/customers")}
        >
          Customers
        </SidebarButton>
        <SidebarButton
          icon={<SearchCode aria-hidden />}
          onClick={() => navigate("/dashboard/search-peppol")}
          active={isActiveLocation("/dashboard/search-peppol")}
        >
          Search on Peppol
        </SidebarButton>
        <SidebarButton
          icon={<BugPlay aria-hidden />}
          onClick={() => navigate("/dashboard/error-logs")}
          active={isActiveLocation("/dashboard/error-logs")}
        >
          Error Logs
        </SidebarButton>
      </Menu>
      <div className='flex flex-col w-full overflow-hidden'>
        <Divider />
        <div className={cxm("flex justify-center items-center py-4 gap-4", expanded ? "px-4" : "flex-col")}>
          <ThemeDropdown beforeClick={beforeClick} />
        </div>
      </div>
    </Wrapper>
  )
}
