import { usePersistedState } from "@/hooks/usePersistedState"
import { useResponsive } from "@/hooks/useResponsive"
import { useTimeout } from "@/hooks/useTimeout"
import { useWindowSize } from "@/hooks/useWindowSize"
import Confetti from "react-confetti"

/**
 * SidebarContext
 */
export const SidebarContext = React.createContext<SidebarContextType>({} as SidebarContextType)
export const useSidebar = () => React.useContext(SidebarContext)
export const useConfetti = () => React.useContext(SidebarContext)
/**
 * SidebarProvider
 */
type SidebarProviderProps = {
  children: React.ReactNode
}
export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const media = useResponsive()
  const [isMobile, isDesktop] = React.useMemo(() => {
    const isMobile = media.max("md")
    return [isMobile, !isMobile]
  }, [media.max("md")])
  const [sidebar, setSidebar] = usePersistedState(false, "sidebar", sessionStorage)
  const [mobileSidebar, setMobileSidebar] = React.useState(false)
  const contextMobile = {
    open: () => setMobileSidebar(true),
    close: () => setMobileSidebar(false),
    toggle: () => setMobileSidebar(!mobileSidebar),
    state: mobileSidebar,
  }
  const contextDesktop = {
    open: () => setSidebar(true),
    close: () => setSidebar(false),
    toggle: () => setSidebar(!sidebar),
    state: sidebar,
  }

  React.useEffect(() => {
    media.max("md") && setMobileSidebar(false)
  }, [media.max("md"), setMobileSidebar])

  const [confetti, setConfetti] = React.useState(false)
  return (
    <SidebarContext.Provider
      value={{
        isMobile,
        isDesktop,
        confetti: () => setConfetti(true),
        ...(isMobile ? contextMobile : contextDesktop),
      }}
    >
      {children}
      {confetti && <Pop hide={() => setConfetti(false)} />}
    </SidebarContext.Provider>
  )
}

const Pop: React.FC<{ hide: () => void }> = ({ hide }) => {
  const [startOpacity, setStartOpacity] = React.useState(false)
  useTimeout(() => setStartOpacity(true), 5000)
  useTimeout(hide, 8000)
  const { width, height } = useWindowSize()
  return (
    <div
      className={cx(
        "fixed inset-0 size-full pointer-events-none transition-opacity",
        startOpacity ? "opacity-0" : "opacity-100"
      )}
      style={{
        width,
        height,
      }}
    >
      <Confetti width={width} height={height} />
    </div>
  )
}

/**
 * types
 */
export type SidebarContextType = {
  isMobile: boolean
  state: boolean
  isDesktop: boolean
  open: () => void
  close: () => void
  toggle: () => void
  confetti: () => void
}
