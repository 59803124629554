import { Card, Fields } from "@/components/collection"
import { en } from "@/fns/date-fns"
import { Props } from "."
import { PaymentDetails } from "../PaymentDetails"

/**
 * PaymentCard
 */
export const PaymentCard: React.FC<Props> = props => {
  const { invoice } = props
  return (
    <Card>
      <Card.Header>
        <Card.Title>Payment details</Card.Title>
      </Card.Header>
      <Card.Content>
        <Fields divider>
          {invoice.dueDate && (
            <Fields.Item
              name="Due date"
              value={
                <>
                  {T.format(invoice.dueDate, "P", { locale: en })}
                  {invoice.dueDateText && (
                    <span className="block pt-2 text-muted-foreground text-xs">
                      {invoice.dueDateText}
                    </span>
                  )}
                </>
              }
              className="grid"
            />
          )}
          <PaymentDetails lines={invoice.invoiceLines} />
        </Fields>
      </Card.Content>
    </Card>
  )
}
