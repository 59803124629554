import { Toolbar } from "@/components/collection"
import { useFilteredCustomers } from "@/store/customers/hooks"
import { CheckCircle, FilterX, XCircle } from "lucide-react"

/**
 * Filters
 */
export const Filters: React.FC<ReturnType<typeof useFilteredCustomers>["filterable"]> = ({
  toggleActive,
  toggleInactive,
  isActive,
  isInactive,
  reset,
}) => {
  return (
    <Toolbar.Filters>
      <Toolbar.Filters.Item onClick={() => toggleInactive("validy")} active={isInactive("validy")}>
        <XCircle aria-hidden />
        Display invalid customers
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleActive("validy")} active={isActive("validy")}>
        <CheckCircle aria-hidden />
        Display valid customers
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={reset}>
        <FilterX aria-hidden />
        Reset all filter
      </Toolbar.Filters.Item>
    </Toolbar.Filters>
  )
}
