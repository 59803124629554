import { decorateStore } from "@/fns/zustand"
import { create } from "zustand"
import { ErrorLog } from "./localizers"

/**
 * types
 */
export type ErrorLogsStoreState = {
  errorLogs: ById<ErrorLog>
}

/**
 * initialState
 */
const initialState: ErrorLogsStoreState = {
  errorLogs: {},
}

/**
 * store
 */
export const errorLogsStore = decorateStore(initialState, create, {})
export const useErrorLogsStore = errorLogsStore.use
