import { Link as WouterLink, LinkProps as WouterLinkProps } from "wouter"

/**
 * Link
 */
type LinkProps = WouterLinkProps // React.ComponentPropsWithoutRef<typeof WouterLink>// & React.ComponentPropsWithoutRef<'a'>
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <WouterLink {...props}>
        <a className={className} ref={ref}>
          {children}
        </a>
      </WouterLink>
    )
  }
)
const linkCx = "text-primary hover:underline transition-colors"

/**
 * LinkExternal
 */
type LinkExternalProps = React.ComponentPropsWithoutRef<"a">
const LinkExternal = React.forwardRef<HTMLAnchorElement, LinkExternalProps>(
  ({ target, rel, children, ...props }, ref) => (
    <a ref={ref} {...props} target={target ?? "_blank"} rel={rel ?? "noreferrer noopener nofollow"}>
      {children}
    </a>
  )
)
/**
 * exports
 */
export { Link, LinkExternal, linkCx, type LinkExternalProps, type LinkProps }
