import { inputIconVariants, inputVariants } from "@/components/form"
import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import { useUuid } from "@/hooks/useUuid"
import { Search, X } from "lucide-react"
import { useToolbarContext } from "./root"

/**
 * ToolbarSearch
 */
type Props = {
  search: string
  setSearch: (search: string) => void
}
export const ToolbarSearch: React.FC<Props> = ({ search, setSearch }) => {
  const { size } = useToolbarContext()
  const searchId = useUuid()
  const searchRef = React.useRef<HTMLInputElement>(null)
  const searchClear = () => {
    searchRef.current?.focus()
    setSearch("")
  }
  return (
    <div
      className={cxm(
        "relative grow w-full",
        size === "default" && "@xl/toolbar:w-auto",
        size === "sm" && "@xl/toolbar:w-auto",
        size === "xs" && "@xs/toolbar:w-auto"
      )}
    >
      <label htmlFor={searchId} className={inputIconVariants({ size, side: "left" })}>
        <Search className='w-4 h-4 text-muted-foreground' aria-hidden />
        <SrOnly>Search</SrOnly>
      </label>
      <input
        id={searchId}
        ref={searchRef}
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        className={inputVariants({ size, icon: "both" })}
        placeholder={"search"}
      />
      <Button variant='ghost' icon onClick={searchClear} className={inputIconVariants({ size, side: "right" })}>
        <X className='text-muted-foreground' aria-hidden />
        <SrOnly>Clear search</SrOnly>
      </Button>
    </div>
  )
}
