import { z } from "zod"

/**
 * ApiMe
 */
export const apiCustomer = z.object({
  id: z.string().uuid(),
  name: z.string(),
  reference: z.string(),
  address1: z.string(),
  address2: z.string(),
  postalCode: z.string(),
  city: z.string(),
  country: z.string(),
  vat: z.string(),
  peppolId: z.string(),
})
export type ApiCustomer = z.infer<typeof apiCustomer>
