import { truncateMiddle } from "@/fns/string"
import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useMatchable"
import { useSortable } from "@/hooks/useSortable"
import { useCustomersStore } from "."
import { customerIsValid } from "./helpers"
import { Customer } from "./localizers"

/**
 * hooks
 */
export const useCustomersById = () => useCustomersStore(D.getUnsafe("customers"))
export const useCustomer = (id: Option<string>) => useCustomersStore(flow(D.getUnsafe("customers"), D.get(id ?? "")))
export const useCustomers = () => useCustomersStore(flow(D.getUnsafe("customers"), D.values))

/**
 * collection
 */
export const useFilteredCustomers = (initialLimit = 24, initialByMore = 12) => {
  const customers = useCustomers()
  const [filterable, filterBy] = useFilterable<Customer>(
    "customers-filters",
    {
      validy: (customer) => customerIsValid(customer),
    },
    {}
  )

  const [matchable, matchIn] = useMatchable<Customer>([
    ({ name }) => name,
    ({ reference }) => reference,
    ({ address1 }) => address1,
    ({ address2 }) => address2,
    ({ postalCode }) => postalCode,
    ({ city }) => city,
    ({ country }) => country,
    ({ vat }) => vat,
    ({ peppolId }) => peppolId,
  ])

  const [sortable, sortBy] = useSortable<Customer>(
    "customers-sort",
    {
      name: ({ name }) => name,
      reference: ({ reference }) => reference,
      city: ({ city }) => city,
    },
    "name"
  )

  const filtered = React.useMemo(
    () => pipe(customers, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [customers, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<Customer>(filtered.length, initialLimit, initialByMore)

  return { customers, filtered, filterable, matchable, sortable, limitable, limit }
}

/**
 * useCustomerOptions
 */
export const useCustomerOptions = () => {
  const customers = useCustomers()
  return React.useMemo(
    () =>
      A.map(customers, (customer) => ({
        value: customer.id,
        label: truncateMiddle(customer.name, 75),
      })),
    [customers]
  )
}
