import { Clipboard } from "@/components/ui/clipboard"
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard"
import { Copy } from "lucide-react"
import { Link } from "wouter"

type ErrorParserProps = {
  errorMessage: string
  errorMessageId?: string
  onlyError?: boolean
}

export const ErrorParser: React.FC<ErrorParserProps> = ({ errorMessage, errorMessageId = "", onlyError }) => {
  const parseErrors = (errorText: string): ErrorMessage[] => {
    // Étape 1: Retirer "ERROR: [" et le dernier "]"
    const trimmedErrorText = errorText.replace(/^ERROR: \[/, "").replace(/\]$/, "")

    // Étape 2: Splitter les erreurs Test=(.*?):\((.*?)\)
    const errorPattern = /\[([^\s[\]]+)\] \[([^\s[\]]+)\] in ([^\s]+) (.*?) Test=([^\s[\]]+):([^\s[\]]+)\((.*?)\),?/g

    const errors: ErrorMessage[] = []

    let match
    while ((match = errorPattern.exec(trimmedErrorText)) !== null) {
      errors.push({
        row: match[0],
        type: match[1]?.replace(/[[\]]/g, "") ?? "",
        code: match[2]?.replace(/[[\]]/g, "") ?? "",
        position: match[3]?.trim() ?? "",
        message: match[4]?.trim() ?? "",
        test: {
          type: match[5] + ":" + match[6],
          params: match[7]?.split(",").map((param) => param.trim()) ?? [],
        },
      })
    }

    return errors
  }
  const [, copy, copyDone] = useCopyToClipboard()
  const [, copy2, copyDone2] = useCopyToClipboard()

  const errors = parseErrors(errorMessage)

  return (
    <div className='flex flex-col gap-2'>
      {!onlyError && (
        <>
          <p className=''>An error occurred on the BeInvoice server during submission</p>
          <p>
            Copy the{" "}
            <button className='relative underline inline-flex items-center gap-2' onClick={() => copy(errorMessage)}>
              complete error message
              <Copy className='w-4 h-4 mr-1' />
              {copyDone && (
                <span className='absolute left-full flex rounded bg-white p-1 border text-xs text-secondary-800/75'>
                  Copied!
                </span>
              )}
            </button>{" "}
            to send to BeInvoice, or send this link to your favorite developer.
            <Link href={`/dashboard/error-messages/${errorMessageId}`}>link</Link>
            <button
              className='relative inline-flex items-center gap-2'
              onClick={() => copy2(`https://peppol.101.lu/dashboard/error-messages/${errorMessageId}`)}
            >
              <Copy className='w-4 h-4 mr-1' />
              {copyDone2 && (
                <span className='absolute left-full flex rounded bg-white p-1 border text-xs text-secondary-800/75'>
                  Copied!
                </span>
              )}
            </button>
          </p>
        </>
      )}
      <div className='flex flex-col divide-y'>
        {errors.length > 0 ? (
          A.mapWithIndex(errors, (index, message) => <Message key={index} {...{ message, index }} />)
        ) : onlyError ? (
          <div>{errorMessage}</div>
        ) : (
          <div>No errors found.</div>
        )}
      </div>
    </div>
  )
}

/**
 * Message
 */
type MessageProps = {
  message: ErrorMessage
  index: number
}
export const Message: React.FC<MessageProps> = ({ message, index }) => {
  return (
    <div key={index} className='relative flex flex-col gap-2 py-8'>
      <Clipboard text={message.row}>
        <span className='flex items-center gap-2 text-sm'>
          <span className='font-bold text-red-600'>{pipe(message.type, S.replace("_", " "), S.toUpperCase)} : </span>
          <span className='font-medium'>{message.code}</span>
          <span className='text-xs'>
            in <span className='font-mono'>{message.position}</span>
          </span>
        </span>
      </Clipboard>
      <div className='pb-2 text-sm font-light italic text-muted-foreground'>{message.message}</div>
      <div className='relative p-4 pt-10 border rounded-md font-mono font-bold'>
        <span className='absolute top-3 left-4 font-medium text-sm'>TEST</span>
        <span className='text-green-700'>{message.test.type}</span>
        <span>
          ({" "}
          {message.test.params.map((param, index) => (
            <React.Fragment key={index}>
              <span className='text-muted-foreground font-medium'>{param}</span>
              {message.test.params.length - 1 > index && ", "}
            </React.Fragment>
          ))}{" "}
          )
        </span>
      </div>
    </div>
  )
}

type ErrorMessage = {
  row: string
  type: string
  code: string
  position: string
  message: string
  test: {
    type: string
    params: string[]
  }
}
