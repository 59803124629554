import { ThemeProvider } from "@/components/theme/context"
import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import { Menu } from "lucide-react"

import { HiddenEasterEgg } from "../ui/hidden-easter-egg"
import { Ninja } from "../ui/ninja"
import { Breadcrumb, Breadcrumbs } from "./breadcrumbs"
import { Sidebar } from "./sidebar"
import { SidebarContext, SidebarProvider } from "./sidebar/context"
import { UserDropdown } from "./user-dropdown"

/**
 * Layout
 */
type LayoutProps = {
  children: React.ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider>
      <SidebarProvider>
        <div className="relative grid min-h-screen dark:bg-black bg-white dark:bg-grid-white/[0.04] bg-grid-black/[0.04]">
          <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-neutral-50 [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]" />
          <Sidebar />
          {children}
        </div>
        <HiddenEasterEgg code={["1", "0", "1"]} reset={30000}>
          <Ninja message="Are you looking for the 101 team?" />
        </HiddenEasterEgg>
      </SidebarProvider>
    </ThemeProvider>
  )
}
export default Layout

/**
 * PageWrapper
 */
export const PageWrapper = React.forwardRef<HTMLDivElement, React.ComponentProps<"main">>(
  ({ className, ...props }, ref) => {
    const sidebar = React.useContext(SidebarContext)
    return (
      <main
        ref={ref}
        className={cxm(
          "md:transition-all md:ease-in-out md:duration-300 pr-4 pt-4 pl-4 pb-48",
          sidebar.state ? "md:ml-[320px]" : "md:ml-24",
          className
        )}
        {...props}
      />
    )
  }
)

/**
 * PageContent
 */
export const PageContent = React.forwardRef<HTMLDivElement, React.ComponentProps<"div">>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cxm(className)} {...props} />
  }
)

/**
 * TempToggle
 */
export const SidebarToggle: React.FC = () => {
  const sidebar = React.useContext(SidebarContext)
  return (
    <Button
      onClick={sidebar.toggle}
      variant="secondary"
      icon
      aria-label="Toggle sidebar"
      side="left"
    >
      <Menu size={16} aria-hidden />
      <SrOnly>Toggle sidebar</SrOnly>
    </Button>
  )
}

/**
 * PageHeader
 */
type PageHeaderProps = {
  breadcrumbs: Breadcrumb[]
  syntheticBreadcrumbs?: React.ReactNode
  image?: string
  children?: React.ReactNode
}
export const PageHeader: React.FC<PageHeaderProps> = ({ breadcrumbs, image, children }) => {
  return (
    <>
      {G.isNotNullable(image) && (
        <div
          className={cxm(
            "absolute top-0 inset-x-0 -z-50 h-24 bg-cover bg-no-repeat bg-center opacity-1"
          )}
          style={{ backgroundImage: `url('${image}')` }}
        />
      )}
      <div className="relative flex justify-between items-center pb-4 gap-2">
        <SidebarToggle />
        <Breadcrumbs {...{ breadcrumbs }} className="grow" />
        <div className="flex gap-2">
          {G.isNotNullable(children) && <div>{children}</div>}
          <UserDropdown align="end" side="bottom" />
        </div>
      </div>
    </>
  )
}
