import { parseCommaNumber } from "@/fns/number"
import { Invoice, InvoiceLine } from "@/store/invoices/localizers"
import { v4 as uuid } from "uuid"
import { z } from "zod"

/**
 * ApiInvoiceParsedFile
 */
export const apiInvoiceParsedFile = z
  .object({
    "Client ID": z.string(),
    "Client First Name": z.string(),
    "Client Last Name": z.string(),
    "Client Company": z.string(),
    "Project ID": z.string(),
    Project: z.string(),
    "Slip ID": z.string(),
    Name: z.string(),
    Kind: z.string(),
    "Kind ID": z.string(),
    Category: z.string(),
    Comment: z.string(),
    Nature: z.string(),
    "Create Date": z.string(),
    "Start Date": z.string(),
    "Due Date": z.string(),
    "Complete Date": z.string(),
    Units: z.string(),
    Duration: z.string(),
    Rate: z.string(),
    "Round Time": z.string(),
    SubTotal: z.string(),
    Markup: z.string(),
    Discount: z.string(),
    Total: z.string(),
    Currency: z.string(),
    Symbol: z.string(),
    Invoice: z.string(),
    Worker: z.string(),
    "TVA Brutte": z.string().optional(),
    "TVA Total": z.string().optional(),
  })
  .array()
export type ApiInvoiceParsedFile = z.infer<typeof apiInvoiceParsedFile>
export type ApiInvoiceParsedLine = ApiInvoiceParsedFile[number]

/**
 * parsedFileToInvoice
 */
export const parsedFileToInvoice = (
  file: ApiInvoiceParsedFile,
  customers: {
    id: string
    reference: string
  }[]
): Pick<
  Invoice,
  | "type"
  | "customerId"
  | "date"
  | "dueDate"
  | "dueDateText"
  | "note"
  | "senderProjectReference"
  | "invoiceReference"
  | "customerProjectReference"
  | "invoiceLines"
> => {
  const firstLine = A.head(file) as ApiInvoiceParsedLine
  return {
    type: "debit",
    customerId:
      A.find(customers, ({ reference }) => reference === firstLine["Client ID"])?.id ?? "",
    date: new Date(),
    dueDate: T.add(new Date(), { days: 30 }),
    dueDateText: "Paiement à 30 jours",
    note: firstLine["Project"],
    customerProjectReference: firstLine["Project ID"],
    invoiceReference: firstLine["Invoice"],
    senderProjectReference: firstLine["Project ID"],
    invoiceLines: A.mapWithIndex(file, parsedLineToInvoiceLine),
  }
}

/**
 * parsedLineToInvoiceLine
 */
const parsedLineToInvoiceLine = (position: number, line: ApiInvoiceParsedLine): InvoiceLine => ({
  id: uuid(),
  position,
  invoiceId: "",
  quantity: calculateQuantity(line),
  unitCode: "E48",
  reference: line["Slip ID"],
  name: line["Name"],
  description: line["Comment"],
  unitPriceNoTax: calculateUnitPriceNoTax(line),
  vat: calculateVat(line),
  vatCode: "S",
  discountPercentage: parseCommaNumber(line["Discount"]),
})

/**
 * calculateVat
 */
const calculateVat = (item: ApiInvoiceParsedLine): number => {
  // Définir les taux de TVA possibles
  const possibleTvaRates = [3, 8, 14, 17] //  [3, 7, 8, 13, 14, 16, 17]
  const subTotal = calculateSubTotal(item)
  const tvaString = item["TVA Brutte"] ?? item["TVA Total"]
  if (!tvaString) return A.last(possibleTvaRates) ?? 0
  const tva = parseFloat(tvaString.replace(",", "."))
  const calculatedTvaPercentage = (tva / subTotal) * 100

  // Trouver le taux de TVA le plus proche du taux calculé
  const closestTvaRate = possibleTvaRates.reduce((prev, curr) => {
    return Math.abs(curr - calculatedTvaPercentage) < Math.abs(prev - calculatedTvaPercentage)
      ? curr
      : prev
  })

  return closestTvaRate // Retourne le taux de TVA le plus proche
}

/**
 * calculateUnitPriceNoTax
 */
const calculateUnitPriceNoTax = (item: ApiInvoiceParsedLine): number => {
  // const rate = parseFloat(item.Rate)
  const subTotal = calculateSubTotal(item)
  const quantity = calculateQuantity(item)
  const unitPriceNoTax = subTotal / quantity
  return parseFloat(unitPriceNoTax.toFixed(2)) // Arrondit le prix unitaire à deux décimales pour la précision
}

/**
 * calculateSubTotal
 */
const calculateSubTotal = (item: ApiInvoiceParsedLine): number => {
  const markup = (parseFloat(item.Markup) + 100) / 100
  const subTotal = parseFloat(item.SubTotal) * markup
  return subTotal
}

/**
 * calculateQuantity
 */
const calculateQuantity = (item: ApiInvoiceParsedLine): number => {
  const rate = parseFloat(item.Rate)
  const subTotal = parseFloat(item.SubTotal)
  const units = parseInt(item.Units, 10)
  const quantity = units === 0 ? 1 : subTotal / rate
  return quantity
}
