import { byId } from "@/fns/collection"
import * as Customers from "@/services/customers/service"
import { match } from "ts-pattern"
import { customersStore } from "."
import { localizeCustomer } from "./localizers"

/**
 * getCustomers
 */
export const getCustomers = async () =>
  match(await Customers.service.index())
    .with({ error: false }, ({ data }) => {
      customersStore.evolve({
        customers: byId(data.customers, localizeCustomer),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createCustomer
 */
export const createCustomer = async (payload: Customers.Payload["create"]) =>
  match(await Customers.service.create(payload))
    .with({ error: false }, ({ data }) => {
      customersStore.evolve({
        customers: D.set(data.customer.id, localizeCustomer(data.customer)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getCustomer
 */
export const getCustomer = async (id: string) =>
  match(await Customers.service.read(id))
    .with({ error: false }, ({ data }) => {
      customersStore.evolve({
        customers: D.set(data.customer.id, localizeCustomer(data.customer)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateCustomer
 */
export const updateCustomer = async (id: string, payload: Customers.Payload["update"]) =>
  match(await Customers.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      customersStore.evolve({
        customers: D.set(data.customer.id, localizeCustomer(data.customer)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteCustomer
 */
export const deleteCustomer = async (id: string) =>
  match(await Customers.service.delete(id))
    .with({ error: false }, () => {
      customersStore.evolve({ customers: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
