import { usePersistedState } from "./usePersistedState"

export const useFilterable = <
  T extends Record<string, unknown>,
  F = Record<string, (item: T) => boolean>
>(
  storageKey: string,
  fields: F,
  initial: Partial<Record<keyof F, boolean>>
) => {
  const [filters, setFilters] = usePersistedState(initial, storageKey, sessionStorage)

  const isActive = React.useCallback((field: keyof F) => D.get(filters, field) === true, [filters])
  const isInactive = React.useCallback(
    (field: keyof F) => D.get(filters, field) === false,
    [filters]
  )

  const toggle = (field: keyof F) => {
    const current = D.get(filters, field)
    const key = field as string
    if (G.isNullable(current) || current === false) setFilters(D.set(key, true))
    else setFilters(D.set(filters, key, false))
  }

  const setFalse = (field: keyof F) => {
    const current = D.get(filters, field)
    const key = field as string
    if (G.isNullable(current) || current === true) setFilters(D.set(key, false))
  }
  const setTrue = (field: keyof F) => {
    const current = D.get(filters, field)
    const key = field as string
    if (G.isNullable(current) || current === false) setFilters(D.set(key, true))
  }
  const unset = (field: keyof F) => {
    if (field in filters) {
      setFilters(filters => D.rejectWithKey(filters, current => current === field))
    }
  }
  // @deprecated
  const clearOne = unset

  // @deprecated
  const clear = () => setFilters({})

  const reset = () => setFilters(initial)
  const toggleActive = (field: keyof F) => (isActive(field) ? unset(field) : setTrue(field))
  const toggleInactive = (field: keyof F) => (isInactive(field) ? unset(field) : setFalse(field))

  const list = React.useMemo(() => D.keys(fields as Record<string, unknown>), [fields])
  const filterBy = React.useCallback(
    (items: T[]) =>
      A.filter(items, item =>
        A.every(D.keys(filters), key => {
          const fn = D.get(fields, key) as Option<(item: T) => boolean>
          const compare = D.get(filters, key)
          return G.isNotNullable(fn) ? fn(item) === compare : true
        })
      ),
    [filters, fields]
  )

  return [
    {
      filters,
      setFilters,
      list,
      toggle,
      setFalse,
      setTrue,
      unset,
      clearOne,
      clear,
      reset,
      isActive,
      isInactive,
      toggleActive,
      toggleInactive,
    },
    filterBy,
  ] as const
}

/**
 * types
 */
export type Filterable<T extends Record<string, unknown>, K extends keyof T> = ReturnType<
  typeof useFilterable<T, K>
>[0]
