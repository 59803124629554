import globalConfig from "@/config/global"
import { makePath } from "@/fns/path"
import { createFetcher, request } from "@101studios/request"

export const getSessionToken = () => (globalConfig.sessionKey ? localStorage.getItem(globalConfig.sessionKey) : null)
export const setSessionToken = (token: string) =>
  globalConfig.sessionKey && localStorage.setItem(globalConfig.sessionKey, token)
export const removeSessionToken = () => globalConfig.sessionKey && localStorage.removeItem(globalConfig.sessionKey)

export const api = createFetcher((url, opts) => {
  const sessionToken = getSessionToken()
  return request(makePath(globalConfig.api, url), {
    ...opts,
    throwCanceled: false,
    throwNetwork: false,
    credentials: "include",
    headers: {
      ...opts?.headers,
      ...(sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}),
    },
  })
})

/**
 * types
 */
export type NoContent = {
  //
}
export type NoError = {
  code: "FETCH_ERROR"
}
export type ErrorCode<T extends string> = {
  code: T
}
export type AuthErrorCode<T extends string = "INVALID_AUTH_SESSION"> = {
  code: T | "INVALID_AUTH_SESSION"
}
export type AdminErrorCode<T extends string = "INVALID_AUTH_SESSION"> = {
  code: T | "RESOURCE_NOT_ALLOWED" | "INVALID_AUTH_SESSION"
}
