import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { ConfirmDialogProps, confirmable, createConfirmation } from "react-confirm"
import { confirmDictionary, useConfirm } from "./hooks/useConfirm"

/**
 * Confirm
 */
type Props = Omit<ReturnType<typeof useConfirm<any>>, "confirm"> & {
  children?: React.ReactNode
}
export const Confirm: React.FC<Props> = ({ open, onCancel, onConfirm, onAsyncConfirm, dictionary, children }) => {
  const getText = (key: keyof typeof confirmDictionary) => D.getUnsafe({ ...confirmDictionary, ...dictionary }, key)

  const onClick = async () => {
    if (open === false) return
    if (G.isNotNullable(onConfirm)) onConfirm(open)
    if (G.isNotNullable(onAsyncConfirm)) {
      const toastId = toast.loading(getText("progress"))
      if ((await onAsyncConfirm(open)).error) toast.error(getText("error"), { id: toastId })
      else toast.success(getText("success"), { id: toastId })
    }
    onCancel()
  }
  return (
    <Dialog.Root open={open !== false} onOpenChange={onCancel} modal>
      <Dialog.Content hideClose size='max-w-md'>
        <Dialog.Header>
          <Dialog.Title>{getText("title")}</Dialog.Title>
          {S.isNotEmpty(getText("description")) && <Dialog.Description>{getText("description")}</Dialog.Description>}
        </Dialog.Header>
        {G.isNotNullable(children) && <Dialog.Content>{children}</Dialog.Content>}
        <Dialog.Footer>
          <Button variant={"outline"} onClick={onCancel}>
            {getText("cancel")}
          </Button>
          <Button onClick={onClick}>{getText("confirm")}</Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

/**
 * Confirmable
 * dictionary src/dictionaries/en/components/ui/confirm.json
 */
type ConfirmableProps = {
  dictionary: Partial<typeof confirmDictionary>
}
const Confirmable: React.FC<ConfirmDialogProps<ConfirmableProps, boolean>> = ({ dictionary, proceed, show }) => {
  const getText = (key: keyof typeof confirmDictionary) => D.getUnsafe({ ...confirmDictionary, ...dictionary }, key)
  return (
    <Dialog.Root open={show} onOpenChange={() => proceed(false)} modal>
      <Dialog.Content hideClose size='max-w-md'>
        <Dialog.Header>
          <Dialog.Title>{getText("title")}</Dialog.Title>
          {S.isNotEmpty(getText("description")) && <Dialog.Description>{getText("description")}</Dialog.Description>}
        </Dialog.Header>
        <Dialog.Footer>
          <Button variant={"outline"} onClick={() => proceed(false)}>
            {getText("cancel")}
          </Button>
          <Button onClick={() => proceed(true)}>{getText("confirm")}</Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

/**
 * confirmAlert
 * example:
 * const isConfirm = await confirmAlert({ dictionary: "pages." })
 */
export const confirmAlert = createConfirmation(confirmable(Confirmable))
