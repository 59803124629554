import { Row } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { Clipboard } from "@/components/ui/clipboard"
import { SearchResult } from "@/services/search-peppol/schemas"
import { SearchCode } from "lucide-react"

export const Item: React.FC<{ item: SearchResult }> = ({ item }) => {
  return (
    <div>
      <Row>
        <Row.Image>
          <SearchCode size={16} />
        </Row.Image>
        <Row.Header>
          <Row.Title>
            <Clipboard text={item.name}>{item.name}</Clipboard>
          </Row.Title>
          <Row.Description>
            <Clipboard text={item.peppolId}>{item.peppolId}</Clipboard>
          </Row.Description>
        </Row.Header>
        <Row.Content className='flex flex-wrap gap-2'>
          {G.isNotNullable(item.vat) && (
            <Badge variant='secondary'>
              <Clipboard text={item.vat}>{item.vat}</Clipboard>
            </Badge>
          )}
        </Row.Content>
      </Row>
    </div>
  )
}
