import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import { SrOnly } from "@/components/ui/sr-only"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { Filter } from "lucide-react"

/**
 * Filters
 */
const Filters: React.FC<React.PropsWithChildren<{ onClick?: () => void }>> = ({ children, onClick }) => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button variant='secondary' icon>
          <Filter aria-hidden />
          <SrOnly>Filter</SrOnly>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align='end' side='bottom' className='w-56' onClick={onClick}>
        {children}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

/**
 * FiltersButton
 */
type FiltersButtonProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
  active?: boolean
  inset?: boolean
}
const FiltersButton: React.FC<FiltersButtonProps> = ({ ...props }) => {
  return <DropdownMenu.Item {...props} />
}

export const ToolbarFilters = Object.assign(Filters, {
  Button: FiltersButton,
  Separator: DropdownMenu.Separator,
  Item: FiltersButton,
})
