import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import { MoreVertical } from "lucide-react"
import { Menu } from "."

/**
 * SectionRoot
 */
const SectionRoot: React.FC<React.ComponentPropsWithoutRef<"section">> = ({ className, ...props }) => (
  <section
    className={cxm(
      "flex flex-col max-w-screen-2xl mx-auto mt-4 p-8 gap-4 bg-card rounded-md border shadow-sm",
      className
    )}
    {...props}
  />
)

/**
 * SectionHeader
 */
const SectionHeader = React.forwardRef<React.ElementRef<"div">, React.ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cxm("relative flex flex-col space-y-1.5 pt-4 text-center sm:text-left pr-12", className)}
      {...props}
    />
  )
)

/**
 * SectionHeaderTitle
 */
const SectionHeaderTitle = React.forwardRef<React.ElementRef<"h3">, React.ComponentPropsWithoutRef<"h3">>(
  ({ className, ...props }, ref) => (
    <h3 ref={ref} className={cxm("text-base font-normal leading-none tracking-tight", className)} {...props} />
  )
)

/**
 * SectionHeaderDescription
 */
const SectionHeaderDescription = React.forwardRef<React.ElementRef<"p">, React.ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p ref={ref} className={cxm("text-sm text-muted-foreground font-light", className)} {...props} />
  )
)

/**
 * SectionMenu
 */
const SectionMenu: React.FC<React.ComponentPropsWithoutRef<"button"> & { menu: React.ReactNode }> = ({
  className,
  menu,
  ...props
}) => {
  return (
    <Menu menu={menu} type='dropdown-menu' align='start' side='left'>
      <Button
        variant='ghost'
        size='xs'
        icon
        className={cxm("absolute top-2 right-2 rounded-full", className)}
        {...props}
      >
        <MoreVertical aria-hidden />
        <SrOnly>Menu</SrOnly>
      </Button>
    </Menu>
  )
}

/**
 * SectionCard
 */
const SectionCard: React.FC<React.ComponentPropsWithoutRef<"section">> = ({ className, ...props }) => (
  <section className={cxm("flex flex-col p-8 gap-4 bg-card rounded-md border shadow-sm", className)} {...props} />
)
/**
 * exports
 */
export const Section = Object.assign(SectionRoot, {
  Card: SectionCard,
  Header: SectionHeader,
  HeaderTitle: SectionHeaderTitle,
  HeaderDescription: SectionHeaderDescription,
  Menu: SectionMenu,
})
