import { Card } from "@/components/collection"
import { ListItem, ListLeftRight } from "@/components/collection/list"
import { en } from "@/fns/date-fns"
import { Props } from "."

/**
 * InvoiceCard
 */
export const InvoiceCard: React.FC<Props> = (props) => {
  const { invoice } = props
  return (
    <Card>
      <Card.Header>
        <Card.Title>Invoice details</Card.Title>
        <Card.Description>{invoice.note}</Card.Description>
      </Card.Header>
      <Card.Content>
        <ListLeftRight divider>
          <ListItem label='Project'># {invoice.senderProjectReference}</ListItem>
          <ListItem label='Customer project'># {invoice.customerProjectReference}</ListItem>
          {G.isNotNullable(invoice.date) && (
            <ListItem label='Date'>{T.format(invoice.date, "P", { locale: en })}</ListItem>
          )}
        </ListLeftRight>
      </Card.Content>
    </Card>
  )
}
