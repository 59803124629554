import {
  FormFieldGroup,
  FormHeader,
  FormInput,
  FormNumber,
  FormReorderableItem,
  FormReorderableList,
  FormSection,
  FormSelect,
  FormSelectUnitCode,
  FormSelectVat,
  FormSelectVatCode,
  FormTextarea,
  useFormContext,
  validator,
  ValuesValidate,
} from "@/components/form"
import { FormFiles, FormFileType, isFile } from "@/components/form/files"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { toRoudedString } from "@/fns/math"
import { textPlaceholder, truncateMiddle } from "@/fns/string"
import { ApiInvoiceType } from "@/services/invoices/schemas"
import { Payload } from "@/services/invoices/service"
import { useCustomerOptions } from "@/store/customers/hooks"
import { useInvoiceTypeOptions } from "@/store/invoices/hooks"
import { InvoiceLine } from "@/store/invoices/localizers"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { ShoppingBasket, Trash } from "lucide-react"
import { match } from "ts-pattern"
import { v4 as uuid } from "uuid"

export const InvoiceForm: React.FC = () => {
  const customerOptions = useCustomerOptions()
  const typeOptions = useInvoiceTypeOptions()
  return (
    <>
      <FormHeader>
        <FormHeader.Title>Invoice information</FormHeader.Title>
      </FormHeader>
      <FormSection className="grid grid-cols-4">
        <FormInput
          label="Reference"
          name="invoiceReference"
          placeholder="101-1234"
          groupCx="col-span-2 ali"
        />
        <FormSelect label="Type" name="type" options={typeOptions} />
        <FormInput label="Date" name="date" type="date" />
        <FormSelect
          label="Customer"
          name="customerId"
          options={customerOptions}
          groupCx="col-span-4"
          placeholder="Select a customer"
        />
        <FormInput
          label="Customer project reference"
          name="customerProjectReference"
          placeholder="1234567"
          groupCx="col-span-2"
        />
        <FormInput label="Due date" name="dueDate" type="date" />
        <FormInput
          label="Due date comment"
          name="dueDateText"
          placeholder="A comment about due date"
        />
        <FormTextarea
          label="Notes"
          name="note"
          groupCx="col-span-4"
          placeholder="Leave notes about the invoice"
        />
        <FormFiles
          label="Invoice file (PDF)"
          name="attachment"
          multiple={false}
          accept={["pdf"]}
          groupCx="col-span-4"
        />
      </FormSection>
    </>
  )
}

const { min, isBeforeIso, isAfterIso } = validator
export const invoiceValidation = validator<FormValues>({
  invoiceReference: [min(1, "Reference is required")],
  date: [
    min(1, "Date is required"),
    isAfterIso("2000-01-01", `Date must be after ${T.format(T.parseISO("2000-01-01"), "P")}`),
    isBeforeIso("2099-12-31", `Date must be before ${T.format(T.parseISO("2099-12-31"), "P")}`),
  ],
  dueDate: [
    min(1, "Date is required"),
    isAfterIso("2000-01-01", `Date must be after ${T.format(T.parseISO("2000-01-01"), "P")}`),
    isBeforeIso("2099-12-31", `Date must be before ${T.format(T.parseISO("2099-12-31"), "P")}`),
  ],
  customerId: [min(1, "You need select a customer")],
  customerProjectReference: [min(1, "Customer project reference is required")],
  attachment: [min(1, "File attachment must be upload")],
})
export const invoiceLineValidation = validator<FormValues["linesById"][string]>({
  name: [min(1, "Name is required")],
  reference: [min(1, "Reference is required")],
  quantity: [min(1, "Quantity is required")],
  unitPriceNoTax: [min(1, "Unit price no tax is required")],
})
export const invoiceValidator = (values: FormValues): ValuesValidate<FormValues> => ({
  ...invoiceValidation(values),
  linesById: D.map(values.linesById, invoiceLineValidation),
})

/**
 * InvoiceLinesForm
 */
export const InvoiceLinesForm: React.FC = () => {
  const { values, setValues } = useFormContext<FormValues>()

  // create a new line
  const createLine = () => {
    const id = uuid()
    setValues({
      lines: [...values.lines, id],
      linesById: D.set(values.linesById, id, emptyLine),
    })
  }

  // drag and drop reordering
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (!(over && active.id !== over.id)) return
    const oldIndex = A.getIndexBy(values.lines, id => id === active.id)
    const newIndex = A.getIndexBy(values.lines, id => id === over.id)
    if (G.isNullable(oldIndex) || G.isNullable(newIndex)) return
    setValues({ lines: arrayMove(values.lines, oldIndex, newIndex) })
  }
  return (
    <FormFieldGroup name="linesById">
      <FormSection>
        <FormHeader>
          <FormHeader.Title>Products list</FormHeader.Title>
        </FormHeader>
        <FormReorderableList
          onDragEnd={onDragEnd}
          items={values.lines}
          createButton="Create a new product"
          create={createLine}
        >
          {A.mapWithIndex(values.lines, (index, id) => (
            <FormLineReorderable id={id} index={index} key={id} />
          ))}
        </FormReorderableList>
      </FormSection>
    </FormFieldGroup>
  )
}

/**
 * FormLineReorderable
 */
const FormLineReorderable: React.FC<{ id: string; index: number }> = ({ id, index }) => {
  const { values, setValues } = useFormContext<FormValues>()

  // delete line
  const deleteLine = () => {
    setValues({
      lines: A.reject(values.lines, card => card === id),
      linesById: D.deleteKey(values.linesById, id),
    })
  }

  // keyboard accessibility reordering
  const onKeyDown = (keyCode: "ArrowUp" | "ArrowDown") =>
    match(keyCode)
      .with("ArrowUp", () => {
        const newIndex = index - 1
        if (newIndex < 0) return
        setValues({
          lines: arrayMove(values.lines, index, newIndex),
        })
      })
      .with("ArrowDown", () => {
        const newIndex = index + 1
        if (newIndex >= values.lines.length) return
        setValues({
          lines: arrayMove(values.lines, index, newIndex),
        })
      })
  const line = D.get(values.linesById, id)
  if (G.isNullable(line)) return null
  const totalPrice =
    (line.unitPriceNoTax - (line.unitPriceNoTax * line.discountPercentage) / 100) * line.quantity
  return (
    <FormFieldGroup name={id}>
      <FormReorderableItem
        id={id}
        title={truncateMiddle(textPlaceholder(line.name, "Product"), 30)}
        titleLevel={3}
        actions={
          <>
            <Badge variant="outline">
              <ShoppingBasket size={16} aria-hidden />
              {line.quantity}
            </Badge>
            <Badge variant="outline">{line.vat} %</Badge>
            <Badge variant="outline">{toRoudedString(totalPrice)} €</Badge>
            <Button
              variant="secondary"
              size="xxs"
              icon
              onClick={deleteLine}
              aria-label="Delete this product"
            >
              <Trash aria-hidden />
            </Button>
          </>
        }
        onKeyDown={onKeyDown}
      >
        <InvoiceLineForm />
      </FormReorderableItem>
    </FormFieldGroup>
  )
}

/**
 * FormLine
 */
export const InvoiceLineForm: React.FC = () => (
  <FormSection className="grid grid-cols-4 gap-4">
    <FormInput
      name="name"
      label="Name"
      placeholder="Enter the name of the product"
      groupCx="col-span-3"
    />
    <FormInput name="reference" label="Reference" placeholder="1234567" />
    <FormTextarea
      name="description"
      label="Description"
      placeholder="Enter e description"
      groupCx="col-span-4"
    />
    <FormNumber name="quantity" label="Quantity" placeholder="0" />
    <FormNumber name="unitPriceNoTax" label="Unit price no taxe" placeholder="0.00" postfix=" €" />
    <FormSelectUnitCode name="unitCode" label="Unit code" groupCx="col-span-2" />
    <FormSelectVat name="vat" label="Vat amount" />
    <FormSelectVatCode name="vatCode" label="Vat code" groupCx="col-span-2" />
    <FormNumber
      name="discountPercentage"
      label="Discount Percentage"
      placeholder="0.00"
      postfix=" %"
    />
  </FormSection>
)

/**
 * basicFields
 */
export const basicFields = [
  "type",
  "invoiceReference",
  "senderProjectReference",
  "dueDateText",
  "customerId",
  "customerProjectReference",
  "note",
] as const

/**
 * emptyLine
 */
export const emptyLine = {
  quantity: 1,
  unitCode: "E48",
  reference: "",
  name: "",
  description: "",
  unitPriceNoTax: 1,
  vat: 17,
  vatCode: "S" as const,
  discountPercentage: 0,
}

/**
 * FormValues
 */
export type FormValues = {
  invoiceReference: string
  type: ApiInvoiceType
  date: string
  senderProjectReference: string
  dueDate: string
  dueDateText: string
  customerId: string
  customerProjectReference: string
  note: string
  attachment: FormFileType[]
  lines: string[]
  linesById: Record<string, Omit<InvoiceLine, "id" | "position" | "invoiceId">>
}

export const formValuesToPayload = (values: FormValues): Payload["create"] => ({
  ...D.selectKeys(values, [...basicFields, "date", "dueDate"]),
  senderProjectReference: values.customerProjectReference,
  attachment: A.head(A.filter(values.attachment, isFile)),
  invoiceLines: A.filterMap(values.lines, id =>
    values.linesById[id]
      ? D.set(values.linesById[id], "position", values.lines.indexOf(id))
      : O.None
  ),
})
