/**
 * Fields
 */
const FieldsRoot = React.forwardRef<HTMLUListElement, React.ComponentPropsWithoutRef<"ul"> & { divider?: boolean }>(
  ({ className, divider = false, ...props }, ref) => (
    <ul
      className={cxm("@container/fields group/fields flex flex-col text-sm", divider && "divide-y", className)}
      ref={ref}
      {...props}
    />
  )
)

/**
 * Field
 */
type FieldsItemProps = Extend<
  React.ComponentPropsWithoutRef<"li">,
  {
    name: React.ReactNode
    icon?: React.ReactNode
    value?: React.ReactNode
    stretch?: boolean
  }
>
const FieldsItem = React.forwardRef<HTMLLIElement, FieldsItemProps>(
  ({ name, icon, value, stretch = false, className, children, ...props }, ref) => (
    <li
      className={cxm(
        "flex flex-col @xs/fields:grid items-start grid-cols-3 gap-x-2",
        stretch ? "py-2" : "py-4",
        className
      )}
      ref={ref}
      {...props}
    >
      <span className='flex items-center col-span-1 font-medium [&>svg]:size-4 [&>svg]:mr-2'>
        {icon}
        {name}
      </span>
      <span className='flex flex-col col-span-2'>{value ?? children}</span>
    </li>
  )
)

/**
 * exports
 */
export const Fields = Object.assign(FieldsRoot, {
  Item: FieldsItem,
})
