import { Grid, Menu, Row, useMenu } from "@/components/collection"
import { ListItem, ListLeftRight } from "@/components/collection/list"
import { Badge } from "@/components/ui/badge"
import { Dot } from "@/components/ui/dot"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSpotlight } from "@/components/ui/hooks/useSpotlight"
import { Popover } from "@/components/ui/popover"
import { Spotlight } from "@/components/ui/spotlight"
import { SrOnly } from "@/components/ui/sr-only"
import { lineIsValid } from "@/fns/invoice"
import { toRoudedString } from "@/fns/math"
import { unitCodes } from "@/services/invoices/unit-codes"
import { reorderInvoiceLines } from "@/store/invoices/actions"
import { Invoice, InvoiceLine } from "@/store/invoices/localizers"
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { BadgePercent, SquarePen, SquarePlus, Trash } from "lucide-react"
import { usePageContext } from "../Context"

type Props = { invoice: Invoice }

/**
 * Lines
 */
export const Lines: React.FC<Props> = props => {
  const { invoice } = props
  const isNotSubmitted = invoice.status !== "submitted"

  const lines = React.useMemo(
    () => pipe(invoice.invoiceLines, A.sortBy(D.getUnsafe("position"))),
    [invoice.invoiceLines]
  )
  const { setItem: createItem, ...createItemProps } = useDialog<number>()

  // drag and drop reordering
  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (active.id !== over?.id) {
        const list = A.map(lines, D.getUnsafe("id"))
        const oldIndex = list.indexOf(active.id as string)
        const newIndex = list.indexOf(over!.id as string)
        reorderInvoiceLines(invoice.id, { lines: arrayMove(list, oldIndex, newIndex) })
      }
    },
    [lines]
  )

  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)

  // keyboard accessibility reordering
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => {
      const keyCode = e.key
      if (!A.includes(["ArrowUp", "ArrowDown"], keyCode)) return
      e.preventDefault()
      const list = A.map(lines, D.prop("id"))
      const oldIndex = list.indexOf(id as string)
      switch (keyCode) {
        case "ArrowUp": {
          const newIndex = oldIndex - 1
          if (newIndex < 0) return
          reorderInvoiceLines(invoice.id, { lines: arrayMove(list, oldIndex, newIndex) })
          break
        }
        case "ArrowDown": {
          const newIndex = oldIndex + 1
          if (newIndex >= list.length) return
          reorderInvoiceLines(invoice.id, { lines: arrayMove(list, oldIndex, newIndex) })
          break
        }
      }
    },
    [lines]
  )

  return isNotSubmitted ? (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={lines} strategy={verticalListSortingStrategy}>
        <Grid view="list">
          {A.map(lines, line => (
            <LineReorderable key={line.id} line={line} onKeyDown={onKeyDown} isNotSubmitted />
          ))}
        </Grid>
      </SortableContext>
    </DndContext>
  ) : (
    <Grid view="list">
      {A.map(lines, line => (
        <Line key={line.id} line={line} />
      ))}
    </Grid>
  )
}

/**
 * LineReorderable
 */
const LineReorderable: React.FC<{
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => void
  isNotSubmitted?: boolean
  line: InvoiceLine
}> = ({ line, onKeyDown, isNotSubmitted = false }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: line.id,
  })
  const style = { transform: CSS.Transform.toString(transform), transition }
  return (
    <Menu type="context-menu" menu={<LineContextMenu line={line} />} asChild>
      <div
        ref={setNodeRef}
        style={style}
        className={cx(
          "relative grid w-full transition-all",
          isDragging ? "opacity-75 z-20" : "opacity-100 z-10"
        )}
      >
        <button
          className="absolute z-10 cursor-default inset-0 w-full h-full rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
          {...listeners}
          {...attributes}
          onKeyDown={e => onKeyDown(e, line.id)}
          type="button"
        >
          <SrOnly>Drag line</SrOnly>
        </button>
        <Line line={line} />
        <Row.Menu
          className="@lg/collection:absolute top-3 z-10"
          menu={<LineContextMenu line={line} />}
        />
      </div>
    </Menu>
  )
}

/**
 * Line
 */
const Line: React.FC<{
  isNotSubmitted?: boolean
  line: InvoiceLine
}> = ({ line }) => {
  const { cardProps, cardSpotlightProps } = useSpotlight()
  const {
    name,
    description,
    discountPercentage,
    quantity,
    unitPriceNoTax,
    vat,
    reference,
    unitCode,
  } = line
  const code = React.useMemo(() => A.find(unitCodes, unit => unit.value === unitCode), [unitCode])
  const totalPrice = React.useMemo(() => {
    const totalBeforeDiscount = unitPriceNoTax * quantity
    const discountAmount = (totalBeforeDiscount * discountPercentage) / 100
    const totalNoTax = totalBeforeDiscount - discountAmount
    return totalNoTax
  }, [discountPercentage, quantity, unitPriceNoTax])
  const discountAmount = React.useMemo(
    () => (totalPrice * discountPercentage) / 100,
    [totalPrice, discountPercentage]
  )
  const check = React.useMemo(() => {
    return {
      line: lineIsValid(line),
      name: S.isNotEmpty(line.name),
      reference: S.isNotEmpty(line.reference),
    }
  }, [line])
  return (
    <div className="grid">
      <div className="grid grid-cols-3 gap-4 w-full bg-card py-4 pl-6 pr-16" {...cardProps}>
        <div className="flex flex-col col-span-2">
          <Row.Header>
            <Row.Title>
              <span className="relative z-10">{name}</span>
              {!check.line && (
                <Popover>
                  <Popover.Trigger className="relative z-10 flex justify-center items-center size-4 pl-2 rounded-full">
                    <Dot
                      valid={check.line}
                      tooltip={
                        check.line
                          ? "Product is valid"
                          : "Product is invalid click to display details"
                      }
                    />
                  </Popover.Trigger>
                  <Popover.Content className="w-full w-xs pt-1" side="right" align="start">
                    <ListLeftRight>
                      <ListItem label="Name">
                        <Dot valid={check.name} />
                      </ListItem>
                      <ListItem label="Reference">
                        <Dot valid={check.reference} />
                      </ListItem>
                    </ListLeftRight>
                  </Popover.Content>
                </Popover>
              )}
            </Row.Title>
            <Row.Description>{description}</Row.Description>
          </Row.Header>
          <div className="flex items-end pt-4 gap-4">
            <Badge variant="outline" aria-label="Quantity" className="w-20">
              {quantity}
            </Badge>
            <Badge aria-label="Unit code">
              {code?.value} {code?.title}
            </Badge>
            <Badge variant="outline">Unit price: {toRoudedString(unitPriceNoTax)} €</Badge>
            {discountPercentage > 0 && (
              <Badge variant="outline">
                <BadgePercent size={12} /> {discountPercentage} % - {toRoudedString(discountAmount)}{" "}
                €
              </Badge>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-4 justify-end">
            <Badge variant="outline" aria-label="VAT">
              {vat} %
            </Badge>
            <Badge variant="outline" aria-label="Refference">
              # {reference}
            </Badge>
          </div>
          <div className="flex grow justify-end items-end">{toRoudedString(totalPrice)} €</div>
        </div>
        <Spotlight {...cardSpotlightProps} />
      </div>
    </div>
  )
}

/**
 * LineContextMenu
 */
const LineContextMenu: React.FC<{ line: InvoiceLine }> = ({ line }) => {
  const { type } = useMenu()
  const ctx = usePageContext()
  return (
    <>
      <Menu.Item onClick={() => ctx.editLine(line)}>
        <SquarePen />
        Edit product details
      </Menu.Item>
      <Menu.Item onClick={() => ctx.confirmDeleteLine(line)}>
        <Trash />
        Delete product
      </Menu.Item>
      {type === "context-menu" && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={() => ctx.createLine(line.invoiceId)}>
            <SquarePlus aria-hidden />
            Create a new product
          </Menu.Item>
        </>
      )}
    </>
  )
}
