import { useEventListener } from "./useEventListener"

/**
 * Easily retrieve window dimensions with this Hook React which also works onRezise
 */
export const useDocumentSize = () => {
  const [windowSize, setWindowSize] = React.useState<{
    width: number
    height: number
  }>({
    width: 0,
    height: 0,
  })

  const handleSize = () => {
    setWindowSize({
      width: document.documentElement.scrollWidth,
      height: document.documentElement.scrollHeight,
    })
  }

  useEventListener("resize", handleSize)

  // Set size at the first client-side load
  React.useLayoutEffect(() => {
    handleSize()
  }, [])

  return windowSize
}
