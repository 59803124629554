/**
 * MenuContext
 */
export type MenuContextType = {
  type: "context-menu" | "dropdown-menu"
}
export const MenuContext = React.createContext<MenuContextType>({ type: "context-menu" })

/**
 * useMenu
 */
export const useMenu = () => {
  const context = React.useContext(MenuContext)
  return context
}
