import { tcf } from "tcts"

/**
 * ActionResult
 */
export type ActionResult<
  S extends { [key: string]: unknown } | void = void,
  E extends { [key: string]: unknown } | void = void
> = Promise<
  S extends void
    ? { error: false } | (E extends void ? { error: true } : { error: true } & E)
    : ({ error: false } & S) | (E extends void ? { error: true } : { error: true } & E)
>

/**
 * tcResult
 */
export const tcResult = tcf<{ error: true; code: "OTHER" }>({
  error: true,
  code: "OTHER",
})

/**
 * resetAllStores
 */

export const resetAllStores = () => {
  localStorage.clear()
  sessionStorage.clear()
}
export const resetAllStoresAndReload = () => {
  localStorage.clear()
  sessionStorage.clear()
  window.location.reload()
}
