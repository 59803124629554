import { Form, FormAssertive, FormSubmit, useForm } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { formatError } from "@/fns/format-error"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { updateInvoiceLine } from "@/store/invoices/actions"
import { InvoiceLine } from "@/store/invoices/localizers"
import { match } from "ts-pattern"
import { InvoiceLineForm, invoiceLineValidation } from "./InvoiceForm"

/**
 * EditLineDialog
 */
export const EditLineDialog: React.FC<UseDialogProps<InvoiceLine>> = ({ item, onOpenChange, open }) => {
  return (
    <Dialog {...{ open, onOpenChange }} title='Edit product' className='max-w-2xl'>
      {item !== false && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}

const DialogForm: React.FC<UseDialogFormProps<InvoiceLine>> = ({ item, onOpenChange }) => {
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      ...item,
    })),
    validate: invoiceLineValidation,
    onSubmit: async ({ values }) => {
      if (!form.isValid && !form.attemptedSubmit) return formatError("VALIDATION_FAILURE")
      return match(await updateInvoiceLine(item.invoiceId, item.id, values))
        .with({ error: false }, () => {
          toast.success("Product updated")
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", formatError)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .with("FETCH_ERROR", (code) => toast.error(formatError(code)))
        )
    },
  })

  return (
    <Form form={form} className='grid gap-6'>
      <FormAssertive />
      <InvoiceLineForm />
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant='secondary'>Back</Button>
        </Dialog.Close>
        <FormSubmit>{!form.isSubmitting && form.attemptedSubmit ? "Update despite errors" : "Update"}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
