import { Menu, Row } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { en } from "@/fns/date-fns"
import { getComputedTotalWithTax, invoiceIsValid } from "@/fns/invoice"
import { toRoudedString } from "@/fns/math"
import { capitalize, textPlaceholder, truncateMiddle } from "@/fns/string"
import { customerIsValid } from "@/store/customers/helpers"
import { useCustomer } from "@/store/customers/hooks"
import { Invoice } from "@/store/invoices/localizers"
import {
  CalendarClock,
  CloudUpload,
  FileClock,
  FileWarning,
  ReceiptText,
  ShoppingCart,
  TriangleAlert,
} from "lucide-react"
import { ContextMenu } from "./ContextMenu"

type Props = {
  invoice: Invoice
}

/**
 * Item
 */
export const Item: React.FC<Props> = props => {
  const { invoice } = props
  invoice.status === "submitted"
  const customer = useCustomer(invoice.customerId)
  const totalNoTax = React.useMemo(
    () => getComputedTotalWithTax(invoice.invoiceLines),
    [invoice.invoiceLines]
  )
  const isValid = invoiceIsValid(invoice, customer ? [customer] : [])
  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <div className="grid" onDoubleClick={() => navigate(`/dashboard/invoices/${invoice.id}`)}>
        <Row>
          <Row.Image>
            <ReceiptText size={16} />
          </Row.Image>
          <Row.Header>
            <Row.Title className="flex gap-2 items-center">
              {customer ? (
                <>
                  {textPlaceholder(truncateMiddle(customer?.name ?? "", 70), "Unnamed customer")}
                  {!customerIsValid(customer) && (
                    <Badge
                      variant="ghost"
                      icon
                      aria-label="Invalid customer"
                      className="text-amber-600"
                    >
                      <TriangleAlert />
                    </Badge>
                  )}
                </>
              ) : (
                "No customer set"
              )}
            </Row.Title>
            <Row.Description className="line-clamp-1 max-w-sm">{invoice.note}</Row.Description>
            <div className="flex gap-2 pt-1">
              {invoice.dueDate && (
                <Badge variant="outline" aria-label="Due date">
                  <CalendarClock size={12} /> {T.format(invoice.dueDate, "P", { locale: en })}
                </Badge>
              )}
              <Badge variant="outline" aria-label="Number of lines">
                <ShoppingCart size={12} /> {invoice.invoiceLines.length}
              </Badge>
              <Badge variant="outline" aria-label="Total no tax">
                {toRoudedString(totalNoTax)} €
              </Badge>
            </div>
          </Row.Header>
          <Row.Content className="flex gap-2">
            {invoice.type === "credit" && <Badge variant="success">Credit</Badge>}
            {isValid && invoice.status}
            <Badge variant="secondary"># {invoice.invoiceReference.replaceAll("ndc", "")}</Badge>
            {invoice.status === "submitted" ? (
              <Row.Icon tooltip={capitalize(invoice.status)} className="text-green-600">
                <CloudUpload />
              </Row.Icon>
            ) : isValid ? (
              <Row.Icon tooltip={capitalize(invoice.status)} className="text-blue-600">
                <FileClock />
              </Row.Icon>
            ) : (
              <Row.Icon tooltip={capitalize(invoice.status)} className="text-amber-600">
                <FileWarning />
              </Row.Icon>
            )}
          </Row.Content>
          <Row.Menu menu={<ContextMenu {...props} />} />
        </Row>
      </div>
    </Menu>
  )
}
