import { BreadcrumbLoader } from "@/components/collection"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout"
import { useDropZone } from "@/hooks/useDropZone"
import { usePromise } from "@/hooks/usePromise"
import { getCustomers } from "@/store/customers/actions"
import { getInvoices } from "@/store/invoices/actions"
import { FileDown } from "lucide-react"
import { Collection } from "./Collection"
import { ContextProvider, usePageContext } from "./Context"

/**
 * Invoices
 */
const Invoices: React.FC = () => {
  const [, isLoading] = usePromise(getInvoices)
  usePromise(getCustomers)
  const { upload } = usePageContext()
  const { dragOver, bindDropZone } = useDropZone({
    accept: ["txt"],
    multiple: false,
    onDropFiles: files => {
      upload(files)
    },
  })
  return (
    <PageWrapper {...bindDropZone} className="relative">
      {dragOver && (
        <div className="fixed z-50 inset-0 p-4">
          <div className=" flex justify-center items-center gap-4 size-full rounded-md border border-background border-dashed bg-foreground/25 text-background text-4xl font-medium">
            <FileDown size={48} />
            Drop your file here
          </div>
        </div>
      )}
      <PageHeader
        breadcrumbs={[
          [<BreadcrumbLoader name="Invoices" isLoading={isLoading} />, "/dashboard/invoices"],
        ]}
      />
      <PageContent>
        <Collection />
      </PageContent>
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <Invoices />
  </ContextProvider>
)
