import { SrOnly } from "@/components//ui/sr-only"
import { Button } from "@/components/ui/button"
import { Popover } from "@/components/ui/popover"
import globalConfig, { acceptedFileExtensions } from "@/config/global"
import { humanFileSize } from "@/fns/human"
import { makeBreakable } from "@/fns/string"
import {
  acceptToInputAccept,
  checkExtFromFile,
  formatExtList,
  getExtFromFile,
  getSizeFromFile,
  useDropZone,
} from "@/hooks/useDropZone"
import { saveAs } from "file-saver"
import { Download, X } from "lucide-react"
import selectFiles from "select-files-capture"
import { Form, FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "."
import { AdaptHeight } from "../ui/adapt-height"
import { extractInputProps, extractWrapperProps } from "./field-wrapper"

/**
 * FormFiles
 */
type Props = FormInputFilesProps & FormFieldWrapperProps
export const FormFiles = React.forwardRef<HTMLButtonElement, Props>(({ ...props }, ref) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <FormInputFiles {...extractInputProps(props)} ref={ref} />
  </FormFieldWrapper>
))

/**
 * FormInputFiles
 */
type FormInputFilesProps = Omit<React.ComponentProps<typeof Form.Input>, "accept"> & {
  accept?: string[]
  min?: number
  max?: number
  maxUploadFile?: number
  multiple?: boolean
}
export const FormInputFiles = React.forwardRef<HTMLButtonElement, FormInputFilesProps>(
  (
    {
      accept = acceptedFileExtensions,
      min = 0,
      max = Infinity,
      maxUploadFile = globalConfig.maxUploadFile,
      multiple = true,
      className,
    },
    ref
  ) => {
    const { setFieldValue, value, id } = useFieldContext<FormFileType[]>()

    // manage file picker and drop zone
    const onDropFiles = (files: File[]) => {
      if (A.isNotEmpty(files))
        multiple ? setFieldValue([...value, ...A.take(files, max - filesCount)]) : setFieldValue(A.take(files, 1))
    }
    const onError = (code: "TOOLARGE" | "UNACCEPTED") => {
      if (code === "TOOLARGE") toast.error(`File is too large`)
      if (code === "UNACCEPTED") toast.error(`File extension is not accepted`)
    }
    const onClickDropZone = async () => {
      const fileList = await selectFiles({ accept: acceptToInputAccept(accept), multiple })
      const files = fileList ? Array.from(fileList) : []
      if (!A.some(files, (file) => min <= getSizeFromFile(file) && max >= getSizeFromFile(file)))
        return onError("TOOLARGE")
      if (!A.some(files, (file) => checkExtFromFile(file, accept))) return onError("UNACCEPTED")
      onDropFiles(files)
    }
    const { bindDropZone, dragOver } = useDropZone({
      accept,
      min,
      max,
      multiple,
      onDropFiles,
      onError,
    })

    // manage files
    const removeFile = (index: number) => {
      const current = A.getUnsafe(value, index)
      if (isSynteticFile(current)) {
        return setFieldValue(A.replaceAt(value, index, { ...current, delete: true }))
      }
      setFieldValue(A.removeAt(value, index))
    }

    const filesCount = React.useMemo(
      () => A.filter(value, (file) => isFile(file) || file.delete === false).length,
      [value]
    )
    return (
      <div className='flex flex-col gap-2'>
        {(multiple ? filesCount < max : filesCount === 0) && (
          <div
            className={cxm(
              "relative flex justify-center items-center w-full rounded-md",
              "border border-input border-dashed focus-within:border-orient transition-colors",
              dragOver ? "bg-primary/5 border-primary" : "bg-card",
              className
            )}
            {...bindDropZone}
          >
            <DropInner ref={ref} {...{ id, accept, min, max, multiple, maxUploadFile, onClickDropZone }} />
          </div>
        )}
        <FilesList files={value} removeFile={removeFile} />
      </div>
    )
  }
)

/**
 * DropInner
 */
type DropInnerProps = {
  id: string
  accept: string[]
  min?: number
  max: number
  maxUploadFile: number
  multiple: boolean
  onClickDropZone: React.MouseEventHandler<HTMLButtonElement>
}
const DropInner = React.forwardRef<HTMLButtonElement, DropInnerProps>(
  ({ id, onClickDropZone, max, multiple, maxUploadFile, accept }, ref) => {
    const acceptedExtensions = React.useMemo(() => formatExtList(accept), [accept])
    const isSingle = max === 1 || !multiple
    return (
      <div className='flex flex-col justify-center items-center min-h-[10rem] p-4 gap-1'>
        <p className='text-base'>
          Drop your {isSingle ? "file" : "files"} here or
          <Button
            variant='link'
            size='link'
            id={id}
            onClick={onClickDropZone}
            ref={ref}
            className='text-base underline'
          >
            select {isSingle ? "a file" : "files"}.
          </Button>
        </p>
        <p className='text-xs text-muted-foreground'>
          ({Number.isFinite(max) && `max file: ${max},`} {`max size by file: ${humanFileSize(maxUploadFile)}`},
          <Popover>
            <Popover.Trigger asChild>
              <Button variant='link' size='link' className='text-xs underline'>
                list of accepted file types
              </Button>
            </Popover.Trigger>
            <Popover.Content>
              <p className='text-xs'>
                <span className='inline-block pb-1 font-bold'>List of accepted extensions :</span>
                <br />
                {A.join(acceptedExtensions, ", ")}
              </p>
            </Popover.Content>
          </Popover>
          )
        </p>
      </div>
    )
  }
)

/**
 * FilesList
 */
type FilesListProps = {
  files: FormFileType[]
  removeFile: (index: number) => void
}
const FilesList: React.FC<FilesListProps> = ({ files, removeFile }) => {
  return A.isNotEmpty(files) ? (
    <AdaptHeight overflowVisible>
      <div className='flex flex-col gap-2'>
        {A.mapWithIndex(files, (index, formFile) => (
          <FilesItem formFile={formFile} remove={() => removeFile(index)} key={index} />
        ))}
      </div>
    </AdaptHeight>
  ) : null
}

/**
 * FilesList
 * dictionary src/dictionaries/en/components/form/form-files.json
 */
type FilesItemProps = {
  formFile: FormFileType
  remove: React.MouseEventHandler<HTMLButtonElement>
}
const FilesItem: React.FC<FilesItemProps> = ({ formFile, remove }) => {
  const file = normalizeFormFile(formFile)
  return file.delete === false ? (
    <div className='flex justify-between items-center gap-1 w-full px-4 py-2 border border-input rounded-md'>
      <div className='flex flex-col gap-y-1'>
        <p className='text-sm font-medium'>
          {/* {file.name} */}
          {makeBreakable(file.name)}
        </p>
        <p className='flex items-center text-xs text-muted-foreground'>
          {humanFileSize(file.size)}
          {isSynteticFile(formFile) && (
            <Button variant='link' size='link' onClick={() => saveAs(file.url, file.name)}>
              <Download aria-hidden size={12} />
              <SrOnly>Download {file.name}</SrOnly>
            </Button>
          )}
        </p>
      </div>
      <Button variant='ghost' size='xs' icon onClick={remove}>
        <X aria-hidden />
        <SrOnly>Remove {file.name}</SrOnly>
      </Button>
    </div>
  ) : null
}

/**
 * helpers
 */
export const isSynteticFile = (file: FormFileType): file is SynteticFile => {
  return G.isNotNullable((file as SynteticFile).delete)
}
export const isFile = (file: FormFileType): file is File => {
  return !isSynteticFile(file)
}
export const normalizeFormFile = (file: FormFileType): NormalizedFile => {
  if (isSynteticFile(file)) return { ...file }
  return {
    name: file.name,
    extension: getExtFromFile(file) ?? "",
    size: file.size,
    url: URL.createObjectURL(file),
    delete: false,
  }
}
export const initialFiles = (
  files: {
    id: string
    name: string
    url: string
    size: number
    extension: string
  }[]
): FormFileType[] =>
  A.map(files, (file) => ({
    ...D.selectKeys(file, ["id", "name", "extension", "size", "url"]),
    delete: false,
  }))

/**
 * types
 */
export type FormFileType = File | SynteticFile
type SynteticFile = {
  id: string
  name: string
  extension: string
  size: number
  url: string
  delete: boolean
}
type NormalizedFile = {
  name: string
  extension: string
  size: number
  url: string
  delete: boolean
}
