import * as TogglePrimitive from "@radix-ui/react-toggle"
import { cva, type VariantProps } from "class-variance-authority"

const toggleVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors hover:bg-muted hover:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-accent data-[state=on]:text-accent-foreground",
  {
    variants: {
      variant: {
        default: "bg-transparent",
        outline: "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground",
      },
      size: {
        default: "h-10",
        sm: "h-9",
        lg: "h-11",
        xs: "h-8 text-xs",
        xxs: "h-6 text-xs",
      },
      icon: {
        false: "gap-0",
        true: "gap-2",
      },
    },
    compoundVariants: [
      { size: "default", icon: false, class: "px-4 py-2 gap-2" },
      { size: "default", icon: true, class: "w-10 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "lg", icon: false, class: "px-8 py-2 gap-4" },
      { size: "lg", icon: true, class: "w-11 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "sm", icon: false, class: "px-3 py-2 gap-1.5" },
      { size: "sm", icon: true, class: "w-9 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "xs", icon: false, class: "px-2 gap-1" },
      { size: "xs", icon: true, class: "w-8 [&>svg]:w-3 [&>svg]:h-3 shrink-0" },
      { size: "xxs", icon: false, class: "px-2 gap-1" },
      { size: "xxs", icon: true, class: "w-6 [&>svg]:w-3 [&>svg]:h-3 shrink-0" },
    ],
    defaultVariants: {
      variant: "default",
      size: "default",
      icon: false,
    },
  }
)

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, size, icon, ...props }, ref) => (
  <TogglePrimitive.Root ref={ref} className={cxm(toggleVariants({ variant, size, icon, className }))} {...props} />
))

Toggle.displayName = TogglePrimitive.Root.displayName

export { Toggle, toggleVariants }
