import * as math from "mathjs"

/**
 * round
 * if the third digit after the decimal point is equal to or greater than 5,
 * round up to the nearest cent; if the third digit after the decimal point
 * is less than 5, round down to the nearest cent.
 */

export const round = (amount: number): number => {
  return math.chain(amount).format({ precision: 14 }).evaluate().round(2).done()

  //return math.round(amount + 0.000001, 2)

  // return Math.round((amount + 0.000001) * 100) / 100 //Number.EPSILON
  // return Number(amount.toFixed(2))
}
export const roundM = (amount: number): number => {
  return math
    .chain(amount - 0.000001)
    .format({ precision: 12 })
    .evaluate()
    .round(2)
    .done()

  //return math.round(amount + 0.000001, 2)

  // return Math.round((amount + 0.000001) * 100) / 100 //Number.EPSILON
  // return Number(amount.toFixed(2))
}
export const toRoudedString = (amount: number): string => {
  return round(amount).toFixed(2)
}
export const toRoudedFixed = (amount: number): number => {
  return +(round(amount)).toFixed(2)
}

// 4274.6 * 3 / 100
// 1897.5 * 3 / 100

// 427460 * 3 / 100
// 189750 * 3 / 100
