type CopiedValue = string | ClipboardItems | null
type CopyFn = (text: string | ClipboardItems) => Promise<boolean> // Return success

/**
 * useCopyToClipboard
 */
export const useCopyToClipboard = (delay = 1000): [CopiedValue, CopyFn, boolean] => {
  const [copiedText, setCopiedText] = React.useState<CopiedValue>(null)
  const [alert, setAlert] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (!alert) return
    const to = setTimeout(() => setAlert(false), delay)
    return () => clearTimeout(to)
  }, [alert, delay])
  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) return false
    try {
      if (typeof text === "string") await navigator.clipboard.writeText(text)
      else navigator.clipboard.write(text)
      setCopiedText(text)
      setAlert(true)
      return true
    } catch (error) {
      setCopiedText(null)
      return false
    }
  }
  return [copiedText, copy, alert]
}
