import { makePathToApi } from "@/fns/path"
import {
  ApiAttachment,
  ApiInvoice,
  ApiInvoiceBankAccount,
  ApiInvoiceLine,
} from "@/services/invoices/schemas"

/**
 * localizeInvoice
 */
export const localizeInvoice = ({
  date,
  dueDate,
  createdAt,
  updatedAt,
  attachment,
  bankAccounts,
  ...parsed
}: ApiInvoice) => ({
  ...D.selectKeys(parsed, [
    "id",
    "status",
    "type",
    "dueDateText",
    "invoiceReference",
    "senderProjectReference",
    "customerProjectReference",
    "note",
    "prepaidAmount",
  ]),
  customerId: parsed.customerId ?? "",
  date: date ? T.parseISO(date) : null,
  dueDate: dueDate ? T.parseISO(dueDate) : null,
  attachment: localizeAttachment(attachment),
  bankAccounts: A.map(bankAccounts, localizeInvoiceBankAccount),
  createdAt: T.parseISO(createdAt),
  updatedAt: T.parseISO(updatedAt),
  invoiceLines: A.map(parsed.invoiceLines, line => localizeInvoiceLine(line, parsed.id)),
})
export type Invoice = ReturnType<typeof localizeInvoice>

/**
 * localizeInvoiceBankAccount
 */
export const localizeInvoiceBankAccount = (parsed: ApiInvoiceBankAccount) =>
  D.selectKeys(parsed, ["bic", "iban"])
export type InvoiceBankAccount = ReturnType<typeof localizeInvoiceBankAccount>

/**
 * localizeAttachment
 */
export const localizeAttachment = (parsed: ApiAttachment) => {
  if (parsed.file === null) return null
  return {
    ...D.selectKeys(parsed, ["name", "size", "extension"]),
    id: "n",
    url: makePathToApi(parsed.path),
  }
}
export type Attachment = ReturnType<typeof localizeAttachment>

/**
 * localizeInvoiceLine
 */
export const localizeInvoiceLine = (parsed: ApiInvoiceLine, invoiceId: string) => ({
  invoiceId,
  ...D.selectKeys(parsed, [
    "id",
    "position",
    "quantity",
    "unitCode",
    "reference",
    "name",
    "description",
    "unitPriceNoTax",
    "vat",
    "vatCode",
    "discountPercentage",
  ]),
})
export type InvoiceLine = ReturnType<typeof localizeInvoiceLine>
