import { byId } from "@/fns/collection"
import * as ErrorLogs from "@/services/error-logs/service"
import { match } from "ts-pattern"
import { errorLogsStore } from "."
import { localizeErrorLog } from "./localizers"

/**
 * getErrorLogs
 */
export const getErrorLogs = async () =>
  match(await ErrorLogs.service.index())
    .with({ error: false }, ({ data }) => {
      errorLogsStore.evolve({
        errorLogs: byId(data.errorLogs, localizeErrorLog),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getErrorLog
 */
export const getErrorLog = async (id: string) =>
  match(await ErrorLogs.service.read(id))
    .with({ error: false }, ({ data }) => {
      errorLogsStore.evolve({
        errorLogs: D.set(data.errorLog.id, localizeErrorLog(data.errorLog)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteErrorLog
 */
export const deleteErrorLog = async (id: string) =>
  match(await ErrorLogs.service.delete(id))
    .with({ error: false }, () => {
      errorLogsStore.evolve({ errorLogs: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
