import * as AvatarPrimitive from "@radix-ui/react-avatar"

/**
 * ImageRoot
 */
const ImageRoot = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root ref={ref} className={cxm("shrink-0 overflow-hidden", className)} {...props} />
))

/**
 * ImageSource
 */
const ImageSource = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => <AvatarPrimitive.Image ref={ref} className={cxm("", className)} {...props} />)

/**
 * ImageFallback
 */
const ImageFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cxm("flex items-center justify-center bg-muted", className)}
    {...props}
  />
))

/**
 * ComponentName
 */
export type ImageProps = Extend<
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>,
  {
    children?: React.ReactNode
    src?: Option<string>
    wrapperCx?: string
  }
>
export type ImageRef = React.ElementRef<typeof AvatarPrimitive.Image>
export const Image = React.forwardRef<ImageRef, ImageProps>(
  ({ children, wrapperCx, className, src, ...props }, ref) => (
    <ImageRoot className={wrapperCx}>
      {G.isNotNullable(src) && <ImageSource ref={ref} {...props} src={src} className={cxm(className)} />}
      <ImageFallback className={cxm(className)}>{children}</ImageFallback>
    </ImageRoot>
  )
)
