import { z } from "zod"
import { ErrorCode, NoContent, NoError, api, removeSessionToken, setSessionToken } from "../api"
import { ApiMe, apiMe } from "./schemas"

/**
 * schemas
 */
const signInPayload = z.object({
  email: z.string(),
  password: z.string(),
})
export type Payload = {
  signIn: z.infer<typeof signInPayload>
}

/**
 * service
 */
export const service = {
  session: async () => {
    type RSuccess = { session: true; user: ApiMe } | { session: false }
    type RError = NoError
    const { success, data } = await api.get<RSuccess, RError>("authentication/session")
    if (!success) return { data: null, error: true, code: data.code } as const
    if (data.session) return { data: { session: true, user: apiMe.parse(data.user) }, error: false } as const
    return { data: { session: false }, error: false } as const
  },
  signIn: async (payload: Payload["signIn"]) => {
    type RSuccess = { user: ApiMe; token: string }
    type RError = ErrorCode<"INVALID_CREDENTIALS" | "ACCOUNT_NOT_ACTIVE" | "VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("authentication/sign-in", {
      data: signInPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    setSessionToken(data.token)
    return { data: { user: apiMe.parse(data.user) }, error: false } as const
  },
  signOut: async () => {
    type RSuccess = NoContent
    type RError = NoError
    const { success, data } = await api.get<RSuccess, RError>("authentication/sign-out")
    if (!success) return { data: null, error: true, code: data.code } as const
    removeSessionToken()
    return { data: {}, error: false } as const
  },
}
