import { z } from "zod"

/**
 * ErrorLog
 */
export const apiErrorLog = z.object({
  id: z.string(),
  parsedJson: z.string(),
  invoice: z.string(),
  beinvoice: z.string(),
  createdAt: z.string(),
})
export type ApiErrorLog = z.infer<typeof apiErrorLog>
