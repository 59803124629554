import { BreadcrumbLoader } from "@/components/collection/loader"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout"
import { usePromise } from "@/hooks/usePromise"
import { getCustomers } from "@/store/customers/actions"
import Collection from "./Collections"
import { ContextProvider } from "./Context"

/**
 * Dashboard
 */
const Dashboard: React.FC = () => {
  const [, isLoading] = usePromise(getCustomers)
  return (
    <ContextProvider>
      <PageWrapper>
        <PageHeader
          breadcrumbs={[[<BreadcrumbLoader name='Customers' isLoading={isLoading} />, "/dashboard/customers"]]}
        />
        <PageContent>
          <Collection />
        </PageContent>
      </PageWrapper>
    </ContextProvider>
  )
}
export default Dashboard
