import { Tooltip } from "./tooltip"

/**
 * dot
 */
export const Dot: React.FC<{ valid: boolean; tooltip?: string }> = ({ valid, tooltip }) => {
  return (
    <Tooltip content={tooltip}>
      <span className={`size-1.5 rounded-full inline-block ${valid ? "bg-green-600" : "bg-red-600"}`} />
    </Tooltip>
  )
}
