/**
 * useLimitable
 */
export const useLimitable = <T extends Record<string, unknown>>(
  length: number,
  initialLimit = 24,
  initialByMore = 12
) => {
  const [limit, setLimit] = React.useState(initialLimit)

  const takeMore = () => setLimit(limit => limit + initialByMore)

  const reached = React.useMemo(() => limit < length, [limit, length])

  return [{ limit, setLimit, takeMore, reached }, (items: T[]) => A.take(items, limit)] as const
}

/**
 * Limitable
 */
export type Limitable = ReturnType<typeof useLimitable<Record<string, unknown>>>[0]
