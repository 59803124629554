import { Card, Menu, PageLoader } from "@/components/collection"
import { ListItem, ListLeftRight } from "@/components/collection/list"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout"
import { Dot } from "@/components/ui/dot"
import { Popover } from "@/components/ui/popover"
import { attachmentIsValid, datesAreValid, detailsAreValid, invoiceIsValid, linesAreValid } from "@/fns/invoice"
import { usePromise } from "@/hooks/usePromise"
import { getCustomers } from "@/store/customers/actions"
import { customerIsValid } from "@/store/customers/helpers"
import { useCustomer } from "@/store/customers/hooks"
import { getInvoice } from "@/store/invoices/actions"
import { useInvoice } from "@/store/invoices/hooks"
import { Invoice } from "@/store/invoices/localizers"
import { Redirect } from "wouter"
import { ContextProvider as CustomerContextProvider } from "../../customers/Context"
import { ContextProvider } from "../Context"
import { AttachmentCard } from "./AttachmentCard"
import { ContextMenu } from "./ContextMenu"
import { CustomerCard } from "./CustomerCard"
import { InvoiceCard } from "./InvoiceCard"
import { Lines } from "./Lines"
import { PaymentCard } from "./PaymentCard"

/**
 * Invoice
 */
export type Props = { invoice: Invoice }
const InvoicePage: React.FC<Props> = (props) => {
  const { invoice } = props
  return (
    <PageWrapper>
      <PageHeader
        breadcrumbs={[
          ["Invoices", "/dashboard/invoices"],
          [`# ${invoice.invoiceReference}`, `/dashboard/invoices/${invoice.id}}`],
        ]}
      />
      <PageContent className='flex flex-col gap-4'>
        <Menu type='context-menu' menu={<ContextMenu {...props} />} asChild>
          <div className='flex flex-col gap-4 w-full max-w-screen-2xl mx-auto'>
            <SectionHeader {...props} />
            <SectionDetails {...props} />
          </div>
        </Menu>
        <Lines {...props} />
      </PageContent>
    </PageWrapper>
  )
}

/**
 * page loader
 */
type PageProps = { id: string }
export default ({ id }: PageProps) => {
  const invoice = useInvoice(id)
  const [result, inProgress] = usePromise(() => getInvoice(id))
  usePromise(getCustomers)
  if (inProgress) return <PageLoader breadcrumbs={[["Invoices", "/dashboard/invoices"]]} />
  if (!result || result.error || G.isNullable(invoice)) return <Redirect to='/dashboard/invoices' />

  return (
    <ContextProvider>
      <CustomerContextProvider>
        <InvoicePage invoice={invoice} />
      </CustomerContextProvider>
    </ContextProvider>
  )
}

/**
 * SectionHeader
 */
const SectionHeader: React.FC<Props> = (props) => {
  const { invoice } = props
  const customer = useCustomer(invoice.customerId)
  const check = React.useMemo(() => {
    return {
      invoice: invoiceIsValid(invoice, customer ? [customer] : []),
      details: detailsAreValid(invoice),
      customer: customerIsValid(customer),
      dates: datesAreValid(invoice),
      attachment: attachmentIsValid(invoice),
      lines: linesAreValid(invoice),
    }
  }, [invoice, customer])

  return (
    <Card>
      <Card.Header>
        <Card.Title className='flex items-center gap-2'>
          <span># {invoice.invoiceReference}</span>
          <Popover>
            <Popover.Trigger className='flex justify-center items-center size-4 rounded-full'>
              <Dot
                valid={check.invoice}
                tooltip={check.invoice ? "Invoice is valid" : "Invoice is invalid click to display details"}
              />
            </Popover.Trigger>
            <Popover.Content className='w-xs pt-1' side='right' align='start'>
              <ListLeftRight>
                <ListItem label='Details'>
                  <Dot valid={check.details} />
                </ListItem>
                <ListItem label='Customer'>
                  <Dot valid={check.customer} />
                </ListItem>
                <ListItem label='Dates'>
                  <Dot valid={check.dates} />
                </ListItem>
                <ListItem label='Attachment'>
                  <Dot valid={check.attachment} />
                </ListItem>
                <ListItem label='Lines'>
                  <Dot valid={check.lines} />
                </ListItem>
              </ListLeftRight>
            </Popover.Content>
          </Popover>
        </Card.Title>
        <Card.Menu menu={<ContextMenu {...props} />} />
      </Card.Header>
    </Card>
  )
}

/**
 * SectionDetails
 */
const SectionDetails: React.FC<Props> = (props) => {
  const { invoice } = props
  const customer = useCustomer(invoice.customerId)

  return (
    <div className='grid grid-cols-3 gap-4'>
      <div className='grid grid-cols-2 grid-rows-[auto_1fr] col-span-2 gap-4'>
        {customer && <CustomerCard customer={customer} className='col-span-2' />}
        <InvoiceCard {...props} />
        <PaymentCard {...props} />
      </div>

      <AttachmentCard {...props} />
    </div>
  )
}
