import { Customer } from "./localizers"

/**
 * customerIsValid
 */
export const customerIsValid = (customer: Option<Customer>) => {
  if (G.isNullable(customer)) return false
  return pipe(
    customer,
    D.selectKeys(["name", "address1", "city", "postalCode", "country", "peppolId", "reference", "vat"]),
    D.values,
    A.every((field) => G.isString(field) && S.isNotEmpty(field))
  )
}
