import { usePromise } from "@/hooks/usePromise"
import { useUuid } from "@/hooks/useUuid"
import { useAuthStore } from "."
import { initAuthStore } from "./actions"

/**
 * useInitAuthStore
 */
export const useInitAuthStore = () => {
  const done = useAuthStore(D.prop("initDone"))
  const sid = useUuid()
  const [response, loading] = usePromise(() => initAuthStore(sid))
  return [loading, done, response] as const
}

/**
 * Hooks
 */
export const useMe = () => {
  const me = useAuthStore(D.prop("user"))
  if (G.isNullable(me)) throw new Error("never")
  return me
}
export const useAuth = () => {
  const authState = useAuthStore((state) => state)
  return { ...authState, authenticated: G.isObject(authState.user) }
}
