import { Toolbar } from "@/components/collection"
import { useFilteredInvoices } from "@/store/invoices/hooks"
import { CloudOff, CloudUpload, FileClock, FileWarning, FilterX } from "lucide-react"

/**
 * Filters
 */
export const Filters: React.FC<ReturnType<typeof useFilteredInvoices>["filterable"]> = ({
  toggleActive,
  toggleInactive,
  isActive,
  isInactive,
  setTrue,
  unset,
  reset,
}) => {
  type Status = "notSubmitted" | "submitted" | "pending" | "invalid"
  const toggleStatus = (status: Status) =>
    A.forEach(["notSubmitted", "submitted", "pending", "invalid"], (c) =>
      c === status ? (isActive(c) ? unset(c) : setTrue(c)) : unset(c)
    )

  return (
    <Toolbar.Filters>
      <Toolbar.Filters.Item onClick={() => toggleStatus("notSubmitted")} active={isActive("notSubmitted")}>
        <CloudOff aria-hidden />
        Not submited
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleStatus("submitted")} active={isActive("submitted")}>
        <CloudUpload aria-hidden />
        Submitted
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleStatus("pending")} active={isActive("pending")}>
        <FileClock aria-hidden />
        Pending
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleStatus("invalid")} active={isActive("invalid")}>
        <FileWarning aria-hidden />
        Invalid
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={() => toggleInactive("credit")} active={isInactive("credit")}>
        Debit rating
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleActive("credit")} active={isActive("credit")}>
        Credit rating
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={reset}>
        <FilterX aria-hidden />
        Reset all filter
      </Toolbar.Filters.Item>
    </Toolbar.Filters>
  )
}
