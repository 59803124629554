import { decorateStore } from "@/fns/zustand"
import { create } from "zustand"
import { Customer } from "./localizers"

/**
 * types
 */
export type CustomersStoreState = {
  customers: ById<Customer>
}

/**
 * initialState
 */
const initialState: CustomersStoreState = {
  customers: {},
}

/**
 * store
 */
export const customersStore = decorateStore(initialState, create, {})
export const useCustomersStore = customersStore.use
