import { z } from "zod"

/**
 * VatCode
 */
export const vatCode = z.enum(["AE", "E", "S", "Z", "G", "O", "K", "L", "M", "B"])
export type VatCode = z.infer<typeof vatCode>

/**
 * InvoiceLine
 */
export const apiInvoiceLine = z.object({
  id: z.string().uuid(),
  position: z.number(),
  quantity: z.number(),
  unitCode: z.string(),
  reference: z.string(),
  name: z.string(),
  description: z.string(),
  unitPriceNoTax: z.number(),
  vat: z.number(),
  vatCode: vatCode,
  discountPercentage: z.number(),
})

export type ApiInvoiceLine = z.infer<typeof apiInvoiceLine>

/**
 * InvoiceBankAccount
 */
export const apiInvoiceBankAccount = z.object({
  bic: z.string(),
  iban: z.string(),
})
export type ApiInvoiceBankAccount = z.infer<typeof apiInvoiceBankAccount>

/**
 * ApiInvoiceStatus
 */
export const apiInvoiceStatus = z.enum(["invalid", "pending", "submitted"])
export type ApiInvoiceStatus = z.infer<typeof apiInvoiceStatus>

/**
 * ApiInvoiceType
 */
export const apiInvoiceType = z.enum(["credit", "debit"])
export type ApiInvoiceType = z.infer<typeof apiInvoiceType>

/**
 * ApiAttachment
 */
export const apiAttachment = z.union([
  z.object({
    name: z.string(),
    file: z.string(),
    path: z.string(),
    size: z.number(),
    extension: z.string(),
  }),
  z.object({
    file: z.null(),
  }),
])
export type ApiAttachment = z.infer<typeof apiAttachment>

/**
 * Invoice
 */
export const apiInvoice = z.object({
  id: z.string().uuid(),
  status: apiInvoiceStatus,
  type: apiInvoiceType,
  customerId: z.string().nullable(),
  date: z.string(),
  dueDate: z.string(),
  dueDateText: z.string(),
  invoiceReference: z.string(),
  senderProjectReference: z.string(),
  customerProjectReference: z.string(),
  note: z.string(),
  prepaidAmount: z.number(),
  attachment: apiAttachment,
  bankAccounts: apiInvoiceBankAccount.array(),
  createdAt: z.string(),
  updatedAt: z.string(),
  invoiceLines: apiInvoiceLine.array(),
})
export type ApiInvoice = z.infer<typeof apiInvoice>

const schema = z.object({
  id: z.string(),
  status: z.string(),
  date: z.string(),
  dueDate: z.string(),
  dueDateText: z.string(),
  invoiceReference: z.string(),
  senderProjectReference: z.string(),
  customerProjectReference: z.string(),
  note: z.string(),
  customerId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  type: z.string(),
  prepaidAmount: z.number(),
  bankAccounts: z.array(z.object({ bic: z.string(), iban: z.string() })),
})
