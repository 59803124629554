import Routes from "@/routes"
import { ErrorBoundary } from "react-error-boundary"
import { Toaster } from "sonner"
import { PageBoundary } from "./components/pages/boundary"
import { PageError } from "./components/pages/error"
import { PageLoader } from "./components/pages/loader"
import { useInitAuthStore } from "./store/auth/hooks"

/**
 * App
 */
const App: React.FC = () => {
  // initialize application
  const [authLoading, authDone] = useInitAuthStore()
  // on loading
  if (authLoading) return <PageLoader />
  // on errors
  if (!authDone)
    return (
      <PageError
        title="Server error"
        secondary="An error occured during the initialization"
        message="The serveur is not responding, please try again later"
      />
    )

  return (
    <ErrorBoundary FallbackComponent={PageBoundary}>
      <Routes />
      <Toaster
        toastOptions={{
          unstyled: true,
          classNames: {
            toast:
              "flex items-center p-4 gap-1.5 bg-card border shadow-lg rounded-md w-[var(--width)]",
            title: "text-card-foreground text-sm",
            description: "text-xs text-card-foreground/75",
            loader: "[&_svg]:text-blue-600",
            closeButton: "text-orange-600",
            cancelButton: "",
            actionButton: "",
            success: "[&_svg]:text-green-600",
            error: "[&_svg]:text-red-600",
            info: "[&_svg]:text-blue-600",
            warning: "[&_svg]:text-amber-600",
            default: "",
          },
        }}
      />
    </ErrorBoundary>
  )
}
export default App
