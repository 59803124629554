import { Card } from "@/components/collection"
import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import { useDocumentSize } from "@/hooks/useDocumentSize"
import { useDropZone } from "@/hooks/useDropZone"
import { updateInvoice } from "@/store/invoices/actions"
import { Invoice } from "@/store/invoices/localizers"
import { saveAs } from "file-saver"
import { motion, useAnimation } from "framer-motion"
import { ChevronLeft, ChevronRight, Download, GripHorizontal } from "lucide-react"
import { Document, DocumentProps, Page, pdfjs } from "react-pdf"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

/**
 * workers
 */
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString()

/**
 * AttachmentCard
 */
type Props = { invoice: Invoice }
export const AttachmentCard: React.FC<Props> = ({ invoice }) => {
  const { attachment } = invoice
  const [loading, setLoading] = React.useState(true)
  const [numberOfPages, setNumberOfPages] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)

  const onLoadSuccess: DocumentProps["onLoadSuccess"] = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages)
    setCurrentPage(1)
    setLoading(false)
  }
  const changePage = (offset: number) => {
    setCurrentPage(prevPageNumber => {
      const nextPageNumber = prevPageNumber + offset
      if (nextPageNumber < 1 || nextPageNumber > numberOfPages) return prevPageNumber
      return nextPageNumber
    })
  }
  const previous = () => changePage(-1)
  const next = () => changePage(1)

  const ref = React.useRef<HTMLDivElement>(null)
  const wrapperWidth = ref.current?.clientWidth ?? 0
  const wrapperHeight = ref.current?.clientHeight ?? 0

  const { bindDropZone, dragOver } = useDropZone({
    accept: ["pdf"],
    multiple: false,
    onDropFiles: files => {
      const file = A.head(files)
      if (G.isNullable(file)) return
      updateInvoice(invoice.id, { attachment: file })
    },
    onError: code => {
      if (code === "TOOLARGE") toast.error(`File is too large`)
      if (code === "UNACCEPTED") toast.error(`File extension is not accepted`)
    },
  })
  return (
    <Card {...bindDropZone} className="overflow-visible">
      <Card.Header>
        <Card.Title>Attachment</Card.Title>
        {G.isNotNullable(attachment) && (
          <Button
            variant="ghost"
            size="xs"
            icon
            onClick={() => {
              const name = `peppol-invoice-${attachment.name}-${new Date().getTime()}.pdf`
              saveAs(attachment.url, name)
            }}
            className="absolute top-2 right-2 rounded-full"
          >
            <Download aria-hidden />
            <SrOnly>Download</SrOnly>
          </Button>
        )}
      </Card.Header>
      {G.isNotNullable(attachment) ? (
        <div className="p-4 pt-0">
          <div className="grid w-full aspect-[210/297]" ref={ref}>
            <Dragable wrapperHeight={wrapperHeight}>
              <Document
                file={attachment.url}
                onLoadSuccess={onLoadSuccess}
                className={cxm(
                  "relative grid justify-center items-center w-full aspect-[210/297]",
                  loading && "h-full"
                )}
              >
                <TransformWrapper>
                  <TransformComponent wrapperClass="bg-white shadow-sm rounded-xs">
                    <Page
                      pageNumber={currentPage}
                      renderMode="canvas"
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      width={wrapperWidth}
                    />
                  </TransformComponent>
                </TransformWrapper>
                {!loading && (
                  <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex justify-center items-center gap-2 rounded-md bg-foreground/75 text-background text-xs">
                    <Button
                      variant="ghost"
                      size="xs"
                      icon
                      className="ring-offset-foreground/30 hover:bg-foreground/30 text-background hover:text-background"
                      onClick={previous}
                    >
                      <ChevronLeft aria-hidden />
                      <SrOnly>Preview page</SrOnly>
                    </Button>
                    <p>
                      Page {currentPage} of {numberOfPages}
                    </p>
                    <Button
                      variant="ghost"
                      size="xs"
                      icon
                      className="ring-offset-foreground/30 hover:bg-foreground/30 text-background hover:text-background"
                      onClick={next}
                    >
                      <ChevronRight aria-hidden />
                      <SrOnly>Next page</SrOnly>
                    </Button>
                  </div>
                )}
              </Document>
            </Dragable>
          </div>
        </div>
      ) : (
        <div className="p-6 pt-0">
          <p className="text-sm text-muted-foreground font-light">
            No attachment set, drop a PDF file here to upload an attachment to the invoice.
          </p>
        </div>
      )}
      {dragOver && (
        <div className="absolute inset-0 z-10 flex justify-center items-center backdrop-blur-sm size-full bg-primary/5 text-primary border border-dashed border-primary rounded-md">
          <p>Drop PDF file here</p>
        </div>
      )}
    </Card>
  )
}

type DragableProps = {
  children: React.ReactNode
  wrapperHeight: number
}
const Dragable: React.FC<DragableProps> = ({ children }) => {
  const [isDragging, setIsDragging] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const size = useDocumentSize()
  const controls = useAnimation()
  const constraints = React.useMemo(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      return {
        left: -rect.left + 16,
        right: size.width - rect.right - 16,
        top: -rect.top + 16,
        bottom: size.height - rect.bottom - 16,
      }
    }
    return { left: 0, right: 0, top: 0, bottom: 0 }
  }, [size])
  const reset = () => {
    controls.start({
      x: 0,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 30 },
    })
  }
  return (
    <div className="grid" ref={ref}>
      <motion.div
        className="relative z-10"
        dragElastic={0.5}
        dragConstraints={constraints}
        drag={isDragging ? true : false}
        animate={controls}
        onDragEnd={() => setIsDragging(false)}
      >
        <button
          className="absolute z-10 top-0 left-0 cursor-move rounded-md p-2"
          onMouseDown={() => setIsDragging(true)}
          onMouseEnter={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          onDoubleClick={reset}
        >
          <GripHorizontal size={12} className="text-muted-foreground" />
        </button>
        {children}
      </motion.div>
    </div>
  )
}
